import * as types from '../actions/scans';
import { apiFetch } from '../../utils/requests';
import { getScanURL } from '../../settings/api';

/**
 * action creator for receiveScanAction
 * @param  {Object} scan - scan objects returned from API
 * @return {Object} - action object
 */
export function receiveScanAction(scan) {
  return {
    type: types.RECEIVE_SCAN,
    payload: { scan },
  };
}

/**
 * Fetches specified Scan from API
 * @param  {number} scanID - scan ID to fetch
 * @return {function} - thunk for fetchScanByID
 */
export default function fetchScanByID(scanID) {
  return (dispatch, getState) => {
    if (scanID == null) {
      return Promise.reject(new Error('scanID must be defined'));
    }

    const scanUrl = getScanURL(scanID);

    return apiFetch(scanUrl, getState().auth.get('token'), 'GET').then(scan => {
      dispatch(receiveScanAction(scan));
      return scan;
    });
  };
}
