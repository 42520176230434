import { AXIAL_VIEWER_NAME, CORONAL_VIEWER_NAME } from '../../selectors';

export default function setHUWindow(min, max) {
  return (notify, getSceneState) => {
    [AXIAL_VIEWER_NAME, CORONAL_VIEWER_NAME].forEach(sceneName => {
      const sceneState = getSceneState(sceneName);

      sceneState.sliceLoader.setWindow(min, max);
      sceneState.sliceLoader.displaySlice(sceneState.sliceIdx, localStorage.getItem('token'));
    });
  };
}
