import { apiFetch } from '../../utils/requests';
import { startSegmentationURL } from '../../settings/api';
import addNewSegmentation from './addNewSegmentation';

export default function startSegmentation(segID) {
  return dispatch => {
    return apiFetch(startSegmentationURL(segID), localStorage.getItem('token'), 'POST').then(
      seg => {
        dispatch(addNewSegmentation(seg));
      },
    );
  };
}
