import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SCAN_STATUS, SEG_STATUS } from '../../../settings/segmentations';

import IconError from '../../../images/statuses/icon-error.svg';
import IconVerified from '../../../images/statuses/icon-verified-border.svg';
import IconRejected from '../../../images/statuses/icon-rejected-border.svg';
import IconUnused from '../../../images/statuses/icon-unused.svg';

export default function SegStatusBar({ seg, scan }) {
  switch (true) {
    // show all segs as rejected, if scan rejected
    case scan.status === SCAN_STATUS.REJECTED:
      return (
        <StatusBarRejected seg={seg}>
          Rejected
          <img src={IconRejected} alt='Rejected' />
        </StatusBarRejected>
      );

    case seg.status === SEG_STATUS.VERIFIED:
      return (
        <StatusBarVerified seg={seg}>
          Verified
          <img src={IconVerified} alt='Verified' />
        </StatusBarVerified>
      );

    // different seg has been verified
    case scan.status === SCAN_STATUS.VERIFIED && seg.status !== SEG_STATUS.VERIFIED:
      return (
        <StatusBarUnused seg={seg}>
          Unused
          <img src={IconUnused} alt='Unused' />
        </StatusBarUnused>
      );

    case seg.status === SEG_STATUS.RUNNING:
      return <StatusBarSegmenting seg={seg}>In Progress...</StatusBarSegmenting>;

    case seg.status === SEG_STATUS.ERROR:
      return (
        <StatusBarError seg={seg}>
          Error
          <img src={IconError} alt='Error' />
        </StatusBarError>
      );

    case seg.status === SEG_STATUS.SUCCESS:
      return <StatusBarReview seg={seg}>Needs Review</StatusBarReview>;

    default:
      return <StatusBarPending seg={seg}>Awaiting Segmentation...</StatusBarPending>;
  }
}

SegStatusBar.propTypes = {
  seg: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  scan: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
};

function StatusBar({ seg, children, className }) {
  return (
    <Container className={className}>
      <SegAlphaNum>
        <SegAlphaNumText data-test='seg-alpha-num'>{seg.segAlphaNum}</SegAlphaNumText>
        <SegAlphaNumBg />
      </SegAlphaNum>
      <SegStatus data-test='seg-status'>{children}</SegStatus>
    </Container>
  );
}

StatusBar.propTypes = {
  seg: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    segAlphaNum: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

StatusBar.defaultProps = {
  className: '',
};

const Container = styled.div`
  display: flex;
  flex: 1 0 300px;
  max-width: 500px;
  margin-right: 25px;

  border: 1px solid #5a5a5a;
  border-radius: 6px;
  overflow: hidden;
`;

const SegAlphaNum = styled.div`
  width: 69px;
  font-size: 16px;

  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
`;

const SegAlphaNumText = styled.div`
  z-index: 2;
`;

const SegAlphaNumBg = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;

  opacity: 0.5;
  z-index: 1;
`;

const SegStatus = styled.div`
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-left: 20px;

  font-size: 16px;
  color: #ffffff;

  position: relative;

  img {
    position: absolute;
    right: 10px;
  }
`;

const StatusBarSegmenting = styled(StatusBar)`
  & {
    border-color: #034ea4;
  }
  ${SegAlphaNumBg}, ${SegStatus} {
    background-image: linear-gradient(to top, #0a3466, #141414);
  }
`;

const StatusBarReview = styled(StatusBar)`
  & {
    border-color: #1b8777;
  }
  ${SegAlphaNumBg}, ${SegStatus} {
    background-image: linear-gradient(to bottom, #1e1e1e 2%, #1a2522 4%, #095342);
  }
`;

const StatusBarError = styled(StatusBar)`
  & {
    border-color: #8f3f3f;
  }
  ${SegAlphaNumBg}, ${SegStatus} {
    background-image: linear-gradient(to bottom, #171717, #450a0a);
  }
`;

const StatusBarPending = styled(StatusBar)`
  & {
    border-color: #74621c;
  }
  ${SegAlphaNumBg}, ${SegStatus} {
    background-image: linear-gradient(to top, #4c3f13, #141414, #191814);
  }
`;

const StatusBarVerified = styled(StatusBar)`
  & {
    border-color: #1b8777;
  }
  ${SegAlphaNumBg} {
    background-image: none;
    background-color: #00a883;
  }
  ${SegStatus} {
    background-image: linear-gradient(to bottom, #1d2120, #0a5140);
  }
`;

const StatusBarRejected = styled(StatusBar)`
  & {
    border-color: #8f3f3f;
  }
  ${SegAlphaNumBg} {
    background-image: none;
    background-color: #a23333;
  }
  ${SegStatus} {
    background-image: linear-gradient(to bottom, #171717, #450a0a);
  }
`;

const StatusBarUnused = styled(StatusBar)`
  & {
    border-color: #6b6b6b;
  }
  ${SegAlphaNumBg} {
    background-image: none;
    background-color: #585858;
  }
  ${SegStatus} {
    background-image: linear-gradient(to top, #565656, #141414);
  }
`;
