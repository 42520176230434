import * as types from '../actions/scans';
import { apiFetch } from '../../utils/requests';
import { updateScanURL } from '../../settings/api';

/**
 * action creator for updateScanAction
 * @param  {Object} scan - scan object returned from API
 * @return {Object} - action object
 */
export function updateScanAction(scan) {
  return {
    type: types.UPDATE_SCAN,
    payload: { scan },
  };
}

/**
 * updates specified scan through PATCH
 * @param  {number} scanID - ID of scan to update
 * @return {function} - thunk for updateScan
 */
export default function updateScan(scanID, data) {
  return (dispatch, getState) => {
    if (scanID == null) {
      return Promise.reject(new Error('scanID must be defined'));
    }

    const scanUrl = updateScanURL(scanID);

    // PATCH scan
    return apiFetch(scanUrl, getState().auth.get('token'), 'PATCH', data).then(scan => {
      dispatch(updateScanAction(scan));
      return scan;
    });
  };
}
