import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useCanvasMouseEvent, useSceneDispatch } from '@visionair/scene-state-3js';

import visualizeCenterline from '../../../modules/scene/actions/airwayView/visualizeCenterline';
import Instructions from './Instructions';
import deleteIconActive from '../../../images/centerline_tools/delete_active.svg';
import { isClick } from '../../../modules/scene/selectors';

const BRANCH_HOVER_COLOR = 0xff0000;

const DELETE_BRANCH = 0;
const CONFIRM = 1;

export default function DeleteTool({ onFinish }) {
  const sceneDispatch = useSceneDispatch();
  const [isBranchSelected, setBranchSelected] = useState(false);
  const prevBranchRef = useRef();
  const [step, setStep] = useState(DELETE_BRANCH);

  useCanvasMouseEvent(
    'pointermove',
    useCallback(
      (evt, sceneState, raycaster) => {
        if (step === DELETE_BRANCH) {
          if (isBranchSelected) {
            return;
          }

          const { branch } = sceneState.centerline.getClosestPointToRay(raycaster.ray);

          if (prevBranchRef.current && prevBranchRef.current !== branch) {
            sceneDispatch(visualizeCenterline());
          }

          prevBranchRef.current = branch;

          const queue = [branch];
          while (queue.length > 0) {
            const b = queue.shift();

            const threeBranch = sceneState.scene.getObjectByName(b.branchName);
            threeBranch.material.color.set(BRANCH_HOVER_COLOR);

            queue.push(...b.children.filter(c => c.visible));
          }
        }
      },
      [step, sceneDispatch, isBranchSelected],
    ),
  );

  useCanvasMouseEvent(
    'pointerup',
    useCallback(
      (evt, sceneState, raycaster) => {
        if (!isClick(sceneState, evt) || step === CONFIRM) {
          return;
        }

        const { branch } = sceneState.centerline.getClosestPointToRay(raycaster.ray);
        sceneState.centerline.deleteBranch(branch);

        sceneDispatch(visualizeCenterline());
        setBranchSelected(true);

        setStep(CONFIRM);
        onFinish();
      },
      [step, sceneDispatch, onFinish],
    ),
  );

  useEffect(() => () => sceneDispatch(visualizeCenterline()), [sceneDispatch]);

  return (
    <Instructions>
      <img src={deleteIconActive} alt='' />
      {isBranchSelected
        ? 'Delete the selected segment using the confirm button.'
        : 'Left-click the centerline segment you wish to delete.'}
    </Instructions>
  );
}
