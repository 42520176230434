import addNewSegmentation from './addNewSegmentation';
import { updateSeg } from '../../utils/requests';
import setSegID from '../viewer/setSegID';

export default function saveSeg(segID, updates = {}) {
  return dispatch => {
    return updateSeg({ ...updates, id: segID }).then(resSeg => {
      dispatch(addNewSegmentation(resSeg));
      dispatch(setSegID(resSeg.id));
    });
  };
}
