import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSceneDispatch } from '@visionair/scene-state-3js';

import { getShowCenterlineBoundsWarning } from '../../../modules/viewer/selectors';
import Instructions from './Instructions';
import setShowCenterlineBoundsWarning from '../../../modules/viewer/setShowCenterlineBoundsWarning';
import verifySegmentation from '../../../modules/scene/actions/airwayView/verifySegmentation';
import { showNotificationError } from '../../../modules/notifications/showNotification';

import warningIcon from '../../../images/statuses/icon-error.svg';

export default function CenterlineBoundsWarning() {
  const show = useSelector(({ viewer }) => getShowCenterlineBoundsWarning(viewer));
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sceneDispatch = useSceneDispatch();
  const reduxDispatch = useDispatch();
  const history = useHistory();

  if (!show) {
    return null;
  }

  const handleVerify = () => {
    setIsSubmitting(true);

    sceneDispatch(verifySegmentation())
      .then(() => {
        setIsSubmitting(false);
        reduxDispatch(setShowCenterlineBoundsWarning(false));
        history.push('/scans');
      })
      .catch(err => {
        setIsSubmitting(false);
        reduxDispatch(setShowCenterlineBoundsWarning(false));
        reduxDispatch(
          showNotificationError('An unexpected error occurred while verifying segmentation.'),
        );
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  return (
    <Container>
      <div>
        <img src={warningIcon} alt='' />
        Centerline outside of airway!
      </div>
      <VerifyBtn onClick={handleVerify} disabled={isSubmitting}>
        Verify Anyway
      </VerifyBtn>
      <AdjustBtn onClick={() => reduxDispatch(setShowCenterlineBoundsWarning(false))}>
        Adjust Centerline
      </AdjustBtn>
    </Container>
  );
}

const Container = styled(Instructions)`
  pointer-events: all;
  width: 550px;
  justify-content: space-between;
  padding: 0 20px;

  img {
    margin-right: 20px;
  }
`;

const VerifyBtn = styled.button`
  background: none;
  border: none;
  color: white;
`;

const AdjustBtn = styled.button`
  background: none;
  border: none;
  color: #00ff14;
  text-decoration: underline;
`;
