import React from 'react';
import styled from 'styled-components';

import CircularProgress from '@material-ui/core/CircularProgress';

import { ReactComponent as ParsingZipIcon } from '../../../images/scan-overwrite-modal/ct-upload-parsing-icon.svg';

import ScanUploadDialog from './ScanUploadDialog';

export default function StepParsingScan({ onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <ScanUploadDialog.Title
        heading='Processing CT Scan'
        icon={<ParsingZipIcon />}
        showCloseBtn
        onClose={handleClose}
      />

      <ScanUploadDialog.Content>
        <LoadingContainer>
          <CirProgress data-test='scanUploader-loadingPatientInfo' />
          Reading Patient Info...
        </LoadingContainer>
      </ScanUploadDialog.Content>

      <ScanUploadDialog.ActionButtons
        ScanParametersProps={{
          showBtn: false,
          open: false,
          onClick: () => {},
        }}
      />
    </>
  );
}

const LoadingContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: #5d1c9a;
`;

function CirProgress(props) {
  return <CP size={100} thickness={2.5} {...props} />;
}

const CP = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: #5d1c9a;
  }

  margin: 30px;
`;
