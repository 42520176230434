import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { MdWarningAmber } from 'react-icons/md';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import useSelectorToJS from '../../hooks/useSelectorToJS';
import { getSelectedVolID } from '../../modules/viewer/selectors';
import { getVolsByScanID } from '../../modules/volume3Ds/selectors';

export const MAX_SLICE_THICKNESS_MM = 1.5;

export default function ScanIssuesWarning() {
  const { scanID } = useParams();

  const [open, setOpen] = useState(false);

  const { selectedVolID, scanVols } = useSelectorToJS(({ viewer, volume3Ds }) => ({
    selectedVolID: getSelectedVolID(viewer),
    scanVols: getVolsByScanID(volume3Ds, scanID),
  }));

  // filter out any empty vols (created by API when parsing error occurs)
  const vols = scanVols.filter(v => v.studyInstanceUID && v.seriesInstanceUID);

  // sort vols by series number
  vols.sort((a, b) => {
    return parseInt(a.seriesNumber, 10) < parseInt(b.seriesNumber, 10) ? -1 : 1;
  });

  const {
    seriesNonAxial,
    seriesNonSquareVoxels,
    seriesMissingSlices,
    seriesInvalidSliceThickness,
    seriesExpiration,
  } = filterSeries(vols);

  const hasViableSeries = vols.some(v => v.isViable);

  useEffect(() => {
    if (hasViableSeries) {
      return;
    }

    setOpen(true);
  }, [selectedVolID, hasViableSeries]);

  if (!open) {
    return null;
  }

  return (
    <S.Card>
      <CardContent data-test='no-viable-series-warning'>
        <S.Heading>
          <MdWarningAmber color='#ffffff' size={24} className='warning-icon' />
          No viable series found
        </S.Heading>

        <S.WarningsList>
          {seriesNonAxial.length > 0 && (
            <li>
              Series is not axial supine{' '}
              <S.SeriesList>
                {seriesNonAxial.map(v => (
                  <li key={v.id}>
                    Series {v.seriesNumber} - {v.seriesDescription}
                  </li>
                ))}
              </S.SeriesList>
            </li>
          )}

          {seriesNonSquareVoxels.length > 0 && (
            <li>
              Series has non-square voxels{' '}
              <S.SeriesList>
                {seriesNonSquareVoxels.map(v => (
                  <li key={v.id}>
                    Series {v.seriesNumber} - {v.seriesDescription}
                  </li>
                ))}
              </S.SeriesList>
            </li>
          )}

          {seriesMissingSlices.length > 0 && (
            <li>
              Series is missing slices{' '}
              <S.SeriesList>
                {seriesMissingSlices.map(v => (
                  <li key={v.id}>
                    Series {v.seriesNumber} - {v.seriesDescription}
                  </li>
                ))}
              </S.SeriesList>
            </li>
          )}

          {seriesExpiration.length > 0 && (
            <li>
              Series is expiration{' '}
              <S.SeriesList>
                {seriesExpiration.map(v => (
                  <li key={v.id}>
                    Series {v.seriesNumber} - {v.seriesDescription}
                  </li>
                ))}
              </S.SeriesList>
            </li>
          )}

          {seriesInvalidSliceThickness.length > 0 && (
            <li>
              Series has invalid slice thickness ( &gt; {MAX_SLICE_THICKNESS_MM} mm ){' '}
              <S.SeriesList>
                {seriesInvalidSliceThickness.map(v => (
                  <li key={v.id}>
                    Series {v.seriesNumber} - {v.seriesDescription}
                    <ul>
                      <li>{v.sliceThickness} mm</li>
                    </ul>
                  </li>
                ))}
              </S.SeriesList>
            </li>
          )}
        </S.WarningsList>
      </CardContent>
    </S.Card>
  );
}

export function filterSeries(vols) {
  const seriesNonAxial = [];
  const seriesNonSquareVoxels = [];
  const seriesMissingSlices = [];
  const seriesInvalidSliceThickness = [];
  const seriesExpiration = [];

  let seriesRemaining = vols.filter(v => {
    if (!v.isAxialPlane) {
      seriesNonAxial.push(v);
      return false;
    }
    return true;
  });

  seriesRemaining = seriesRemaining.filter(v => {
    if (v.hasNonSquareVoxels) {
      seriesNonSquareVoxels.push(v);
      return false;
    }
    return true;
  });

  seriesRemaining = seriesRemaining.filter(v => {
    if (v.isMissingSlices) {
      seriesMissingSlices.push(v);
      return false;
    }
    return true;
  });

  seriesRemaining = seriesRemaining.filter(v => {
    if (v.seriesDescription.toLowerCase().includes('exp')) {
      seriesExpiration.push(v);
      return false;
    }
    return true;
  });

  seriesRemaining = seriesRemaining.filter(v => {
    if (v.sliceThickness > MAX_SLICE_THICKNESS_MM) {
      seriesInvalidSliceThickness.push(v);
      return false;
    }
    return true;
  });

  return {
    seriesNonAxial,
    seriesNonSquareVoxels,
    seriesMissingSlices,
    seriesInvalidSliceThickness,
    seriesExpiration,
    seriesRemaining,
  };
}

const S = {};

S.Card = styled(Card)`
  position: absolute;
  top: 68px;
  right: 100px;

  width: 490px;

  border-radius: 10px;
  background: #8a0505;
  color: #ffffff;
`;

S.Heading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  font-size: 15px;
  font-weight: 700;

  .warning-icon {
    margin-right: 10px;
  }
`;

S.CloseButton = styled.button`
  background: none;
  border: none;
  outline: none;
  padding: 5px;
  position: absolute;
  right: 10px;
`;

S.WarningsList = styled.ul`
  margin-bottom: 0;
  padding-inline-start: 25px;

  li:not(:last-child) {
    margin-bottom: 10px;
  }
`;

S.SeriesList = styled.ul`
  margin-top: 8px;
  padding-inline-start: 30px;
`;
