import styled from 'styled-components';
import { Tooltip } from 'react-bootstrap';

export default styled(Tooltip)`
  z-index: 2001;
  margin-left: 4px;

  font-size: 13px;
  font-weight: 600;

  .arrow::before {
    content: none;
  }

  .tooltip-inner {
    background: #1d1d1d;
    border: solid 1px #4e4e4e;
    padding: 8px 14px;
    max-width: 250px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }
`;
