import { parseDicom } from 'dicom-parser';

import {
  getContentOfFileInZip,
  getFileNameAndExtensionFromPath,
  readFileAsArrayBuffer,
  readZipFile,
} from '../files';
import { raceResolved } from '../general';

import {
  CorruptZipError,
  NoDICOMFoundError,
  NumberSlicesError,
  SliceThicknessError,
  InvalidDICOMError,
  errors,
} from './dicom-errors';
import { dicomDateStringToDate } from './dicom';

import DicomSeries from './DicomSeries';
import DicomSlice from './DicomSlice';
import tags from './tags';
import vr from './vr';

export function parseDICOMFromZip(zipFile) {
  return (
    readFileAsArrayBuffer(zipFile)
      // extract files from zip and parse as DICOMs
      .then(arrayBuffer => searchZipForValidDICOM(arrayBuffer))
  );
}

/**
 * Searches provided zip file for valid DICOM
 * @param {File} zipFile
 * @returns {Object || null} - object with desired fields from DICOM header
 *                           - null if DICOM file is found, but doesn't have valid fields
 */
export async function searchZipForValidDICOM(zipFile) {
  // extract files from zip and attempt to parse as DICOMs
  let zipObjects;
  try {
    zipObjects = await readZipFile(zipFile);
  } catch (err) {
    // check err msg from jsZip
    if (err.message.toLowerCase?.().includes('corrupted zip')) {
      throw new CorruptZipError();
    }

    throw err;
  }

  const { possibleDicomFiles, jpegsFound } = filterFilesInZip(zipObjects);
  if (!possibleDicomFiles.length) {
    throw new NoDICOMFoundError(jpegsFound);
  }

  const seriesMap = {};

  const addSliceToSeries = dicomSlice => {
    const key = `${dicomSlice.studyInstanceUID}-${dicomSlice.seriesInstanceUID}`;
    // create DicomSeries if it doesn't exist already
    if (!seriesMap[key]) {
      seriesMap[key] = new DicomSeries();
    }

    const series = seriesMap[key];
    series.addSlice(dicomSlice, true);
    return series;
  };

  const promiseArr = possibleDicomFiles.map(file =>
    getContentOfFileInZip(file, 'uint8array').then(byteArray => {
      const dicomInfo = parseDICOMForDesiredInfo(byteArray);

      const slice = new DicomSlice(dicomInfo, file.name);
      const series = addSliceToSeries(slice);

      if (!series.hasEnoughSlices()) {
        dicomInfo.numSlices = series.slices.length;
        throw new NumberSlicesError(dicomInfo);
      }

      if (!series.hasValidSliceThickness()) {
        dicomInfo.sliceThickness = series.sliceThickness;
        throw new SliceThicknessError(dicomInfo);
      }

      return dicomInfo;
    }),
  );

  return raceResolved(promiseArr).catch(errs => {
    const thicknessError = errs.find(err => err instanceof SliceThicknessError);
    if (thicknessError) {
      throw thicknessError;
    }

    throw errs[errs.length - 1];
  });
}

export function filterFilesInZip(zipObjects) {
  const filesInZip = Object.values(zipObjects);

  const possibleDicomFiles = [];
  let jpegsFound = false;

  for (const file of filesInZip) {
    // filter out directories
    if (file.dir) {
      continue; // eslint-disable-line no-continue
    }

    // filter out files that are certainly not DICOMs
    if (isDicomFileBlacklisted(file.name)) {
      continue; // eslint-disable-line no-continue
    }

    // detect and ignore JPEGs
    if (isFileJPEG(file.name)) {
      jpegsFound = true;
      continue; // eslint-disable-line no-continue
    }

    possibleDicomFiles.push(file);
  }

  return {
    possibleDicomFiles,
    jpegsFound,
  };
}

// ignore (don't parse) files w/ these filenames
// NOTE: uppercase
export const DICOM_FILENAME_BLACKLIST = {
  DICOMDIR: true,
  '.DS_STORE': true,
};
// ignore (don't parse) files w/ these file extensions
// NOTE: uppercase
export const DICOM_FILE_EXTENSION_BLACKLIST = {
  EXE: true,
  BIN: true,
  HTML: true,
  PDF: true,
  DB: true,
  DS_STORE: true,
};

export function isDicomFileBlacklisted(filePath) {
  const [fileName, fileExtension] = getFileNameAndExtensionFromPath(filePath.toUpperCase());

  // ignore hidden files
  if (fileName.startsWith('.')) {
    return true;
  }

  return DICOM_FILENAME_BLACKLIST[fileName] || DICOM_FILE_EXTENSION_BLACKLIST[fileExtension];
}

export function isFileJPEG(filePath) {
  const [, fileExtension] = getFileNameAndExtensionFromPath(filePath.toUpperCase());
  return fileExtension === 'JPEG' || fileExtension === 'JPG';
}

// gets/formats desired header values from single parsed dicom
export function parseDICOMForDesiredInfo(byteArray) {
  const wantTags = [
    { tag: tags.STUDY_INSTANCE_UID, vr: vr.UI },
    { tag: tags.STUDY_ID, vr: vr.SH },
    { tag: tags.SERIES_INSTANCE_UID, vr: vr.UI },
    { tag: tags.SERIES_NUMBER, vr: vr.IS },
    { tag: tags.STUDY_DATE, vr: vr.DA },
    { tag: tags.SERIES_DATE, vr: vr.DA },
    { tag: tags.ACQUISITION_DATE, vr: vr.DA },
    { tag: tags.PATIENT_NAME, vr: vr.PN },
    { tag: tags.PATIENT_MRN, vr: vr.LO },
    { tag: tags.PATIENT_SEX, vr: vr.CS },
    { tag: tags.PATIENT_BIRTHDATE, vr: vr.DA },
    { tag: tags.IMAGE_POSITION, vr: vr.DS },
    { tag: tags.IMAGE_ORIENTATION, vr: vr.DS },
  ];

  const dicomInfo = parseDICOM(byteArray, wantTags);

  const desiredInfo = {};

  // used for patient info modal
  desiredInfo.patientName = dicomInfo[tags.PATIENT_NAME];
  desiredInfo.patientMRN = dicomInfo[tags.PATIENT_MRN];
  desiredInfo.patientSex = dicomInfo[tags.PATIENT_SEX];
  desiredInfo.patientDOB = dicomDateStringToDate(dicomInfo[tags.PATIENT_BIRTHDATE]);
  desiredInfo.scanDate = getScanDate(dicomInfo);

  // used for thickness calculations
  desiredInfo[tags.STUDY_INSTANCE_UID] = dicomInfo[tags.STUDY_INSTANCE_UID];
  desiredInfo[tags.STUDY_ID] = dicomInfo[tags.STUDY_ID];
  desiredInfo[tags.SERIES_INSTANCE_UID] = dicomInfo[tags.SERIES_INSTANCE_UID];
  desiredInfo[tags.SERIES_NUMBER] = dicomInfo[tags.SERIES_NUMBER];
  desiredInfo[tags.STUDY_DATE] = dicomInfo[tags.STUDY_DATE];
  desiredInfo[tags.SERIES_DATE] = dicomInfo[tags.SERIES_DATE];
  desiredInfo[tags.ACQUISITION_DATE] = dicomInfo[tags.ACQUISITION_DATE];
  desiredInfo[tags.IMAGE_POSITION] = dicomInfo[tags.IMAGE_POSITION];
  desiredInfo[tags.IMAGE_ORIENTATION] = dicomInfo[tags.IMAGE_ORIENTATION];

  return desiredInfo;
}

export function getScanDate(dicomInfo) {
  const studyDate = dicomDateStringToDate(dicomInfo[tags.STUDY_DATE]);
  const seriesDate = dicomDateStringToDate(dicomInfo[tags.SERIES_DATE]);
  const aquisitionDate = dicomDateStringToDate(dicomInfo[tags.ACQUISITION_DATE]);

  return studyDate || seriesDate || aquisitionDate;
}

// parses DICOM and returns JS object with specified header values
export function parseDICOM(byteArray, wantTags) {
  if (!byteArray.length) {
    throw new InvalidDICOMError(errors.NOT_A_DICOM);
  }

  const parseUntilTag = calcHighestTag(wantTags);

  let dataSet;
  try {
    // parseDicom requires as input a Uint8Array
    dataSet = parseDicom(byteArray, { untilTag: parseUntilTag });
  } catch (err) {
    // throws object (NOT Error) if parsing fails, so rethrow as Error
    // see https://github.com/cornerstonejs/dicomParser/blob/82573d94342e12b4bae4fcd2e93f91bb061474cf/src/parseDicom.js#L138
    console.error(err); // eslint-disable-line no-console
    throw new InvalidDICOMError(err);
  }

  const dicomInfo = {};

  wantTags.forEach(({ tag, vr: tagVR }) => {
    const tagValue = parseTagValue(dataSet, tag, tagVR);
    dicomInfo[tag] = tagValue;
  });

  return dicomInfo;
}

function calcHighestTag(dicomTags) {
  if (!dicomTags?.length) {
    return undefined;
  }

  // sort tags ascending in hex value
  const tagsSorted = dicomTags.sort((a, b) => {
    if (a.tag < b.tag) {
      return -1;
    }
    if (a.tag > b.tag) {
      return 1;
    }
    return 0;
  });

  return tagsSorted[dicomTags.length - 1].tag;
}

export const ErrCannotParseVR = new Error('cannot parse tag with specified VR');

function parseTagValue(dataSet, tag, tagVR) {
  let value;

  switch (tagVR) {
    case vr.US:
      value = dataSet.uint16(tag);
      break;
    case vr.SS:
      value = dataSet.int16(tag);
      break;
    case vr.UL:
      value = dataSet.uint32(tag);
      break;
    case vr.SL:
      value = dataSet.int32(tag);
      break;
    case vr.FL:
      value = dataSet.float(tag);
      break;
    case vr.FD:
      value = dataSet.double(tag);
      break;
    case vr.DS:
      value = parseDS(dataSet, tag);
      break;
    case vr.IS:
      value = dataSet.intString(tag);
      break;
    case vr.CS:
    case vr.DA:
    case vr.LO:
    case vr.PN:
    case vr.SH:
    case vr.UI:
      value = dataSet.string(tag);
      break;
    default:
      throw ErrCannotParseVR;
  }

  return value;
}

// dicomParser parses each index of DS individually so we'll parse each
// and concat them back to a DS string (eg. -1\0\2)
function parseDS(dataSet, tag) {
  let value = '';

  const vm = dataSet.numStringValues(tag);
  for (let i = 0; i < vm; i += 1) {
    if (i !== 0) {
      value += '\\';
    }
    value += dataSet.floatString(tag, i);
  }

  return value;
}
