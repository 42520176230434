export default function lockCameraPosition(lock) {
  return (notify, getSceneState) => {
    const { controls } = getSceneState();

    controls.noRotate = lock;
    controls.noPan = lock;

    notify();
  };
}
