import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  getShowSeedList,
  getShowSliceViews,
  getShowCTWindowing,
} from '../../modules/viewer/selectors';

import ctIcon from '../../images/ct-viewer-white.svg';
import ctIconActive from '../../images/ct-viewer.svg';
import seedIcon from '../../images/seed-list-white.svg';
import seedIconActive from '../../images/seed-list.svg';
import windowIcon from '../../images/windowing-white.svg';
import windowIconActive from '../../images/windowing.svg';
import setShowSeedList from '../../modules/viewer/setShowSeedList';
import setShowSliceViews from '../../modules/viewer/setShowSliceViews';
import setShowCTWindowing from '../../modules/viewer/setShowCTWindowing';
import AddSeedBtn from './slice-viewers/AddSeedBtn';

export default function LeftToolbar() {
  const { showSliceViews, showSeedList, showCTWindowing } = useSelector(({ viewer }) => ({
    showSliceViews: getShowSliceViews(viewer),
    showSeedList: getShowSeedList(viewer),
    showCTWindowing: getShowCTWindowing(viewer),
  }));

  const dispatch = useDispatch();

  return (
    <Toolbar style={{ borderRight: '5px solid black' }}>
      <AddSeedBtn />
      <Btn onClick={() => dispatch(setShowSeedList(!showSeedList))} data-test='max-seed-btn'>
        <img src={showSeedList ? seedIconActive : seedIcon} alt='' />
      </Btn>
      <Btn onClick={() => dispatch(setShowSliceViews(!showSliceViews))} data-test='max-ct-btn'>
        <img src={showSliceViews ? ctIconActive : ctIcon} alt='' />
      </Btn>
      <Btn
        onClick={() => {
          dispatch(setShowCTWindowing(!showCTWindowing));

          if (!showSliceViews) {
            dispatch(setShowSliceViews(true));
          }
        }}
        data-test='ct-windowing-btn'
      >
        <img src={showCTWindowing ? windowIconActive : windowIcon} alt='' />
      </Btn>
    </Toolbar>
  );
}

const Toolbar = styled.div`
  background: #2b2b2b;
  width: 58px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
`;

const Btn = styled.button`
  border: none;
  background: none;
  height: 60px;
  width: 60px;

  && {
    outline: none;
  }
`;
