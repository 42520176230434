import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Modal from './modals/ModalStyled';
import ButtonDefault from './buttons/ButtonDefault';
import TextInput from './TextInput';

import BaseComponent from './BaseComponent';
import authLogin from '../modules/auth/authLogin';
import authLogout from '../modules/auth/authLogout';
// import { showNotificationError } from '../modules/notifications/showNotification';
import { getUserEmail, getTokenOrigIssuedAtMs } from '../modules/auth/selectors';

import { REENTER_PASSWORD_MODAL_TIMEOUT_MS, MAX_TOKEN_REFRESH_MS } from '../settings/broncUsers';

import { HttpUnauthorizedError } from '../utils/requests';

export class ReenterPasswordModalComponent extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      password: '',
    };
  }

  componentDidMount() {
    this.setModalTimeout();
  }

  componentWillUnmount() {
    clearTimeout(this.modalTimeout);
    clearTimeout(this.logoutTimeout);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tokenOrigIssuedAtMs !== this.props.tokenOrigIssuedAtMs) {
      this.setModalTimeout();
    }

    if (!prevState.show && this.state.show) {
      this.setLogoutTimeout();
    }
  }

  setLogoutTimeout() {
    clearTimeout(this.logoutTimeout);
    this.logoutTimeout = setTimeout(this.props.authLogout, REENTER_PASSWORD_MODAL_TIMEOUT_MS);
  }

  setModalTimeout() {
    clearTimeout(this.modalTimeout);

    const tokenExpiresAtMs = this.props.tokenOrigIssuedAtMs + MAX_TOKEN_REFRESH_MS;
    const delayMs = tokenExpiresAtMs - Date.now() - REENTER_PASSWORD_MODAL_TIMEOUT_MS;

    this.modalTimeout = setTimeout(() => {
      this.setState({ show: true });
    }, delayMs);
  }

  handleReenterPasswordSubmit() {
    clearTimeout(this.logoutTimeout);

    this.props
      .authLogin(this.props.email, this.state.password)
      .then(() => this.setState({ show: false, password: '' }))
      .catch(err => {
        this.props.authLogout();

        if (err instanceof HttpUnauthorizedError) {
          // this.props.showNotificationError('Incorrect Password. You have been logged out.', 5000);
          return;
        }

        // this.props.showNotificationError(
        //   'An unexpected error occurred. Please try logging in again.',
        // );
        console.error(err); // eslint-disable-line no-console
      });
  }

  handlePasswordInputChange({ target: { value } }) {
    this.setLogoutTimeout();
    this.setState({ password: value });
  }

  render() {
    return (
      <StyledModal show={this.state.show} centered>
        <Modal.Header>
          <div>Re-Enter Password</div>
        </Modal.Header>
        <ModalBody>
          <TextContainer>
            <div>It looks like you&rsquo;ve been logged in for a while...</div>
            <br />
            <div>For security purposes, please re-enter your password to remain logged in.</div>
          </TextContainer>
          <TextField
            placeholder='Password'
            type='password'
            required
            value={this.state.password}
            handleChange={this.handlePasswordInputChange}
          />
        </ModalBody>
        <ModalFooter>
          <LogoutButton onClick={this.props.authLogout}>Logout</LogoutButton>
          <LoginButton disabled={!this.state.password} onClick={this.handleReenterPasswordSubmit}>
            Stay Logged In
          </LoginButton>
        </ModalFooter>
      </StyledModal>
    );
  }
}

ReenterPasswordModalComponent.propTypes = {
  email: PropTypes.string.isRequired,
  tokenOrigIssuedAtMs: PropTypes.number.isRequired,
  authLogin: PropTypes.func.isRequired,
  authLogout: PropTypes.func.isRequired,
  // showNotificationError: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  email: getUserEmail(auth),
  tokenOrigIssuedAtMs: getTokenOrigIssuedAtMs(auth),
});

const mapDispatchToProps = {
  authLogin,
  authLogout,
  // showNotificationError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReenterPasswordModalComponent);

const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 550px;
  }
`;

const ModalBody = styled(Modal.Body)`
  padding: 30px 50px;
`;

const ModalFooter = styled(Modal.Footer)`
  padding: 20px 40px;
`;

const TextContainer = styled.div`
  margin-bottom: 26px;
`;

const TextField = styled(TextInput)`
  div {
    color: ${props => props.theme.colors.white};
    font-size: 16px;
  }

  input {
    height: 45px;
    background-color: rgba(55, 55, 55, 0.67);
    border: 1px solid #7d7d7d;
    color: ${props => props.theme.colors.white} !important;

    &:focus {
      border: 1px solid #23d3cd;
    }
  }
`;

const LoginButton = styled(ButtonDefault)`
  width: 190px;
  background-image: linear-gradient(to bottom, #ffc044 -101%, #ffbd3f 0%, #feaa22 148%);
`;

const LogoutButton = styled(ButtonDefault)`
  width: 190px;
  background-color: #969696;
  margin-right: 20px !important;
`;
