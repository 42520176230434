import React, { useState } from 'react';
import ReactDropzone, { ErrorCode } from 'react-dropzone';
import styled, { css } from 'styled-components';

import MuiTooltip from '@material-ui/core/Tooltip';

import { MAX_SLICE_THICKNESS } from '../../../utils/dicom/DicomSeries';
import { formatFileSize } from '../../../utils/files';

import { ReactComponent as InvalidZipIcon } from '../../../images/scan-overwrite-modal/ct-upload-invalid-zip-icon.svg';
import { ReactComponent as SelectZipIcon } from '../../../images/scan-overwrite-modal/ct-upload-select-zip-icon.svg';
import { ReactComponent as ValidZipIcon } from '../../../images/scan-overwrite-modal/ct-upload-valid-zip-icon.svg';
import { ReactComponent as ZipfileMac } from '../../../images/scan-overwrite-modal/ct-upload-zipfile-mac.svg';
import { ReactComponent as ZipfileWindows } from '../../../images/scan-overwrite-modal/ct-upload-zipfile-windows.svg';

import ScanUploadDialog from './ScanUploadDialog';
import * as S from './ScanUploadDialog.styled';

export const MAX_ZIP_SIZE = 2 * 1024 ** 3; // 2 GB

const ZipError = {
  INVALID_ZIP: 'INVALID_ZIP',
  FILE_TOO_LARGE: 'FILE_TOO_LARGE',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
};

export default function StepSelectScanZip({ onContinue, onClose }) {
  const [openScanParams, setOpenScanParams] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [zipError, setZipError] = useState(null);

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setOpenScanParams(false);
    }, 1000);
  };

  const handleContinue = () => {
    setOpenScanParams(false);
    onContinue(selectedFile);
  };

  const handleFileAccepted = file => {
    setZipError(null);
    setSelectedFile(file);
  };

  const handleFileRejected = fileRejection => {
    console.error(fileRejection.errors); // eslint-disable-line no-console

    setSelectedFile(fileRejection.file);

    const isInvalidZip = fileRejection.errors.find(err => err.code === ErrorCode.FileInvalidType);
    if (isInvalidZip) {
      setZipError(ZipError.INVALID_ZIP);
      return;
    }

    const isTooLarge = fileRejection.errors.find(err => err.code === ErrorCode.FileTooLarge);
    if (isTooLarge) {
      setZipError(ZipError.FILE_TOO_LARGE);
      return;
    }

    setZipError(ZipError.UNKNOWN_ERROR);
  };

  const continueDisabled = !selectedFile || !!zipError;

  let tooltipMsg = '';
  if (!selectedFile) {
    tooltipMsg = 'Select .zip file to upload';
  } else if (zipError) {
    tooltipMsg = 'Select valid .zip file';
  }

  let heading = 'Select CT Scan .zip for Overwriting';
  if (zipError === ZipError.INVALID_ZIP) {
    heading = 'Error: Invalid .zip file';
  }
  if (zipError === ZipError.FILE_TOO_LARGE) {
    heading = `Error: Selected .zip file too large (> ${formatFileSize(MAX_ZIP_SIZE, 0)})`;
  }
  if (zipError === ZipError.UNKNOWN_ERROR) {
    heading = 'Error: Unable to read .zip file';
  }

  return (
    <>
      <Title
        heading={heading}
        icon={<SelectZipIcon />}
        $hasError={!!zipError}
        showCloseBtn
        onClose={handleClose}
      />

      <ScanUploadDialog.Content openScanParameters={openScanParams}>
        <>
          <Instructions $hasError={!!zipError}>
            {!zipError && (
              <span>
                <strong>Drag & Drop</strong> or <strong>Select</strong> your compressed .zip file
                below to upload.
              </span>
            )}

            {zipError === ZipError.INVALID_ZIP && (
              <>
                <span>
                  <strong>The selected file is not a .zip file.</strong> Select a different file to
                  continue.
                </span>
                <div style={{ marginTop: '10px' }}>
                  Make sure you are <strong>NOT</strong> dragging a normal (unzipped) folder!
                </div>
              </>
            )}

            {zipError === ZipError.FILE_TOO_LARGE && (
              <div>
                To reduce your .zip file size, you can try deleting any unnecessary files before
                re-zipping your DICOM files.
                <div style={{ marginTop: '10px' }}>
                  <strong>You can delete:</strong>
                  <ul style={{ marginBottom: '0' }}>
                    <li>any DICOM series with non-axial orientation</li>
                    <li>{`any DICOM series with slice thickness > ${MAX_SLICE_THICKNESS} mm`}</li>
                    <li>any executable files (.exe) such as DICOM Viewer applications</li>
                  </ul>
                </div>
              </div>
            )}

            {zipError === ZipError.UNKNOWN_ERROR && (
              <span>
                <strong>We were unable to read the selected file.</strong> Try re-zipping your DICOM
                files by following the instructions in the previous step.
              </span>
            )}
          </Instructions>

          <Dropzone
            onFileAccepted={handleFileAccepted}
            onFileRejected={handleFileRejected}
            selectedFile={selectedFile}
            zipError={zipError}
          />
        </>
      </ScanUploadDialog.Content>

      <ScanUploadDialog.ActionButtons
        ScanParametersProps={{
          showBtn: true,
          open: openScanParams,
          onClick: () => setOpenScanParams(!openScanParams),
        }}
      >
        <Tooltip title={tooltipMsg} placement='top' arrow id='scanUpload-continueTooltip'>
          <span
            style={{
              display: 'inline-block',
              cursor: continueDisabled ? 'not-allowed' : 'pointer',
            }}
          >
            <S.PrimaryButton onClick={handleContinue} disabled={continueDisabled}>
              Continue
            </S.PrimaryButton>
          </span>
        </Tooltip>
      </ScanUploadDialog.ActionButtons>
    </>
  );
}

const Title = styled(ScanUploadDialog.Title)`
  ${props =>
    props.$hasError &&
    css`
      color: #ff0000;

      .ScanUploadDialog-DialogTitleIcon svg path {
        fill: #ff0000;
      }
    `}
`;

const Instructions = styled(S.Instructions)`
  ${props =>
    props.$hasError &&
    css`
      margin-bottom: 0px;
    `}
`;

const Tooltip = styled(props => <MuiTooltip classes={{ popper: props.className }} {...props} />)`
  ${S.TooltipCSS}
`;

function Dropzone({ onFileAccepted, onFileRejected, selectedFile, zipError }) {
  const hasZipError = !!zipError;

  const handleFileSelected = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length !== 0 && acceptedFiles.length === 0) {
      onFileRejected(rejectedFiles[0]);
      return;
    }

    onFileAccepted(acceptedFiles[0]);
  };

  return (
    <ReactDropzone
      onDrop={handleFileSelected}
      accept={{
        'application/zip': [],
      }}
      multiple={false}
      maxSize={MAX_ZIP_SIZE}
    >
      {({ getRootProps, getInputProps }) => (
        <DropzoneContainer
          {...getRootProps()}
          $error={zipError}
          id='scan-upload-dropzone'
          data-test='scan-upload-dropzone'
        >
          <input {...getInputProps() /* eslint-disable-line react/jsx-props-no-spreading */} />

          {!selectedFile &&
            (/win/i.test(window.navigator.userAgent) ? (
              <ZipfileWindows width='120' height='95' />
            ) : (
              <ZipfileMac />
            ))}

          {selectedFile && !hasZipError && <ValidZipIcon width='118' height='95' />}
          {hasZipError && <InvalidZipIcon width='118' height='95' />}

          <DragNDropInstructs>
            {selectedFile ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div style={{ textAlign: 'center' }}>{selectedFile.name}</div>
                <div style={{ marginTop: '5px' }}>{formatFileSize(selectedFile.size, 2)}</div>
              </div>
            ) : (
              'Drag & Drop .zip file here or click to select file...'
            )}
          </DragNDropInstructs>

          <SelectFileButton
            $hasError={hasZipError}
            variant='outlined'
            data-test='scan-upload-select-file-button'
          >
            {selectedFile ? 'Select Different File' : 'Select .zip File'}
          </SelectFileButton>
        </DropzoneContainer>
      )}
    </ReactDropzone>
  );
}

const DropzoneContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  margin: 20px 0;
  padding: 80px 30px;

  border: 2px dashed #bdbdbd;
  border-radius: 20px;
  ${props =>
    props.$error &&
    css`
      border-color: #ff0000;
    `}

  ${props =>
    props.$error === ZipError.FILE_TOO_LARGE &&
    css`
      padding: 20px 30px;
    `}
`;

const DragNDropInstructs = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

const SelectFileButton = styled(S.SecondaryButton)`
  ${props =>
    props.$hasError &&
    css`
      background-color: #552794 !important;
      color: #ffffff !important;
    `}
`;
