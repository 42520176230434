import styled, { css } from 'styled-components';

import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';

export const Dialog = styled(MuiDialog)`
  .MuiDialog-scrollBody {
    overflow-x: auto;
    display: flex;
    justify-content: safe center;
    align-items: safe center;

    &:after {
      display: none;
    }
  }

  .MuiDialog-paper {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    width: 830px;
    min-width: 830px;
    max-width: 830px;
    height: 680px;
    min-height: 680px;
    max-height: 680px;
  }
`;

export const DialogTitle = styled.div`
  display: flex;
  align-items: center;

  padding: 20px 40px 20px 40px;
  ${props =>
    props.$showCloseBtn &&
    css`
      padding: 20px 23px 20px 40px;
    `}
`;

export const DialogContent = styled(MuiDialogContent)`
  padding: 0 40px;
  overflow-y: hidden;

  display: flex;
  flex-direction: column;
`;

export const Instructions = styled.div`
  font-size: 16px;
  margin-bottom: 20px;

  strong {
    font-weight: 600;
  }
`;

export const DialogActions = styled(MuiDialogActions)`
  padding: 20px 40px 30px 40px;

  justify-content: flex-end;
  ${props =>
    props.$showScanParametersBtn &&
    css`
      justify-content: space-between;
    `}
`;

export const ScanParametersBtn = styled(Button)`
  background-color: transparent;
  color: #000000;

  font-size: 14px;
  font-weight: 600;

  height: 35px;
  padding: 0 8px 0 20px;
  border-radius: 10px;

  .MuiButton-startIcon {
    margin-right: 12px;
  }

  ${props =>
    props.$open &&
    css`
      box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.25) inset;
    `}
`;

export const PrimaryButton = styled(Button)`
  background-color: #552794;
  color: #ffffff;

  font-size: 14px;
  font-weight: 500;

  height: 35px;
  padding: 0 50px;
  border-radius: 10px;

  &:hover {
    background-color: #552794;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  &.Mui-disabled {
    opacity: 0.3;
    color: #ffffff;
  }
`;

export const SecondaryButton = styled(Button)`
  background-color: #ffffff;
  color: #000000;
  border-color: #d2d5da;

  font-size: 14px;
  font-weight: 500;

  height: 35px;
  padding: 0 50px;
  border-radius: 10px;

  &:hover {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
`;

export const TooltipCSS = css`
  & .MuiTooltip-tooltip {
    color: #000000;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 29%);
    padding: 8px 16px;
    font-size: 12px;
    border-radius: 10px;
  }

  .MuiTooltip-arrow {
    color: #ffffff;

    &:before {
      border: 1px solid #e6e8ed;
    }
  }
`;
