import displaySlice from './displaySlice';

export default function loadMask(bufGeo) {
  return (notify, getSceneState, callSceneAction) => {
    const { sliceIdx, mask } = getSceneState();

    mask.geometry.dispose();
    mask.geometry = bufGeo;

    mask.visible = true;

    // redraw slice to show mask
    callSceneAction(displaySlice(sliceIdx));

    return mask;
  };
}
