export default function clearCenterlineStates() {
  return (notify, getSceneState) => {
    const { centerlineChanges, centerline } = getSceneState();

    centerlineChanges.states = [centerline.serialize({ computeCarinaRegions: false })];
    centerlineChanges.idx = 0;

    notify();
  };
}
