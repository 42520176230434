import { API_SEED_URL, updateSeedURL } from '../../../../settings/api';

import { get3DPos } from '../../selectors';
import { apiFetch } from '../../../../utils/requests';
import { getToken } from '../../../auth/selectors';
import { getSelectedSegID, getEditSeedID } from '../../../viewer/selectors';
import setSegID from '../../../viewer/setSegID';
import setEditSeedID from '../../../viewer/setEditSeedID';
import addNewSegmentation from '../../../segmentations/addNewSegmentation';
import store from '../../../../store';

export default function placeSeed(canvasX, canvasY) {
  return (notify, getSceneState) => {
    const sceneState = getSceneState();
    const seed3DPos = get3DPos(sceneState, canvasX, canvasY);

    const { auth, viewer } = store.getState();

    const token = getToken(auth);
    const segID = getSelectedSegID(viewer);
    const body = {
      position: seed3DPos.toArray(),
    };

    const editSeedID = getEditSeedID(viewer);
    const reqArgs = !editSeedID
      ? [API_SEED_URL, token, 'POST', { ...body, segmentationID: segID }]
      : [updateSeedURL(editSeedID), token, 'PATCH', body];

    // stop placing seed on click to prevent multiple requests being sent
    store.dispatch(setEditSeedID(null));

    return apiFetch(...reqArgs).then(res => {
      store.dispatch(addNewSegmentation(res));
      store.dispatch(setSegID(res.id));
    });
  };
}
