import React, { useCallback, useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import { Plane, Vector3 } from 'three';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Branch } from '@visionair/centerline-3js';
import {
  useCanvasMouseEvent,
  useSceneSelector,
  useSceneDispatch,
  useSceneSetup,
} from '@visionair/scene-state-3js';

import {
  intersectPlaneWithLineSegments,
  createSphere,
  isPointInFrontOfPlane,
} from '../../../utils/threeUtils';
import setControlsEnabled from '../../../modules/scene/actions/airwayView/setControlsEnabled';
import setSelectedBranch from '../../../modules/scene/actions/airwayView/setSelectedBranch';

import updateCrossSection from '../../../modules/scene/actions/crossSectionView/updateCrossSection';
import setIsCrossSectionVisible from '../../../modules/viewer/setIsCrossSectionVisible';
import setIsAdjustingCenterline from '../../../modules/scene/actions/crossSectionView/setIsAdjustingCenterline';
import visualizeCenterline from '../../../modules/scene/actions/airwayView/visualizeCenterline';
import { CROSS_SECTION_VIEWER_NAME } from '../../../modules/scene/selectors';
import Instructions from './Instructions';
import centroidIconActive from '../../../images/centerline_tools/centroid_active.svg';

const SELECT_POINT_1 = 0;
const SELECT_POINT_2 = 1;
const ADJUST_CENTERLINE = 2;

const stepToText = {
  [SELECT_POINT_1]: 'Left-Click and drag to draw a line across the centerline.',
  [SELECT_POINT_2]: 'Left-Click and drag to draw a line across the centerline.',
  [ADJUST_CENTERLINE]: 'Use the adjustment window to reposition the centerline.',
};

const CP_MARKER_COLOR = 0xff0000;
export const CP_MARKER_NAME = 'cp-marker';

export default function AdjustToCentroidTool({ onFinish, className }) {
  const [step, setStep] = useState(SELECT_POINT_1);
  const canvasRef = useRef();
  const lineRef = useRef();
  const p1Ref = useRef();
  const fabricCanvasRef = useRef();
  const centerlineMarkerRef = useRef();

  const { canvasWidth, canvasHeight } = useSceneSelector(state => ({
    canvasWidth: state.canvas.width,
    canvasHeight: state.canvas.height,
  }));

  useEffect(() => {
    if (!fabricCanvasRef.current) {
      return;
    }

    fabricCanvasRef.current.setWidth(canvasWidth);
    fabricCanvasRef.current.setHeight(canvasHeight);
    fabricCanvasRef.current.calcOffset();
  }, [canvasWidth, canvasHeight]);

  const reduxDispatch = useDispatch();
  const sceneDispatch = useSceneDispatch();

  useSceneSetup(({ scene }) => {
    sceneDispatch(setControlsEnabled(false));

    fabricCanvasRef.current = new fabric.Canvas(canvasRef.current, {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      containerClass: className,
    });

    return () => {
      sceneDispatch(setControlsEnabled(true));
      sceneDispatch(setIsAdjustingCenterline(false), CROSS_SECTION_VIEWER_NAME);
      sceneDispatch(setSelectedBranch(null));
      sceneDispatch(visualizeCenterline());
      reduxDispatch(setIsCrossSectionVisible(false));

      fabricCanvasRef.current.dispose();

      if (centerlineMarkerRef.current) {
        scene.remove(centerlineMarkerRef.current);
      }
    };
  });

  useCanvasMouseEvent(
    'pointermove',
    useCallback(
      evt => {
        if (step !== SELECT_POINT_2) {
          return;
        }

        fabricCanvasRef.current.remove(lineRef.current);
        lineRef.current = new fabric.Line(
          [...p1Ref.current.mouseCoords, evt.offsetX, evt.offsetY],
          {
            stroke: '#00ff00',
            strokeWidth: 2,
            strokeDashArray: [5],
            strokeUniform: true,
            lockRotation: true,
          },
        );
        fabricCanvasRef.current.add(lineRef.current);
      },
      [step],
    ),
  );

  useCanvasMouseEvent(
    'pointerdown',
    useCallback(
      (evt, sceneState, raycaster) => {
        if (step !== SELECT_POINT_1) {
          return;
        }

        p1Ref.current = {
          mouseCoords: [evt.offsetX, evt.offsetY],
          threeCoords: raycaster.ray.origin.clone(),
        };

        setStep(SELECT_POINT_2);
      },
      [step],
    ),
  );

  useCanvasMouseEvent(
    'pointerup',
    useCallback(
      (evt, sceneState, raycaster) => {
        if (step !== SELECT_POINT_2) {
          return;
        }

        fabricCanvasRef.current.remove(lineRef.current);
        lineRef.current = new fabric.Line(
          [...p1Ref.current.mouseCoords, evt.offsetX, evt.offsetY],
          {
            stroke: '#00ff00',
            strokeWidth: 2,
            strokeDashArray: [5],
            strokeUniform: true,
            lockRotation: true,
          },
        );
        fabricCanvasRef.current.add(lineRef.current);

        const camDir = new Vector3(0, 0, -1).transformDirection(sceneState.camera.matrixWorld);
        const p3 = raycaster.ray.origin.clone().add(camDir);

        const plane = new Plane().setFromCoplanarPoints(
          p1Ref.current.threeCoords,
          raycaster.ray.origin,
          p3,
        );

        const branchIntData = sceneState.centerline.traverse(b => {
          const branchInt = intersectPlaneWithLineSegments(plane, b.points);
          if (branchInt) {
            const centerlinePlaneNormal = new Vector3()
              .crossVectors(b.direction, camDir)
              .normalize();
            const centerlinePlane = new Plane().setFromNormalAndCoplanarPoint(
              centerlinePlaneNormal,
              branchInt.point,
            );

            const isP1InFrontOfPlane = isPointInFrontOfPlane(
              centerlinePlane,
              p1Ref.current.threeCoords,
            );
            const isP2InFrontOfPlane = isPointInFrontOfPlane(centerlinePlane, raycaster.ray.origin);

            const arePointsCrossingBranch = isP1InFrontOfPlane !== isP2InFrontOfPlane;
            if (arePointsCrossingBranch) {
              return { ...branchInt, branch: b };
            }
          }

          return undefined;
        });

        // line segment did not cross centerline, remove line and start over
        if (!branchIntData) {
          fabricCanvasRef.current.remove(lineRef.current);
          fabricCanvasRef.current.renderAll();
          lineRef.current = null;
          setStep(SELECT_POINT_1);

          return;
        }

        sceneDispatch(setSelectedBranch(branchIntData.branch));

        centerlineMarkerRef.current = createSphere(
          sceneState.scene,
          branchIntData.point,
          CP_MARKER_COLOR,
          1,
        );
        centerlineMarkerRef.current.name = CP_MARKER_NAME;

        const dummyBranch = new Branch(branchIntData.branch.airway);
        dummyBranch.setPoints([
          branchIntData.point.clone().add(plane.normal),
          plane.normal
            .clone()
            .negate()
            .add(branchIntData.point),
        ]);

        sceneDispatch(
          updateCrossSection(dummyBranch, 0.5, {
            showCentroid: true,
            showCenterline: true,
            zoomFactor: 0.8,
            showDiams: true,
          }),
          CROSS_SECTION_VIEWER_NAME,
        );
        sceneDispatch(setIsAdjustingCenterline(true), CROSS_SECTION_VIEWER_NAME);
        reduxDispatch(setIsCrossSectionVisible(true));

        setStep(ADJUST_CENTERLINE);

        onFinish();
      },
      [step, sceneDispatch, reduxDispatch, onFinish],
    ),
  );

  return (
    <>
      <CanvasContainer style={{ height: `${canvasHeight}px`, width: `${canvasWidth}px` }}>
        <canvas
          style={{ position: 'absolute' }}
          ref={canvasRef}
          width={canvasWidth}
          height={canvasHeight}
        />
      </CanvasContainer>
      <Instructions>
        <img src={centroidIconActive} alt='' />
        {stepToText[step]}
      </Instructions>
    </>
  );
}

const CanvasContainer = styled.div`
  position: absolute;
  pointer-events: none;
`;
