const OUTSIDE_AIRWAY_COLOR = 0xff0000;

export default function checkCenterlineBounds() {
  return (notify, getSceneState) => {
    const { centerline, scene } = getSceneState();

    let isOutsideAirway = false;

    centerline.traverse(b => {
      if (!b.isEnclosedInAirway()) {
        b.visualize(scene, OUTSIDE_AIRWAY_COLOR);

        isOutsideAirway = true;
      }
    });

    return !isOutsideAirway;
  };
}
