import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Navbar from './Navbar';

const ContainerLoggedIn = ({ children, className }) => (
  <BodyContainer>
    <Navbar />
    <PageContent className={className}>{children}</PageContent>
  </BodyContainer>
);

ContainerLoggedIn.defaultProps = {
  className: '',
};

ContainerLoggedIn.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
  className: PropTypes.string,
};

export default ContainerLoggedIn;

const BodyContainer = styled.div`
  width: 100%;
  min-height: 100%;
  background-image: linear-gradient(to bottom, #2b2b2b, #1b1b1b);
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1024px;
  max-width: 1600px;
  min-height: calc(100vh - 68px); /* subtract navbar height */
  margin: 0 auto;
`;

const Content = styled.div`
  min-width: 1024px;
  max-width: 1920px;
  width: 100%;

  flex-grow: 1;

  padding-left: 100px;
  padding-right: 100px;
  @media (max-width: 1440px) {
    padding-left: 60px;
    padding-right: 60px;
  }
`;

ContainerLoggedIn.Content = Content;
