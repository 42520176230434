import { fromJS } from 'immutable';
import * as types from '../actions';

import { SCANSEG_STATUS } from '../../settings/segmentations';
import { SORT_ASC } from '../../components/InfiniteScrollTable';

const { PENDING, SEGMENTING, ERROR, NEEDS_REVIEW, REJECTED, VERIFIED } = SCANSEG_STATUS;

export const searchFields = {
  SCAN_NUM: 'scanNum', // value should match URL query string param
  DOC_NAME: 'doctorName',
  PATIENT_NAME: 'patientName',
  UPLOAD_DATE: 'uploadDate',
};

export const INITIAL_STATE = fromJS({
  isLoading: false,
  statusFilters: {
    [PENDING]: false, // true means status is shown
    [SEGMENTING]: true,
    [ERROR]: true,
    [NEEDS_REVIEW]: true,
    [REJECTED]: false,
    [VERIFIED]: false,
  },
  search: {
    field: searchFields.SCAN_NUM,
    value: '',
  },
  sort: {
    field: 'uploaded_at',
    dir: SORT_ASC,
  },
});

function setIsLoadingScans(state, isLoading) {
  return state.set('isLoading', isLoading);
}

function setScansStatusFilters(state, statuses) {
  return Object.entries(statuses).reduce((nextState, [status, isActive]) => {
    if (!Object.values(SCANSEG_STATUS).includes(status)) {
      return nextState;
    }

    return nextState.setIn(['statusFilters', status], isActive);
  }, state);
}

function clearScansStatusFilters(state) {
  return state.set(
    'statusFilters',
    state.get('statusFilters').map(() => false),
  );
}

function setSearchValues(state, { field, value }) {
  let newState = state;

  if (value !== undefined) {
    newState = newState.setIn(['search', 'value'], value);
  }
  if (field !== undefined) {
    newState = newState.setIn(['search', 'field'], field);
  }

  return newState;
}

function setSortValues(state, { field, dir }) {
  let newState = state;

  if (dir !== undefined) {
    newState = newState.setIn(['sort', 'dir'], dir);
  }
  if (field !== undefined) {
    newState = newState.setIn(['sort', 'field'], field);
  }

  return newState;
}

export default function scansTableReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_IS_LOADING_SCANS:
      return setIsLoadingScans(state, action.isLoading);
    case types.SET_SCANS_STATUS_FILTERS:
      return setScansStatusFilters(state, action.statuses);
    case types.CLEAR_SCANS_STATUS_FILTERS:
      return clearScansStatusFilters(state);
    case types.SET_SCANS_SEARCH:
      return setSearchValues(state, action);
    case types.SET_SCANS_SORT:
      return setSortValues(state, action);
    case types.AUTH_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
