// Value Representation
// http://dicom.nema.org/dicom/2013/output/chtml/part05/sect_6.2.html
export default {
  AE: 'AE',
  AS: 'AS',
  AT: 'AT',
  CS: 'CS',
  DA: 'DA',
  DS: 'DS',
  DT: 'DT',
  FL: 'FL',
  FD: 'FD',
  IS: 'IS',
  LO: 'LO',
  LT: 'LT',
  OB: 'OB',
  OD: 'OD',
  OF: 'OF',
  OW: 'OW',
  PN: 'PN',
  SH: 'SH',
  SL: 'SL',
  SQ: 'SQ',
  SS: 'SS',
  ST: 'ST',
  TM: 'TM',
  UI: 'UI',
  UL: 'UL',
  UN: 'UN',
  US: 'US',
  UT: 'UT',
};
