import React, { useState, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { parseDICOMFromZip } from '../../../utils/dicom/parse';
import { iso8601StringToDicomDateString, isValidPatientSex } from '../../../utils/dicom/dicom';
import { NoViableSeriesError, FileTooLargeError } from '../../../utils/dicom/dicom-errors';
import { fullUrl, httpStatusCodes, uploadFile } from '../../../utils/requests';

import { overwriteScanURL } from '../../../settings/api';

import ScanUploadDialogComp from './ScanUploadDialog';
import StepConfirmScanInfo from './StepConfirmScanInfo';
import StepParsingScan from './StepParsingScan';
import StepSelectScanZip from './StepSelectScanZip';
import StepUploadError from './StepUploadError';
import StepUploadingScan from './StepUploadingScan';

const ScanUploadStep = {
  SELECT_CT_ZIP: 'SELECT_CT_ZIP',
  PARSING_CT_ZIP: 'PARSING_CT_ZIP',
  CONFIRM_SCAN_INFO: 'CONFIRM_SCAN_INFO',
  UPLOADING_SCAN: 'UPLOADING_SCAN',
  UPLOAD_ERROR: 'UPLOAD_ERROR',
};

export default function ScanOverwriteModal({ scan, open, onClose, onUploadSuccess }) {
  const [currentStep, setCurrentStep] = useState(ScanUploadStep.SELECT_CT_ZIP);

  const [uploadError, setUploadError] = useState(null);
  const [validZipFile, setValidZipFile] = useState(null);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);

  const originalScanInfo = useMemo(
    () => ({
      patientName: scan.patient_name,
      patientMRN: scan.patient_mrn,
      patientDOB: scan.patientDOB,
      patientSex: scan.patientSex,
      scanDate: scan.ct_scan_date,
    }),
    [scan],
  );

  const [parsedScanInfo, setParsedScanInfo] = useState({
    patientName: '',
    patientMRN: '',
    patientDOB: null,
    patientSex: '',
    scanDate: null,
    filename: '',
    numSlices: null,
    sliceThickness: null,
  });

  const handleClose = () => {
    onClose();
  };

  const handleFileSelected = async zipFile => {
    setCurrentStep(ScanUploadStep.PARSING_CT_ZIP);

    try {
      const dicomInfo = await parseDICOMFromZip(zipFile);

      // parseDICOMFromZip returns null if no valid info found
      const scanDataFromDicom = {
        ...dicomInfo,
        filename: zipFile.name,
        patientSex: isValidPatientSex(dicomInfo.patientSex) ? dicomInfo.patientSex || '' : '',
      };

      setParsedScanInfo(scanDataFromDicom);
      setValidZipFile(zipFile);
      setCurrentStep(ScanUploadStep.CONFIRM_SCAN_INFO);
    } catch (err) {
      // error while parsing (bad zip file, no DICOMs found, etc.)
      console.error(err); // eslint-disable-line no-console
      setUploadError(err);
      setCurrentStep(ScanUploadStep.UPLOAD_ERROR);
    }
  };

  const uploadZip = async confirmedScanInfo => {
    setCurrentStep(ScanUploadStep.UPLOADING_SCAN);

    const opts = {
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`,
      },
      responseType: 'json',
      body: {
        patientName: confirmedScanInfo.patientName,
        patientMRN: confirmedScanInfo.patientMRN,
        patientSex: confirmedScanInfo.patientSex,
        patientDOB: iso8601StringToDicomDateString(confirmedScanInfo.patientDOB),
        seriesDate: iso8601StringToDicomDateString(confirmedScanInfo.scanDate),
        overwriteReason: confirmedScanInfo.overwriteReason,
        overwriteFix: confirmedScanInfo.overwriteFix,
      },
    };

    try {
      const response = await uploadFile(
        validZipFile,
        fullUrl(overwriteScanURL(scan.id)),
        'scanZipFile',
        opts,
        percentUploaded => setUploadPercent(percentUploaded),
      );

      setUploadComplete(true);

      const showSuccessMs = 2000;
      setTimeout(() => {
        onUploadSuccess(response.data);
      }, showSuccessMs);
    } catch (err) {
      console.error(err); // eslint-disable-line no-console

      if (err?.status === httpStatusCodes.UNPROCESSABLE_ENTITY) {
        setUploadError(new NoViableSeriesError(err.response.nonViableSeries));
      } else if (err?.message === 'max file size exceeded') {
        setUploadError(new FileTooLargeError());
      } else {
        setUploadError(err);
      }

      setCurrentStep(ScanUploadStep.UPLOAD_ERROR);
    }
  };

  // TODO: memoize
  const renderStep = () => {
    return {
      [ScanUploadStep.SELECT_CT_ZIP]: (
        <StepSelectScanZip onContinue={handleFileSelected} onClose={handleClose} />
      ),

      [ScanUploadStep.PARSING_CT_ZIP]: <StepParsingScan onClose={handleClose} />,

      [ScanUploadStep.CONFIRM_SCAN_INFO]: (
        <StepConfirmScanInfo
          originalScanInfo={originalScanInfo}
          parsedScanInfo={parsedScanInfo}
          onClose={handleClose}
          onContinue={uploadZip}
        />
      ),

      [ScanUploadStep.UPLOADING_SCAN]: (
        <StepUploadingScan
          uploadPercent={uploadPercent}
          uploadComplete={uploadComplete}
          onClose={handleClose}
        />
      ),

      [ScanUploadStep.UPLOAD_ERROR]: <StepUploadError error={uploadError} onClose={handleClose} />,
    }[currentStep];
  };

  return (
    <ScanUploadDialog open={open} $staticHeight={currentStep !== ScanUploadStep.CONFIRM_SCAN_INFO}>
      {renderStep()}
    </ScanUploadDialog>
  );
}

const ScanUploadDialog = styled(ScanUploadDialogComp)`
  .MuiDialog-paper {
    ${({ $staticHeight }) =>
      !$staticHeight &&
      css`
        height: unset;
        min-height: 680px;
        max-height: unset;
      `}
  }
`;
