import { SEG_BB_NAME } from '../../../../settings/segmentations';

export default function removeSegBB() {
  return (notify, getSceneState) => {
    const { scene } = getSceneState();
    const bb = scene.getObjectByName(SEG_BB_NAME);
    if (bb) {
      scene.remove(bb);
    }

    notify();
  };
}
