import React from 'react';
import styled from 'styled-components';

export default function Instructions({ children, ...props }) {
  return (
    <Container {...props} data-test='centerline-instructions'>
      {children}
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 20px;
  left: 20px;
  pointer-events: none;
  padding: 0 40px 0 15px;
  border-radius: 6px;
  background-color: #171616;
  color: white;
  font-weight: 500;
  height: 47px;

  img {
    margin-right: 15px;
  }
`;
