import visualizeCenterline from './visualizeCenterline';

export default function resetCenterline() {
  return (notify, getSceneState, callSceneAction) => {
    const { centerline, centerlineChanges } = getSceneState();

    centerline.fromJSON(centerlineChanges.states[centerlineChanges.idx]);
    callSceneAction(visualizeCenterline());

    notify();
  };
}
