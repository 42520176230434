import * as types from '../actions/auth';
import { showNotificationSuccess } from '../notifications/showNotification';

export function authLogoutAction() {
  return {
    type: types.AUTH_LOGOUT,
  };
}

export default function authLogout() {
  return dispatch => {
    dispatch(authLogoutAction());
    dispatch(showNotificationSuccess("You've been logged out successfully."));
    localStorage.clear();
  };
}
