import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSceneDispatch } from '@visionair/scene-state-3js';

import SliceViewer from './SliceViewer';
import { AXIAL_VIEWER_NAME, CORONAL_VIEWER_NAME } from '../../../modules/scene/selectors';
import { fetchMask } from '../../../utils/requests';
import setMaskVisible from '../../../modules/scene/actions/sliceView/setMaskVisible';
import {
  getSelectedVolID,
  getShowSliceViews,
  getShowSeedList,
} from '../../../modules/viewer/selectors';
import { showNotificationError } from '../../../modules/notifications/showNotification';
import SliceViewUtil from '../../../utils/SliceViewUtil';
import fetchSliceViewsByVol from '../../../modules/sliceViews/fetchSliceViewsByVol';
import ctIcon from '../../../images/ct-viewer.svg';
import minIcon from '../../../images/minimize.svg';
import setShowSliceViews from '../../../modules/viewer/setShowSliceViews';

export default function SliceViews({ seg, segIDToFetch }) {
  const [maskGeo, setMaskGeo] = useState();
  const [sliceViews, setSliceViews] = useState();

  const { volID, showSliceViews, showSeedList } = useSelector(({ viewer }) => ({
    volID: getSelectedVolID(viewer),
    showSliceViews: getShowSliceViews(viewer),
    showSeedList: getShowSeedList(viewer),
  }));

  const reduxDispatch = useDispatch();
  const sceneDispatch = useSceneDispatch();
  const history = useHistory();

  useEffect(() => {
    sceneDispatch(setMaskVisible(false), AXIAL_VIEWER_NAME);
    sceneDispatch(setMaskVisible(false), CORONAL_VIEWER_NAME);
    if (!segIDToFetch) {
      return;
    }

    fetchMask(segIDToFetch)
      .then(setMaskGeo)
      .catch(err => {
        history.push(`/scans`);
        reduxDispatch(
          showNotificationError('An unexpected error occurred while fetching airway mask.'),
        );
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, [segIDToFetch, sceneDispatch, history, reduxDispatch]);

  useEffect(() => {
    if (!volID) {
      return;
    }

    reduxDispatch(fetchSliceViewsByVol(volID))
      .then(views => {
        setSliceViews(views.map(view => new SliceViewUtil(view)));
      })
      .catch(err => {
        history.push(`/scans`);
        reduxDispatch(
          showNotificationError('An unexpected error occurred while fetching slice views.'),
        );
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, [reduxDispatch, volID, history]);

  if (!sliceViews?.length) {
    return <Container />;
  }

  const axialSliceView = sliceViews.find(sv => sv.isAxialView);
  const coronalSliceView = sliceViews.find(sv => sv.isCoronalView);

  return (
    <Container style={{ display: showSliceViews ? 'flex' : 'none' }} $showBorder={showSeedList}>
      <Header>
        <div>
          <img src={ctIcon} alt='' />
          CT Viewer
        </div>
        <button
          type='button'
          onClick={() => reduxDispatch(setShowSliceViews(false))}
          data-test='min-ct-btn'
        >
          <img src={minIcon} alt='Minimize' />
        </button>
      </Header>
      <SliceViewer
        seg={seg}
        sliceView={axialSliceView}
        maskGeo={maskGeo}
        canvasName={AXIAL_VIEWER_NAME}
      />
      <Spacer />
      <SliceViewer
        seg={seg}
        sliceView={coronalSliceView}
        maskGeo={maskGeo}
        canvasName={CORONAL_VIEWER_NAME}
      />
    </Container>
  );
}

const Container = styled.div`
  flex: 1 1 100px;
  height: 100%;
  background: #0a0a0a;

  border-right: 5px solid black;

  display: flex;
  flex-direction: column;

  ${({ $showBorder }) =>
    $showBorder &&
    `
    border-left: 5px solid black;
  `}
`;

const Header = styled.div`
  height: 48px;
  background-image: linear-gradient(to bottom, #414141, #0d0d0d);
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 12px;
  font-weight: bold;

  button {
    border: none;
    background: none;
    outline: none;
  }
`;

const Spacer = styled.div`
  height: 10px;
  background: #2b2b2b;
`;
