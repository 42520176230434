import { createTheme } from '@material-ui/core/styles';

import fonts from './fonts';

const muiTheme = createTheme({
  typography: {
    fontFamily: fonts.fontFamilyDefault,
    button: {
      textTransform: 'none',
    },
  },
  props: {
    MuiModal: {
      // setting this enables usage of iframe (ex. Groove Widget) while mui modal is open
      // https://github.com/mui/material-ui/issues/17497#issuecomment-579188864
      disableEnforceFocus: true,
    },
  },
});

export default muiTheme;
