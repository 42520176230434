import { useParams } from 'react-router-dom';

import useSelectorToJS from './useSelectorToJS';

export default function useScanSegs(scanID) {
  const params = useParams();
  const segs = useSelectorToJS(({ segmentations }) => segmentations.get('byId'));

  return Object.values(segs).filter(seg => seg.scanID === (scanID || params.scanID));
}
