import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { ButtonRound } from './buttons';

import logoWithWordmark from '../images/logo-teal-w-wordmark.svg';

export default function Navbar() {
  const history = useHistory();

  const handleLogoutClick = () => {
    history.push('/logout');
  };

  return (
    <NavBar>
      <Container>
        <Logo src={logoWithWordmark} alt='VisionAir Solutions' />
        <LogoutButtonContainer>
          <LogoutButton onClick={handleLogoutClick} data-test='logout-button'>
            Logout
          </LogoutButton>
        </LogoutButtonContainer>
      </Container>
      <BottomBorder />
    </NavBar>
  );
}

const NavBar = styled.div``;

const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  min-height: 64px;
  background-color: ${props => props.theme.colors.header};
`;

const Logo = styled.img`
  display: flex;
  padding-left: 50px;
`;

const LogoutButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
`;

const LogoutButton = styled(ButtonRound)`
  justify-content: flex-end;
  height: 29.5px;
  width: 154px;
  align-self: center;
  line-height: 0px;
  border: solid 1px ${props => props.theme.colors.lightGrey};
  background-color: ${props => props.theme.colors.lightGrey};
  color: ${props => props.theme.colors.white};
  outline: none;
`;

const BottomBorder = styled.div`
  height: 4px;
  background-color: #4e4e4e;
`;
