import { fromJS } from 'immutable';
import * as types from '../actions';
import { SUCCESS_NOTIFICATION } from '../../components/Notification';

export const INITIAL_STATE = fromJS({
  message: null,
  notificationType: SUCCESS_NOTIFICATION,
  showForMS: null,
  closeImmediately: false,
});

export default function notifications(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CLEAR_NOTIFICATION:
      return clearNotification(state, action);
    case types.SHOW_NOTIFICATION:
      return showNotification(state, action);
    default:
      return state;
  }
}

function clearNotification(state, { closeImmediately }) {
  return INITIAL_STATE.set('notificationType', state.get('notificationType')) // persist type so styling doesn't change during animation
    .set('closeImmediately', closeImmediately);
}

function showNotification(state, { message, notificationType, showForMS }) {
  return state
    .set('message', message)
    .set('notificationType', notificationType)
    .set('showForMS', showForMS)
    .set('closeImmediately', false);
}
