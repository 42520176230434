import colors from './colors';
import fonts from './fonts';

export default {
  content: {
    'border-radius': '7px',
    'box-shadow': '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
    'font-family': fonts.fontFamilyDefault,
    'font-size': '14px',
    color: colors.white,
    'background-color': '#2b2b2b',
    border: 'solid 1px #4e4e4e',
  },
  header: {
    height: '70px',
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    'font-size': '18px',
    'font-weight': '500',
    'border-bottom': 'none',
  },
  footer: {
    height: '100px',
    display: 'flex',
    'justify-content': 'center',
    'align-content': 'center',
    'align-items': 'center',
    'text-align': 'inherit',
    'border-top': 'none',
  },
};
