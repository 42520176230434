export const getSegByID = (state, id) => state.getIn(['byId', id]);
export const getSegSeedPoints = (state, id) => state.getIn(['byId', id, 'seedPoints']);
export const getSegsByVolID = (state, volID) =>
  state
    .get('byId')
    .filter(vol => vol.get('volume3DID') === volID)
    .toList();

export const getSegCenterlineStatus = (state, segID) =>
  getSegByID(state, segID).get('centerlineStatus');

export const getSegSensitivity = (state, id) => state.getIn(['byId', id, 'sensitivity']);
