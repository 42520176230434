import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useSceneSelector, useSceneDispatch } from '@visionair/scene-state-3js';

import ToolBtn from './ToolBtn';
import { getSelectedSeg } from '../../../modules/viewer/selectors';
import toggleTruncatedBBIcon from '../../../images/centerline_tools/toggle_truncated_bb.svg';
import toggleTruncatedBBIconActive from '../../../images/centerline_tools/toggle_truncated_bb_active.svg';
import { getSegBB } from '../../../modules/scene/selectors';
import setIsSegBBVisible from '../../../modules/scene/actions/airwayView/setIsSegBBVisible';

export default function BBVisToggleBtn() {
  const dispatch = useSceneDispatch();
  const segHasBB = useSelector(({ viewer, segmentations }) =>
    Boolean(getSelectedSeg(viewer, segmentations).get('bbMin')),
  );
  const isBBVisible = useSceneSelector(state => getSegBB(state)?.visible);

  const handleClick = () => {
    dispatch(setIsSegBBVisible(!isBBVisible));
  };

  return (
    <Btn
      tooltip={isBBVisible ? 'Hide Bounding Box' : 'Show Bounding Box'}
      disabled={!segHasBB}
      onClick={handleClick}
      active={isBBVisible}
      data-test='seg-bb-viz-btn'
    >
      <img src={isBBVisible ? toggleTruncatedBBIconActive : toggleTruncatedBBIcon} alt='' />
    </Btn>
  );
}

const Btn = styled(ToolBtn)`
  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }
`;
