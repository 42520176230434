export const AIRWAY_DEFAULT_COLOR = 0xffffff;
export const AIRWAY_DEFAULT_TRANSPARENT = true;
export const AIRWAY_DEFAULT_OPACITY = 0.5;
export const AIRWAY_MATERIAL_DEFAULTS = {
  color: AIRWAY_DEFAULT_COLOR,
  transparent: AIRWAY_DEFAULT_TRANSPARENT,
  opacity: AIRWAY_DEFAULT_OPACITY,
};

export const AIRWAY_LABEL = 'airway';
export const AIRWAY_FIT_ZOOM_FACTOR = 0.3;
export const AIRWAY_FIT_TWEEN_TIME = 1000; // ms

export const AXIAL_PLANE_NAME = 'axial-plane';
export const CORONAL_PLANE_NAME = 'coronal-plane';
