import React, { useState, useRef, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { fabric } from 'fabric';
import { Box3, Vector3 } from 'three';
import { useDispatch, useSelector } from 'react-redux';
import { useSceneSelector, useSceneDispatch, useSceneSetup } from '@visionair/scene-state-3js';

import lockCameraPosition from '../../../modules/scene/actions/airwayView/lockCameraPosition';
import { getAirwaySTL } from '../../../modules/scene/selectors';
import snapCameraToObjectCoronal from '../../../modules/scene/actions/snapCameraToObjectCoronal';
import snapCameraToObjectSagittal from '../../../modules/scene/actions/snapCameraToObjectSagittal';
import { getSelectedSegID } from '../../../modules/viewer/selectors';
import { showNotificationError } from '../../../modules/notifications/showNotification';
import Instructions from './Instructions';
import bbToolActive from '../../../images/centerline_tools/bb_active.svg';
import saveSeg from '../../../modules/segmentations/saveSeg';

const CORONAL_STEP = 0;
const SAGITTAL_STEP = 1;

const CAM_ZOOM_FACTOR = 0.7;

const stepToText = {
  [CORONAL_STEP]: (
    <span>
      Left-Click and drag the control points to resize the bounding box on the coronal plane.
      <br />
      You will need to draw a bounding box on the sagittal plane next.
    </span>
  ),
  [SAGITTAL_STEP]:
    'Left-Click and drag the control points to resize the bounding box on the sagittal plane.',
};

export default function BoundingBoxTool({ onFinish, className }) {
  const [step, setStep] = useState(CORONAL_STEP);
  const canvasRef = useRef();
  const rectRef = useRef();
  const fabricCanvasRef = useRef();
  const segBB = useMemo(() => new Box3(), []);

  const { canvasWidth, canvasHeight, airway, camera } = useSceneSelector(state => ({
    canvasWidth: state.canvas.width,
    canvasHeight: state.canvas.height,
    airway: getAirwaySTL(state),
    camera: state.camera,
  }));

  const segID = useSelector(({ viewer }) => getSelectedSegID(viewer));

  const dispatch = useDispatch();
  const sceneDispatch = useSceneDispatch();

  useEffect(() => {
    if (!fabricCanvasRef.current) {
      return;
    }

    fabricCanvasRef.current.setWidth(canvasWidth);
    fabricCanvasRef.current.setHeight(canvasHeight);
    fabricCanvasRef.current.calcOffset();
  }, [canvasWidth, canvasHeight]);

  useSceneSetup(sceneState => {
    sceneDispatch(lockCameraPosition(true));
    sceneDispatch(snapCameraToObjectCoronal(airway, CAM_ZOOM_FACTOR));

    const bb = new Box3().setFromObject(airway);
    const size = new Vector3();
    const center = new Vector3();

    bb.getSize(size);
    bb.getCenter(center);

    const pixPerUnit =
      canvasRef.current.width / ((sceneState.camera.right * 2) / sceneState.camera.zoom);

    const recWidth = pixPerUnit * size.x;
    const recHeight = pixPerUnit * size.z;

    const rect = new fabric.Rect({
      width: recWidth,
      height: recHeight,
      left: canvasRef.current.width / 2 - recWidth / 2,
      top: canvasRef.current.height / 2 - recHeight / 2,
      fill: 'rgba(0, 0, 0, 0)',
      stroke: 'yellow',
      strokeWidth: 2,
      strokeDashArray: [5],
      strokeUniform: true,
      lockRotation: true,
      hasBorders: false,
    });

    rectRef.current = rect;

    const canvas = new fabric.Canvas(canvasRef.current, {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      containerClass: className,
      uniformScaling: false,
    });
    canvas.add(rect);

    canvas.setActiveObject(rect);

    fabricCanvasRef.current = canvas;

    return () => sceneDispatch(lockCameraPosition(false));
  });

  const handleProgressClick = () => {
    if (step === CORONAL_STEP) {
      const boundingRect = rectRef.current.getBoundingRect();

      const bottomCorner = new Vector3(
        boundingRect.left / camera.right - 1,
        (boundingRect.top + boundingRect.height) / camera.bottom + 1,
      ).unproject(camera);
      const topCorner = new Vector3(
        (boundingRect.left + boundingRect.width) / camera.right - 1,
        boundingRect.top / camera.bottom + 1,
      ).unproject(camera);

      segBB.min.copy(bottomCorner);
      segBB.max.copy(topCorner);

      const bb = new Box3().setFromObject(airway);
      const size = new Vector3();
      const center = new Vector3();

      bb.getSize(size);
      bb.getCenter(center);

      const pixPerUnit = canvasRef.current.width / ((camera.right * 2) / camera.zoom);

      const recWidth = pixPerUnit * size.y;
      const recHeight = pixPerUnit * (segBB.max.z - segBB.min.z);
      const projectedBBMax = segBB.max.clone().project(camera);

      const rect = new fabric.Rect({
        width: recWidth,
        height: recHeight,
        left: canvasRef.current.width / 2 - recWidth / 2,
        top: (projectedBBMax.y - 1) * camera.bottom,
        fill: 'rgba(0, 0, 0, 0)',
        stroke: 'yellow',
        strokeWidth: 2,
        strokeDashArray: [3],
        strokeUniform: true,
        lockRotation: true,
        hasBorders: false,
      });

      fabricCanvasRef.current.remove(rectRef.current);
      fabricCanvasRef.current.add(rect);

      fabricCanvasRef.current.setActiveObject(rect);

      rectRef.current = rect;

      setStep(SAGITTAL_STEP);
      sceneDispatch(snapCameraToObjectSagittal(airway, CAM_ZOOM_FACTOR));
    } else {
      const boundingRect = rectRef.current.getBoundingRect();

      const bottomCorner = new Vector3(
        (boundingRect.left + boundingRect.width) / camera.right - 1,
        (boundingRect.top + boundingRect.height) / camera.bottom + 1,
      ).unproject(camera);
      const topCorner = new Vector3(
        boundingRect.left / camera.right - 1,
        boundingRect.top / camera.bottom + 1,
      ).unproject(camera);

      segBB.min.set(segBB.min.x, bottomCorner.y, Math.min(segBB.min.z, bottomCorner.z));
      segBB.max.set(segBB.max.x, topCorner.y, Math.max(segBB.max.z, topCorner.z));

      dispatch(
        saveSeg(segID, {
          bbMin: { X: segBB.min.x, Y: segBB.min.y, Z: segBB.min.z },
          bbMax: { X: segBB.max.x, Y: segBB.max.y, Z: segBB.max.z },
        }),
      ).catch(err => {
        dispatch(showNotificationError('There was an error saving bounding box. Please try again'));
        // eslint-disable-next-line no-console
        console.error(err);
      });

      fabricCanvasRef.current.dispose();

      onFinish();
    }
  };

  const handleCancel = () => {
    fabricCanvasRef.current.dispose();

    onFinish();
  };

  return (
    <>
      <canvas
        ref={canvasRef}
        width={canvasWidth}
        height={canvasHeight}
        data-test='bounding-box-canvas'
      />
      <Intstructs>
        <img src={bbToolActive} alt='' />
        {stepToText[step]}
      </Intstructs>
      <FooterBtns style={{ bottom: `-${canvasHeight - 20}px` }}>
        <CancelBtn onClick={handleCancel}>Cancel</CancelBtn>
        <ConfirmBtn onClick={handleProgressClick}>
          {step === CORONAL_STEP ? 'Next' : 'Confirm'}
        </ConfirmBtn>
      </FooterBtns>
    </>
  );
}

const FooterBtns = styled.div`
  position: absolute;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CancelBtn = styled.button`
  background: #393939;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  width: 160px;
  height: 35px;
  margin-right: 10px;
`;

const ConfirmBtn = styled.button`
  background: #19822d;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  width: 160px;
  height: 35px;
  margin-left: 10px;
`;

const Intstructs = styled(Instructions)`
  height: unset;
  padding-top: 15px;
  padding-bottom: 15px;
`;
