import { Map } from 'immutable';
import jwt from 'jwt-decode';
import * as types from '../actions';

export const INITIAL_STATE = new Map({
  connected: true,
  showLogoutWarning: false,
  token: null,
  userID: null,
  userType: null,
  email: null,
  tokenExpiration: null,
  tokenIssuedAt: null,
  tokenOrigIssuedAt: null,
  refreshedAtMs: null,
  recaptcha: null,
});

function authLogout(state) {
  return state.delete('token');
}

function showLogoutWarning(state, { showWarning }) {
  return state.set('showLogoutWarning', showWarning);
}

function authSetToken(state, token) {
  if (!token) {
    return state.set('token', null);
  }

  const { exp, iat, id, usertype, email, origIssuedAt } = jwt(token);

  return state
    .set('token', token)
    .set('tokenExpiration', exp)
    .set('tokenIssuedAt', iat)
    .set('tokenOrigIssuedAt', origIssuedAt)
    .set('userID', id)
    .set('email', email)
    .set('userType', usertype);
}

function authLogin(state, payload) {
  return authSetToken(state, payload.token);
}

function authRefresh(state, payload) {
  return authSetToken(state, payload.token).set('refreshedAtMs', payload.refreshedAtMs);
}

function authSetConnected(state, payload) {
  return state.set('connected', payload.isConnected);
}

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.AUTH_LOGIN:
      return authLogin(state, action.payload);
    case types.AUTH_LOGOUT:
      return authLogout(state);
    case types.AUTH_REFRESH:
      return authRefresh(state, action.payload);
    case types.SHOW_LOGOUT_WARNING:
      return showLogoutWarning(state, action);
    case types.AUTH_SET_CONNECTED:
      return authSetConnected(state, action.payload);
    default:
      return state;
  }
}
