/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import BaseComponent from './BaseComponent';
import ViewerPage from './pages/ViewerPage';
import ScansPage from './pages/ScansPage';

import LogoutWarning from './LogoutWarning';
import ReenterPasswordModal from './ReenterPasswordModal';

import authRefresh from '../modules/auth/authRefresh';
import authLogout from '../modules/auth/authLogout';
import {
  getIsLoggedIn,
  getTokenRefreshedAtMs,
  getTokenOrigIssuedAtMs,
  getShowLogoutWarning,
} from '../modules/auth/selectors';

import { REFRESH_THROTTLE_MS, MAX_TOKEN_REFRESH_MS } from '../settings/broncUsers';
import { HttpUnauthorizedError } from '../utils/requests';

class PrivateRoutes extends BaseComponent {
  componentDidMount() {
    this.isRefreshingToken = false;
    window.addEventListener('mousemove', this.onWindowMouseMove);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.onWindowMouseMove);
  }

  onWindowMouseMove() {
    const { showLogoutWarning, tokenRefreshedAtMs, tokenOrigIssuedAtMs } = this.props;
    const tokenNeedsRefreshed =
      Date.now() - (tokenRefreshedAtMs || -Infinity) > REFRESH_THROTTLE_MS;

    if (tokenNeedsRefreshed && !this.isRefreshingToken && !showLogoutWarning) {
      this.isRefreshingToken = true;

      const maxTokenRefreshReached = Date.now() > tokenOrigIssuedAtMs + MAX_TOKEN_REFRESH_MS;
      if (maxTokenRefreshReached) {
        return;
      }

      this.props
        .authRefresh()
        .then(() => {
          this.isRefreshingToken = false;
        })
        .catch(err => {
          console.error(err); // eslint-disable-line no-console
          this.isRefreshingToken = false;

          if (err instanceof HttpUnauthorizedError) {
            this.props.authLogout();
          }
        });
    }
  }

  render() {
    const { props } = this;

    return (
      <>
        {props.showLogoutWarning && <LogoutWarning show={props.showLogoutWarning} />}
        <ReenterPasswordModal />
        <Switch>
          <Route exact path='/scans' component={ScansPage} />
          <Route path='/viewer/:scanID' component={ViewerPage} />
        </Switch>
      </>
    );
  }
}

PrivateRoutes.propTypes = {
  authRefresh: PropTypes.func.isRequired,
  authLogout: PropTypes.func.isRequired,
  showLogoutWarning: PropTypes.bool,
  tokenRefreshedAtMs: PropTypes.number,
  tokenOrigIssuedAtMs: PropTypes.number.isRequired,
};

PrivateRoutes.defaultProps = {
  showLogoutWarning: false,
  tokenRefreshedAtMs: 0,
};

export const PrivateRoutesComponent = props => {
  if (!props.isLoggedIn) {
    const redirectObject = {
      pathname: '/',
      state: { from: props.location },
    };

    return <Redirect to={redirectObject} />;
  }

  return <PrivateRoutes {...props} />;
};

PrivateRoutesComponent.propTypes = {
  authRefresh: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  location: PropTypes.shape({}),
  showLogoutWarning: PropTypes.bool,
};

PrivateRoutesComponent.defaultProps = {
  location: {},
  showLogoutWarning: false,
};

PrivateRoutesComponent.displayName = 'PrivateRoutes'; // fixes jest snapshot tests

function mapStateToProps({ auth }) {
  return {
    isLoggedIn: getIsLoggedIn(auth),
    tokenRefreshedAtMs: getTokenRefreshedAtMs(auth),
    tokenOrigIssuedAtMs: getTokenOrigIssuedAtMs(auth),
    showLogoutWarning: getShowLogoutWarning(auth),
  };
}

export default connect(mapStateToProps, { authRefresh, authLogout })(PrivateRoutesComponent);
