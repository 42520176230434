import * as types from '../actions';
import { getSliceViewsByVolume } from '../../settings/api';
import { apiFetch } from '../../utils/requests';
import { getToken } from '../auth/selectors';

export const addSliceViews = sliceViews => ({
  type: types.ADD_SLICE_VIEWS,
  sliceViews,
});

export default function fetchSliceViewsBy(volID) {
  return (dispatch, getState) => {
    const token = getToken(getState().auth);

    return apiFetch(getSliceViewsByVolume(volID), token, 'GET').then(views => {
      dispatch(addSliceViews(views));
      return views;
    });
  };
}
