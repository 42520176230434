import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useSceneDispatch } from '@visionair/scene-state-3js';

import { makeStyles, withStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';

import setHUWindow from '../../../modules/scene/actions/sliceView/setHUWindow';

import {
  HU_MAX,
  HU_MIN,
  LUNG_HU_MIN,
  LUNG_HU_MAX,
  MEDIASTINUM_HU_MIN,
  MEDIASTINUM_HU_MAX,
} from '../../../three/CTMaterial';
import { getShowCTWindowing } from '../../../modules/viewer/selectors';

const WindowSlider = withStyles({
  root: {
    color: 'rgba(0, 0, 0, 0)',
    background: 'none',
    padding: 0,
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: '#fff',
    transform: 'translateX(1px)',
    boxShadow: '0px 0px 0px 8px rgb(63 81 181 / 16%)',
  },
  track: {
    width: 36,
  },
})(Slider);

const useSelectStyles = makeStyles({
  select: {
    borderRadius: 6,
    border: 'solid 1px #656565',
    background: '#222222',
    color: 'white',

    '& .MuiMenuItem-root': {
      fontSize: '12px',
      fontWeight: 600,
    },
  },
});

const LUNG = 'lung';
const MEDIASTINUM = 'mediastinum';
const CUSTOM = 'custom';

export default function WindowControls() {
  const [windowVals, setWindowVals] = useState([LUNG_HU_MIN, LUNG_HU_MAX]);
  const [windowPreset, setWindowPreset] = useState(LUNG);

  const show = useSelector(({ viewer }) => getShowCTWindowing(viewer));

  const sceneDispatch = useSceneDispatch();
  const classes = useSelectStyles();

  const handleChange = (evt, newVals) => {
    setWindowVals(newVals);
    sceneDispatch(setHUWindow(...newVals));
  };

  const handleSelect = evt => {
    let windowSelect;

    switch (evt.target.value) {
      case LUNG:
        windowSelect = [LUNG_HU_MIN, LUNG_HU_MAX];
        break;
      case MEDIASTINUM:
        windowSelect = [MEDIASTINUM_HU_MIN, MEDIASTINUM_HU_MAX];
        break;
      case CUSTOM:
      default:
    }

    if (windowSelect) {
      handleChange(undefined, windowSelect);
      setWindowPreset(evt.target.value);
    }
  };

  if (!show) {
    return null;
  }

  return (
    <S.WindowControls data-test='ct-window-controls'>
      <S.Header style={{ marginBottom: '20px' }}>Window Settings</S.Header>
      <S.SliderContainer>
        <S.LabelContainer data-test='windowControls-sliderLabels'>
          <S.WindowMarker
            style={{ bottom: `${(100 * (windowVals[0] - HU_MIN)) / (HU_MAX - HU_MIN)}%` }}
          />
          <S.WindowMarker
            style={{ bottom: `${(100 * (windowVals[1] - HU_MIN)) / (HU_MAX - HU_MIN)}%` }}
          />
          <S.WindowMarker style={{ bottom: '25%', background: 'black', height: '1px' }} />
          <S.WindowMarker style={{ bottom: '50%', background: 'black', height: '1px' }} />
          <S.HULabel style={{ bottom: '0' }}>-1000</S.HULabel>
          <S.HULabel style={{ bottom: '25%' }}>0</S.HULabel>
          <S.HULabel style={{ bottom: '50%' }}>1000</S.HULabel>
          <S.HULabel style={{ bottom: '100%' }}>3000</S.HULabel>
        </S.LabelContainer>
        <WindowSlider
          min={HU_MIN}
          max={HU_MAX}
          step={10}
          value={windowVals}
          onChange={(...args) => {
            handleChange(...args);
            setWindowPreset(CUSTOM);
          }}
          valueLabelDisplay='off'
          orientation='vertical'
          data-test='ct-window-slider'
        />
      </S.SliderContainer>
      <S.SelectContainer>
        <S.Header style={{ marginTop: '25px', marginBottom: '10px' }}>Window Preset</S.Header>
        <S.WindowSelect
          value={windowPreset}
          onChange={handleSelect}
          disableUnderline
          data-test='windowControls-presetSelect'
          MenuProps={{
            classes: {
              paper: classes.select,
            },
          }}
          inputProps={{ 'data-test': 'windowControls-presetSelectInput' }}
        >
          <MenuItem value={LUNG}>Lung</MenuItem>
          <MenuItem value={MEDIASTINUM}>Mediastinum</MenuItem>
          <MenuItem hidden value={CUSTOM}>
            Custom
          </MenuItem>
        </S.WindowSelect>
      </S.SelectContainer>
    </S.WindowControls>
  );
}

const S = {};

S.WindowControls = styled.div`
  position: absolute;
  top: 70px;
  left: 58px;

  width: 148px;
  color: white;
  font-size: 12px;
  font-weight: 600;
  background-color: #1b1b21;
  z-index: 10;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.64);
  padding: 20px 12px 12px 12px;
`;

S.Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

S.SliderContainer = styled.div`
  position: relative;

  height: 485px;
  @media (max-height: 800px) {
    height: 328px;
  }

  width: 36px;
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(40, 40, 40, 1) 25%,
    rgba(254, 254, 254, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  padding: 10px 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.55);
  border: solid 1px #646464;
  border-radius: 6px;
  margin-left: 25px;
`;

S.LabelContainer = styled.div`
  position: absolute;
  height: calc(100% - 20px);
  top: 10px;
`;

S.WindowMarker = styled.div`
  width: 34px;
  height: 2px;
  position: absolute;
  left: 0;
  background: #00ffc2;
`;

S.HULabel = styled.div`
  position: absolute;
  left: 60px;
  font-size: 11px;
  font-weight: 500;
  transform: translateY(50%);
`;

S.SelectContainer = styled.div``;

S.WindowSelect = styled(Select)`
  && {
    width: 100%;
    height: 34px;

    padding: 12px;
    border-radius: 6px;

    color: white;
    background: #292929;

    font-size: 12px;
    font-weight: 600;
  }

  .MuiSelect-select {
    padding-right: 12px;

    :focus {
      background-color: #292929;
    }
  }

  .MuiSelect-icon {
    color: white;
  }
`;
