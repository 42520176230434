import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCanvasMouseEvent, useSceneDispatch } from '@visionair/scene-state-3js';

import visualizeCenterline from '../../../modules/scene/actions/airwayView/visualizeCenterline';
import { getCarinaMesh, isClick } from '../../../modules/scene/selectors';

import { CARINA_DEFAULT_COLOR } from '../../../settings/centerline';
import { showNotificationError } from '../../../modules/notifications/showNotification';
import Instructions from './Instructions';
import mergeIconActive from '../../../images/centerline_tools/merge_active.svg';

const HOVER_CARINA_COLOR = 0xffff00;
const SELECTED_CARINA_COLOR = 0xff0000;

export default function MergeCarinaTool({ onFinish }) {
  const [selectedBranch, setSelectedBranch] = useState();
  const prevBranchMeshRef = useRef();
  const [isMerged, setIsMerged] = useState(false);

  const sceneDispatch = useSceneDispatch();
  const dispatch = useDispatch();

  useCanvasMouseEvent(
    'pointermove',
    useCallback(
      (evt, sceneState, raycaster) => {
        if (isMerged) {
          return;
        }

        const { branch } = sceneState.centerline.getClosestCarinaPointToRay(raycaster.ray);

        if (
          prevBranchMeshRef.current &&
          prevBranchMeshRef.current.name !== selectedBranch?.carinaName
        ) {
          prevBranchMeshRef.current.material.color.set(CARINA_DEFAULT_COLOR);
        }

        if (selectedBranch !== branch) {
          const carinaMesh = getCarinaMesh(sceneState, branch);
          carinaMesh.material.color.set(HOVER_CARINA_COLOR);

          prevBranchMeshRef.current = carinaMesh;
        }
      },
      [selectedBranch, isMerged],
    ),
  );

  useCanvasMouseEvent(
    'pointerup',
    useCallback(
      (evt, sceneState, raycaster) => {
        if (isMerged || !isClick(sceneState, evt)) {
          return;
        }

        const { branch } = sceneState.centerline.getClosestCarinaPointToRay(raycaster.ray);

        if (!selectedBranch) {
          const carinaMesh = getCarinaMesh(sceneState, branch);
          carinaMesh.material.color.set(SELECTED_CARINA_COLOR);

          setSelectedBranch(branch);
        } else if (selectedBranch !== branch) {
          // merge b1 into b2
          const b1 = selectedBranch;
          const b2 = branch;

          if (b1 !== b2.parent && b2 !== b1.parent) {
            dispatch(showNotificationError('Carina not of parent child relationship', 5000));
            return;
          }

          if (b1 === b2.parent) {
            // merging parent into child
            const otherChildren = b1.children.filter(b => b !== b2);
            otherChildren.forEach(b => {
              b.setPoints([b2.end.clone(), ...b.points.slice(1)]);
            });
            b1.setChildren([...b2.children, ...otherChildren]);
            b1.children.forEach(b => {
              b.setParent(b1);
            });
            b1.setPoints([...b1.points, b2.end.clone()]);

            b1.fixCarina();
            b1.samplePointsAtDist(1);
            b1.children.forEach(c => {
              c.samplePointsAtDist(1);
            });

            b2.setParent(null);
            b2.setChildren([]);
          } else {
            // merging child into parent
            const otherChildren = b2.children.filter(b => b !== b1);
            b2.setChildren([...b1.children, ...otherChildren]);
            b1.children.forEach(b => {
              b.setPoints([b2.end.clone(), ...b.points.slice(1)]);
              b.setParent(b2);
            });

            b2.fixCarina();
            b2.samplePointsAtDist(1);
            b2.children.forEach(c => {
              c.samplePointsAtDist(1);
            });

            b1.setParent(null);
            b1.setChildren([]);
          }

          sceneDispatch(visualizeCenterline());
          setIsMerged(true);

          onFinish();
        }
      },
      [selectedBranch, onFinish, sceneDispatch, dispatch, isMerged],
    ),
  );

  useEffect(() => () => sceneDispatch(visualizeCenterline()), [sceneDispatch]);

  let instructionsText = 'Left-click to select a carina point to merge into another carina point.';
  if (isMerged) {
    instructionsText = 'Confirm the merged carina position.';
  } else if (selectedBranch) {
    instructionsText = 'Left-click the point the carina should merge into.';
  }

  return (
    <Instructions>
      <img src={mergeIconActive} alt='' />
      {instructionsText}
    </Instructions>
  );
}
