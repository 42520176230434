import * as types from '../actions';

import addNewSegmentation from './addNewSegmentation';
import setSegID from '../viewer/setSegID';
import { getToken } from '../auth/selectors';

import { deleteSeedURL } from '../../settings/api';
import { apiFetch } from '../../utils/requests';

export function removeSeed(seed) {
  return {
    type: types.REMOVE_SEED,
    seed,
  };
}

export default function deleteSeed(seedID) {
  return (dispatch, getState) => {
    const token = getToken(getState().auth);

    return apiFetch(deleteSeedURL(seedID), token, 'DELETE').then(seg => {
      dispatch(addNewSegmentation(seg));
      dispatch(setSegID(seg.id));
    });
  };
}
