import { Mesh, OrthographicCamera, PlaneGeometry, Scene, Texture } from 'three';
import { WebGLRenderer } from 'three/src/renderers/WebGLRenderer';

import CTMaterial from '../three/CTMaterial';
import { fetchSliceImages } from './requests';

const DEFAULT_SLICE_SIZE_MB = 0.03; // 30 KB
const MINIMUM_SCROLL_SPEED = 5 / 1000; // 5 slices per second
const MAXIMUM_SCROLL_SPEED = 60 / 1000; // 60 slices per second
const SCROLL_SPEED_BUFFER_LENGTH = 3; // number of past display times to factor into scroll speed calculation
const DEFAULT_WAIT_TIME_MS = 500; // time it should take to fetch and display slices when no buffer
const DEFAULT_IMG_WIDTH = 512;
const DEFAULT_IMG_HEIGHT = 512;

const LOADING_STATE = 'loading';

export default class SliceLoader {
  constructor(canvas, sliceView) {
    this.isWorkerContext = canvas instanceof OffscreenCanvas;

    // pixel offset amount in the image canvas's coordinate system
    this.canvasX = 0;
    this.canvasY = 0;
    this.zoom = 1;

    this.canvas = canvas;
    this.canvasCtx = canvas.getContext('2d');
    this.initializeSliceView(sliceView);

    this.threeCanvas = this.isWorkerContext
      ? new OffscreenCanvas(DEFAULT_IMG_WIDTH, DEFAULT_IMG_HEIGHT)
      : document.createElement('canvas');
    this.renderer = new WebGLRenderer({ canvas: this.threeCanvas });

    this.imageMesh = new Mesh(
      new PlaneGeometry(DEFAULT_IMG_WIDTH, DEFAULT_IMG_HEIGHT),
      new CTMaterial(),
    );
    this.imageMesh.position.set(0, 0, 1);

    this.scene = new Scene();
    this.scene.add(this.imageMesh);

    this.camera = new OrthographicCamera(
      DEFAULT_IMG_WIDTH / -2,
      DEFAULT_IMG_WIDTH / 2,
      DEFAULT_IMG_HEIGHT / 2,
      DEFAULT_IMG_HEIGHT / -2,
    );
    this.camera.up.set(0, -1, 0);
    this.camera.lookAt(this.imageMesh.position);

    // slice idx that is currently draw to the canvas
    // may be different from currSliceIdx if slice fetched and displayed asyncronously
    this.currDisplayedIdx = null;

    // used for determining when and how many slices to fetch for optimal UX
    const { rtt, downlink } = navigator.connection;
    this.msPerSlice = ((DEFAULT_SLICE_SIZE_MB * 8) / downlink) * 1000 + rtt;
    this.prevDisplayTimes = new Array(SCROLL_SPEED_BUFFER_LENGTH).fill(0);
    this.scrollSpeed = MINIMUM_SCROLL_SPEED;

    // num of slices currently being fetched/parsed
    this.numPendingSlices = 0;
  }

  initializeSliceView(sliceView) {
    this.sliceView = sliceView;
    this.sliceArr = new Array(this.sliceView.numSlices);

    this.calculateDrawDimensions();

    this.prevSliceIdx = 0;
    this.currSliceIdx = 0;
  }

  resizeCanvas(width, height) {
    const { filter } = this.canvasCtx;

    this.canvas.width = width;
    this.canvas.height = height;

    this.canvasCtx.filter = filter;

    this.calculateDrawDimensions();
    this.drawSlice(this.currDisplayedIdx);
  }

  setImageView(zoom, canvasX, canvasY) {
    this.canvasX = canvasX;
    this.canvasY = canvasY;
    this.zoom = zoom;

    this.calculateDrawDimensions();
    this.drawSlice(this.currDisplayedIdx);
  }

  setWindow(min, max) {
    this.imageMesh.material.setWindow(min, max);
  }

  calculateDrawDimensions() {
    const sliceSize = this.sliceView.size;

    if (sliceSize.x / this.canvas.width >= sliceSize.y / this.canvas.height) {
      this.dWidth = this.canvas.width;
      this.dHeight = Math.min(
        this.canvas.height,
        this.zoom * this.dWidth * (sliceSize.y / sliceSize.x),
      );

      this.viewWidth = this.sliceView.size.x / this.zoom;
      this.viewHeight = (this.viewWidth * this.dHeight) / this.dWidth;

      this.dX = 0;
      this.dY = (this.canvas.height - this.dHeight) / 2;
    } else {
      this.dHeight = this.canvas.height;
      this.dWidth = Math.min(
        this.canvas.width,
        this.zoom * this.dHeight * (sliceSize.x / sliceSize.y),
      );

      this.viewHeight = this.sliceView.size.y / this.zoom;
      this.viewWidth = this.dWidth * (this.viewHeight / this.dHeight);

      this.dY = 0;
      this.dX = (this.canvas.width - this.dWidth) / 2;
    }
  }

  calculateNumSlicesToFetch(bufferHealth) {
    if (bufferHealth === Infinity) {
      return 0;
    }
    if (bufferHealth === 0) {
      return Math.ceil(DEFAULT_WAIT_TIME_MS / this.msPerSlice);
    }

    return Math.ceil(bufferHealth / (this.scrollSpeed * this.msPerSlice));
  }

  calculateBufferThreshold() {
    return Math.round(DEFAULT_WAIT_TIME_MS / this.msPerSlice);
  }

  calculateScrollSpeed(sliceIdx) {
    this.prevDisplayTimes.unshift({ sliceIdx, ts: performance.now() });
    this.prevDisplayTimes.splice(SCROLL_SPEED_BUFFER_LENGTH);

    if (!this.prevDisplayTimes[SCROLL_SPEED_BUFFER_LENGTH - 1]) {
      this.scrollSpeed = MINIMUM_SCROLL_SPEED;
      return this.scrollSpeed;
    }

    const deltaSlices = Math.abs(
      this.prevDisplayTimes[0].sliceIdx -
        this.prevDisplayTimes[SCROLL_SPEED_BUFFER_LENGTH - 1].sliceIdx,
    );
    const deltaMs =
      this.prevDisplayTimes[0].ts - this.prevDisplayTimes[SCROLL_SPEED_BUFFER_LENGTH - 1].ts;

    this.scrollSpeed = deltaSlices / deltaMs || MINIMUM_SCROLL_SPEED;

    this.scrollSpeed = Math.max(
      Math.min(this.scrollSpeed, MAXIMUM_SCROLL_SPEED),
      MINIMUM_SCROLL_SPEED,
    );

    return this.scrollSpeed;
  }

  calculateMaxPendingSlices() {
    return (2 * DEFAULT_WAIT_TIME_MS) / this.msPerSlice;
  }

  loadSlices(start, numSlices, token) {
    this.numPendingSlices += numSlices;

    for (let i = start; i < start + numSlices; i += 1) {
      this.sliceArr[i] = LOADING_STATE;
    }

    return fetchSliceImages(this.sliceView.id, start, numSlices, token)
      .then(imgDataArr => {
        imgDataArr.forEach((imgData, i) => {
          this.sliceArr[start + i] = imgData;
        });

        this.numPendingSlices -= numSlices;
      })
      .catch(err => {
        for (let i = start; i < start + numSlices; i += 1) {
          this.sliceArr[i] = undefined;
        }

        this.numPendingSlices -= numSlices;

        throw err;
      });
  }

  async drawSlice(sliceIdx) {
    let img = this.sliceArr[sliceIdx];
    if (!img || img === LOADING_STATE || this.currSliceIdx !== sliceIdx) {
      return;
    }

    if (img instanceof File) {
      const bitmap = await createImageBitmap(img);
      img = new Texture(bitmap);
      img.needsUpdate = true;
      img.height = bitmap.height;
      img.width = bitmap.width;
      this.sliceArr[sliceIdx] = img;
    }

    this.currDisplayedIdx = sliceIdx;

    const patientImgRatioX = this.sliceView.size.x / img.width;
    const patientImgRatioY = this.sliceView.size.y / img.height;

    let sWidth;
    let sHeight;
    if (this.sliceView.size.x / this.canvas.width >= this.sliceView.size.y / this.canvas.height) {
      sWidth = img.width / this.zoom;
      sHeight = (this.viewWidth * this.dHeight) / this.dWidth / patientImgRatioY;
    } else {
      sHeight = img.height / this.zoom;
      sWidth = (this.viewHeight * this.dWidth) / this.dHeight / patientImgRatioX;
    }

    const sXCanvasOffset = (this.canvasX / this.canvas.width) * img.width;
    const sYCanvasOffset = (this.canvasY / this.canvas.height) * img.height;
    const sXZoomOffset = (this.sliceView.size.x - this.viewWidth) / 2 / patientImgRatioX;
    const sYZoomOffset = (this.sliceView.size.y - this.viewHeight) / 2 / patientImgRatioY;

    const clampedSX = Math.max(0, Math.min(img.width - sWidth, sXCanvasOffset + sXZoomOffset));
    const clampedSY = Math.max(0, Math.min(img.height - sHeight, sYCanvasOffset + sYZoomOffset));

    // render image in three.js scene with a custom material that allows for HU windowing
    // then draw the image to the canvas that has been passed in

    if (this.threeCanvas.width !== img.width || this.threeCanvas.height !== img.height) {
      this.imageMesh.geometry = new PlaneGeometry(img.width, img.height);
      this.camera.left = img.width / -2;
      this.camera.right = img.width / 2;
      this.camera.top = img.height / 2;
      this.camera.bottom = img.height / -2;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(img.width, img.height, false);
    }

    this.imageMesh.material.setTexture(img);
    this.renderer.render(this.scene, this.camera);

    this.canvasCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.canvasCtx.drawImage(
      this.threeCanvas,
      clampedSX,
      clampedSY,
      sWidth,
      sHeight,
      this.dX,
      this.dY,
      this.dWidth,
      this.dHeight,
    );

    const canvasX = (clampedSX - sXZoomOffset) * (this.canvas.width / img.width);
    const canvasY = (clampedSY - sYZoomOffset) * (this.canvas.height / img.height);
    const imgDimensions = {
      dWidth: this.dWidth,
      dHeight: this.dHeight,
      viewWidth: this.viewWidth,
      viewHeight: this.viewHeight,
      imgPixelWidth: img.width,
      imgPixelHeight: img.height,
      canvasPixelWidth: this.canvas.width,
      canvasPixelHeight: this.canvas.height,
      canvasX,
      canvasY,
      zoom: this.zoom,
    };

    if (this.isWorkerContext) {
      postMessage({ message: 'displaySlice', sliceIdx, imgDimensions });
    } else {
      this.sliceCallback(sliceIdx, imgDimensions);
    }
  }

  async displaySlice(sliceIdx, token) {
    this.calculateScrollSpeed(sliceIdx);

    const sliceIdxNum = Number(sliceIdx);
    this.currSliceIdx = sliceIdxNum;

    this.drawSlice(this.currSliceIdx);

    if (this.numPendingSlices > this.calculateMaxPendingSlices()) {
      return Promise.resolve();
    }

    const sliceDirectionFactor = this.currSliceIdx - this.prevSliceIdx >= 0 ? 1 : -1;
    this.prevSliceIdx = sliceIdxNum;

    let bufferHealth = 0;
    for (
      let i = sliceIdxNum;
      i >= 0 && i <= this.sliceView.numSlices - 1;
      i += sliceDirectionFactor
    ) {
      if (!this.sliceArr[i]) {
        break;
      }
      if (i === 0 || i === this.sliceView.numSlices - 1) {
        bufferHealth = Infinity;
        break;
      }
      bufferHealth += 1;
    }

    let numMissingSlices = 0;
    for (
      let i = sliceIdxNum + sliceDirectionFactor * bufferHealth;
      i >= 0 && i <= this.sliceView.numSlices - 1;
      i += sliceDirectionFactor
    ) {
      if (this.sliceArr[i]) {
        break;
      }
      numMissingSlices += 1;
    }

    const slicesToFetch = Math.min(numMissingSlices, this.calculateNumSlicesToFetch(bufferHealth));
    const bufferThreshold = this.calculateBufferThreshold();
    if (slicesToFetch > 0 && bufferHealth <= bufferThreshold) {
      const directionalStartIdx = Math.max(
        0,
        sliceDirectionFactor > 0 ? sliceIdxNum : sliceIdxNum - slicesToFetch + 1,
      );
      const bufferedStartIdx = directionalStartIdx + sliceDirectionFactor * bufferHealth;
      const clampedSlicesToFetch =
        bufferedStartIdx + slicesToFetch > this.sliceView.numSlices - 1
          ? this.sliceView.numSlices - bufferedStartIdx
          : slicesToFetch;

      const fetchStartTime = performance.now();
      await this.loadSlices(bufferedStartIdx, clampedSlicesToFetch, token);

      const fetchStopTime = performance.now();
      this.msPerSlice = Math.max(1, (fetchStopTime - fetchStartTime) / slicesToFetch);

      return this.drawSlice(this.currSliceIdx);
    }

    return Promise.resolve();
  }

  dispose() {
    this.sliceArr.forEach(slice => {
      if (slice instanceof Texture) {
        slice.dispose();
      }
    });

    this.imageMesh.material.dispose();
    this.imageMesh.geometry.dispose();
    this.renderer.dispose();
  }
}
