import { getSliceImageState } from '../../selectors';

export default function setSliceImageState(deltaZoom, canvasX, canvasY) {
  return (notify, getSceneState) => {
    const sceneState = getSceneState();
    const sliceImageState = getSliceImageState(sceneState);

    const cX = canvasX || sliceImageState.sCanvas.x;
    const cY = canvasY || sliceImageState.sCanvas.y;

    const zoom = Math.max(1, sliceImageState.zoom + deltaZoom);
    sceneState.sliceLoader.loader.setImageView(zoom, cX, cY);
    sliceImageState.zoom = zoom;

    notify();
  };
}
