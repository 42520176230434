import React, { useState, useCallback, useRef } from 'react';
import { SphereBufferGeometry, Mesh, MeshBasicMaterial, DoubleSide, BackSide } from 'three';
import { Branch } from '@visionair/centerline-3js';
import {
  useCanvasMouseEvent,
  useSceneDispatch,
  useSceneEffect,
  useSceneSetup,
} from '@visionair/scene-state-3js';

import visualizeCenterline from '../../../modules/scene/actions/airwayView/visualizeCenterline';
import { getAirwaySTL, isClick } from '../../../modules/scene/selectors';

import { computeCentroid } from '../../../utils/threeUtils';
import Instructions from './Instructions';
import addIconActive from '../../../images/centerline_tools/add_branch_active.svg';

const NEW_BRANCH_COLOR = 0xff0000;
const MOUSE_SPHERE_COLOR = 0xff0000;
const MIN_BRANCH_LENGTH = 0.5;

export default function AddBranchTool({ onFinish }) {
  const [newBranch, setNewBranch] = useState();
  const mouseSphereRef = useRef();
  const hasPlacedBranchRef = useRef(false);
  const dsAirwayRef = useRef();
  const pointIdx = useRef();

  const dispatch = useSceneDispatch();

  useSceneSetup(sceneState => {
    const mouseSphere = new Mesh(
      new SphereBufferGeometry(1, 32, 32),
      new MeshBasicMaterial({ color: MOUSE_SPHERE_COLOR }),
    );
    mouseSphere.name = 'mouse-sphere';
    mouseSphereRef.current = mouseSphere;

    sceneState.scene.add(mouseSphere);

    const airway = getAirwaySTL(sceneState);
    const mat = new MeshBasicMaterial({ side: DoubleSide });
    dsAirwayRef.current = new Mesh(airway.geometry, mat);

    return () => sceneState.scene.remove(mouseSphere);
  });

  useCanvasMouseEvent(
    'pointermove',
    useCallback(
      (evt, sceneState, raycaster) => {
        const { centerline } = sceneState;

        if (!newBranch) {
          if (!centerline.root) {
            const ints = raycaster.intersectObject(dsAirwayRef.current);
            if (ints.length < 2) {
              return;
            }

            const airwayCenter = computeCentroid([ints[0].point, ints[1].point]);
            mouseSphereRef.current.position.copy(airwayCenter);

            return;
          }

          const { point } = centerline.getClosestPointToRay(raycaster.ray);
          mouseSphereRef.current.position.copy(point);
        } else {
          const ints = raycaster.intersectObject(dsAirwayRef.current);
          if (ints.length < 2) {
            return;
          }

          const airwayCenter = computeCentroid([ints[0].point, ints[1].point]);

          if (newBranch === centerline.root && pointIdx.current === 0) {
            newBranch.points.splice(0, 1, airwayCenter);
          } else {
            newBranch.points.splice(newBranch.points.length - 1, 1, airwayCenter);
          }

          newBranch.visualize(sceneState.scene, NEW_BRANCH_COLOR);
        }
      },
      [newBranch],
    ),
  );

  useCanvasMouseEvent(
    'pointerup',
    useCallback(
      (evt, sceneState, raycaster) => {
        if (!isClick(sceneState, evt)) {
          return;
        }

        const { centerline } = sceneState;
        let inProgressBranch;

        if (!newBranch) {
          if (!centerline.root) {
            const ints = raycaster.intersectObject(dsAirwayRef.current);
            if (ints.length < 2) {
              return;
            }

            const airwayCenter = computeCentroid([ints[0].point, ints[1].point]);
            const airway = getAirwaySTL(sceneState);

            const nBranch = new Branch(
              new Mesh(airway.geometry, new MeshBasicMaterial({ side: BackSide })),
            );
            nBranch.setPoints([airwayCenter, airwayCenter]);

            centerline.root = nBranch;

            setNewBranch(nBranch);
            return;
          }

          // selecting branch point
          const { branch, index } = centerline.getClosestPointToRay(raycaster.ray);
          pointIdx.current = index;

          const { airway } = branch;
          if (index === 0) {
            if (branch === centerline.root) {
              inProgressBranch = centerline.root;
              branch.setPoints([branch.start.clone(), ...branch.points]);
              mouseSphereRef.current.visible = false;
            } else {
              // branch point is being added to start of branch
              inProgressBranch = new Branch(airway);
              inProgressBranch.setPoints([branch.start.clone(), branch.points[1].clone()]);
              inProgressBranch.setParent(branch.parent);
              branch.parent.addChild(inProgressBranch);
            }
          } else if (index <= branch.points.length - 2) {
            // branch point is being added to middle of branch, create new carina
            const nBranch = new Branch(airway);

            nBranch.setPoints(branch.points.splice(index));

            nBranch.setChildren([...branch.children]);
            nBranch.children.forEach(b => {
              b.setParent(nBranch);
            });
            nBranch.setParent(branch);

            branch.setChildren([nBranch]);
            branch.setPoints([...branch.points, nBranch.start.clone()]);

            inProgressBranch = new Branch(airway);
            inProgressBranch.setPoints([nBranch.start.clone(), nBranch.points[1].clone()]);
            inProgressBranch.setParent(branch);

            branch.addChild(inProgressBranch);
          } else {
            // branch point is being added to end of branch, add to carina
            inProgressBranch = new Branch(airway);
            inProgressBranch.setPoints([
              branch.end.clone(),
              branch.points[branch.points.length - 2].clone(),
            ]);
            inProgressBranch.setParent(branch);
            branch.addChild(inProgressBranch);

            // delete invisible children to avoid double branches when gen visibility is increased
            // copy array since deleteChild will modify children array
            branch
              .getChildren()
              .slice()
              .forEach(c => {
                if (!c.visible) {
                  branch.deleteChild(c);
                }
              });
          }

          setNewBranch(inProgressBranch);
        } else {
          // add new point to branch in progress
          const ints = raycaster.intersectObject(dsAirwayRef.current);
          if (ints.length < 2) {
            return;
          }

          const airwayCenter = computeCentroid([ints[0].point, ints[1].point]);

          if (
            pointIdx.current !== 0 &&
            newBranch.points[0].distanceTo(airwayCenter) < MIN_BRANCH_LENGTH
          ) {
            return;
          }

          if (newBranch === centerline.root && pointIdx.current === 0) {
            newBranch.points.splice(0, 1, airwayCenter);
          } else {
            newBranch.points.splice(newBranch.points.length - 1, 1, airwayCenter);
          }

          newBranch.samplePointsAtDist(1);
          if (newBranch.parent) {
            newBranch.parent.findCarinaRegionBorders();
          }
          newBranch.fitToCenter();

          newBranch.visualize(sceneState.scene, NEW_BRANCH_COLOR);

          // add dummy inprogress point in case user wants to add more points to this branch
          newBranch.points.push(newBranch.end.clone().add(newBranch.direction));

          // allow tool to be completed once at least one point is added
          if (!hasPlacedBranchRef.current) {
            onFinish();
            hasPlacedBranchRef.current = true;
          }
        }
      },
      [onFinish, newBranch],
    ),
  );

  useSceneEffect(
    ({ centerline }) => {
      if (!newBranch) {
        return;
      }

      // eslint-disable-next-line consistent-return
      return () => {
        // remove inprogress branch point
        if (newBranch === centerline.root && pointIdx.current === 0) {
          // root branch is being extended upwards
          newBranch.setPoints(newBranch.points.slice(1));
        } else {
          newBranch.setPoints(newBranch.points.slice(0, -1));
        }

        if (newBranch.points.length > 1 && newBranch.parent?.children.length === 2) {
          newBranch.parent.fixCarina();
          newBranch.parent.children.forEach(c => c.samplePointsAtDist(1));
        }

        dispatch(visualizeCenterline());
      };
    },
    [newBranch, dispatch],
  );

  return (
    <Instructions>
      <img src={addIconActive} alt='' />
      {newBranch
        ? 'Left-Click to place additional points drawing the path of the new branch.'
        : 'Left-Click on the line segment or carina point that you want to branch from.'}
    </Instructions>
  );
}
