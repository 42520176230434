import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { SCANSEG_STATUS } from '../../settings/segmentations';

import IconVerified from '../../images/statuses/icon-verified.svg';
import IconRejected from '../../images/statuses/icon-rejected.svg';

const ScanStatusIcon = ({ status }) => {
  let StatusIcon;
  switch (status) {
    case SCANSEG_STATUS.VERIFIED:
      StatusIcon = <StatusSVG src={IconVerified} alt='Verified' />;
      break;
    case SCANSEG_STATUS.REJECTED:
      StatusIcon = <StatusSVG src={IconRejected} alt='Rejected' />;
      break;
    default:
      StatusIcon = <StatusBubble status={status} />;
  }

  return <Container>{StatusIcon}</Container>;
};

ScanStatusIcon.propTypes = {
  status: PropTypes.oneOf(Object.values(SCANSEG_STATUS)).isRequired,
};

export default ScanStatusIcon;

const status2Color = status =>
  ({
    [SCANSEG_STATUS.PENDING]: 'background-image: linear-gradient(to bottom, #fad961, #f76b1c);',
    [SCANSEG_STATUS.SEGMENTING]: 'background-image: linear-gradient(to top, #07337f, #0093fd);',
    [SCANSEG_STATUS.ERROR]: 'background-image: linear-gradient(to bottom, #ff6c6b, #bd2323);',
    [SCANSEG_STATUS.NEEDS_REVIEW]: 'background-image: linear-gradient(to top, #224039, #0efba7);',
  }[status]);

const Container = styled.div`
  margin-right: 10px;
`;

const StatusSVG = styled.img``;

const StatusBubble = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 15px;

  ${({ status }) => status2Color(status)};
`;
