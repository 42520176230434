import { Vector2, Vector3 } from 'three';

import { AIRWAY_LABEL, AXIAL_PLANE_NAME, CORONAL_PLANE_NAME } from '../../settings/airway';
import * as centerline from '../../settings/centerline';
import { SEG_BB_NAME } from '../../settings/segmentations';
import { MOUSE_CLICK_DIST } from '../../settings/viewer';

export const AIRWAY_VIEWER_NAME = 'airway-viewer';
export const AXIAL_VIEWER_NAME = 'axial-viewer';
export const CORONAL_VIEWER_NAME = 'coronal-viewer';
export const CROSS_SECTION_VIEWER_NAME = 'cross-section-viewer';

const mouseCoords = new Vector2();

export const getScene = state => state.scene;
export const getCamera = state => state.camera;
export const getCanvas = state => state.canvas;
export const getControls = state => state.controls;
export const getMouse = state => state.mouse;
export const getSliceImageState = state => state.sliceImageState;
export const getSliceLoader = state => state.sliceLoader;
export const getSliceView = state => state.sliceView;
export const getIsHoveringOverCanvas = state => state.mouse.isHovering;

export const getAirwayMask = state => state.scene.getObjectByName('airway-mask');
export const getAirwaySTL = state => state.scene.getObjectByName(AIRWAY_LABEL);
export const getCenterlineGroup = state =>
  state.scene.getObjectByName(centerline.CENTERLINE_GROUP_NAME);
export const getCarinaGroup = state => state.scene.getObjectByName(centerline.CARINA_GROUP_NAME);

export const getSliceIdx = state => state.sliceIdx;

export const getSeedByID = (state, seedID) => state.seedPoints.find(({ id }) => id === seedID);
export const getSeedPoints = state => state.seedPoints;

export const get3DPos = (state, canvasX, canvasY) => {
  const sliceView = getSliceView(state);
  const sliceIdx = getSliceIdx(state);
  const camera = getCamera(state);

  const seed2DPos = new Vector3()
    .set(canvasX / camera.right - 1, canvasY / camera.bottom + 1, 0)
    .unproject(camera);
  const seed3DPos = new Vector3();

  const slicePlane = sliceView.getSlicePlane(sliceIdx);
  slicePlane.projectPoint(seed2DPos, seed3DPos);

  return seed3DPos;
};

export const getSeed3DPos = (state, seedID) => {
  const seed = getSeedByID(state, seedID);
  return get3DPos(state, seed.projPos.x, seed.projPos.y);
};
export const getIsPlacingSeed = state => state.isPlacingSeed;

export const getIsSliceLoaderInitialized = state => state.isSliceLoaderInitialized;

export const getAxialSlicePlane = state => state.scene.getObjectByName(AXIAL_PLANE_NAME);
export const getCoronalSlicePlane = state => state.scene.getObjectByName(CORONAL_PLANE_NAME);

export const getCenterlineNeedsUpdate = state => state?.centerlineChanges.needsUpdate;
export const getUndoEnabled = state => state.centerlineChanges.idx > 0;

export const getCarinaMesh = (state, branch) =>
  getCarinaGroup(state)?.getObjectByName(branch.carinaName);
export const getBranchMesh = (state, branch) =>
  getCenterlineGroup(state)?.getObjectByName(branch.branchName);

export const getSegBB = state => state.scene.getObjectByName(SEG_BB_NAME);

export const isClick = (state, evt) => {
  mouseCoords.set(evt.offsetX, evt.offsetY);
  return (
    state.mouse.isDown &&
    (!state.mouse.isDragging ||
      state.mouse.mouseDownCoords.distanceTo(mouseCoords) < MOUSE_CLICK_DIST)
  );
};
