import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';

import removeAirway from './removeAirway';

import { fullUrl, initialize } from '../../../../utils/requests';
import { getToken } from '../../../auth/selectors';

import {
  AIRWAY_MATERIAL_DEFAULTS,
  AIRWAY_LABEL,
  AIRWAY_FIT_ZOOM_FACTOR,
} from '../../../../settings/airway';
import { getZoomValueToFitObjectBB } from '../../../../utils/threeUtils';
import store from '../../../../store';

export default function loadAirwaySTL(airwayURL) {
  return (notify, getSceneState, callSceneAction) => {
    const token = getToken(store.getState().auth);
    const { scene, camera, controls, light } = getSceneState();

    callSceneAction(removeAirway());

    return fetch(fullUrl(airwayURL), initialize(token, 'GET'))
      .then(res => res.arrayBuffer())
      .then(arrayBuffer => {
        const bufGeo = STLLoader.prototype.parse(arrayBuffer);
        const material = new THREE.MeshLambertMaterial({ ...AIRWAY_MATERIAL_DEFAULTS });

        const airwayMesh = new THREE.Mesh(bufGeo, material);
        airwayMesh.name = AIRWAY_LABEL;

        scene.add(airwayMesh);

        const { zoom, center } = getZoomValueToFitObjectBB(airwayMesh, AIRWAY_FIT_ZOOM_FACTOR);

        // set camera position and zoom to sliceView plane of airway
        const cameraCenterOffset = 400;
        camera.position
          .copy(center)
          .add(new THREE.Vector3(0, -1, 0).multiplyScalar(cameraCenterOffset));

        camera.zoom = zoom;
        camera.updateProjectionMatrix();
        controls.target.copy(center);
        camera.up.copy(new THREE.Vector3(0, 0, 1));

        // always point light at airway
        if (!scene.children.includes(light.target)) {
          light.target = new THREE.Object3D();
          scene.add(light, light.target);
        }
        light.target.position.copy(center);

        notify();

        return airwayMesh;
      });
  };
}
