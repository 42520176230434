import { CENTERLINE_DEFAULT_COLOR, CARINA_DEFAULT_COLOR } from '../../../../settings/centerline';

export default function visualizeCenterline() {
  return (notify, getSceneState) => {
    const { centerline, scene } = getSceneState();

    centerline.visualize(scene, CENTERLINE_DEFAULT_COLOR);
    centerline.visualizeCarina(scene, CARINA_DEFAULT_COLOR);
  };
}
