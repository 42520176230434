import deleteSeed from './removeSeed';

import { getSegSeedPoints } from './selectors';

export default function removePendingSeeds(segID) {
  return (dispatch, getState) => {
    const seedPoints = getSegSeedPoints(getState().segmentations, segID)
      .toJS()
      .filter(seed => seed.pending);

    // waterfall seed delete requests to avoid race conditions
    return seedPoints.reduce((promiseChain, seed) => {
      return promiseChain.then(() => {
        return dispatch(deleteSeed(seed.id));
      });
    }, Promise.resolve());
  };
}
