import jwt from 'jwt-decode';

import * as types from '../actions/auth';
import setLogoutWarningTimeout from './setLogoutWarningTimeout';

import { apiFetch, HttpUnauthorizedError } from '../../utils/requests';
import { API_TOKEN_URL } from '../../settings/api';
import { USERTYPE_ADMINISTRATOR, USERTYPE_TECHNICIAN } from '../../settings/broncUsers';

export function authLoginAction(token) {
  return {
    type: types.AUTH_LOGIN,
    payload: {
      token,
    },
  };
}

export default function authLogin(email, password) {
  return dispatch => {
    const data = {
      email,
      password,
    };

    return apiFetch(API_TOKEN_URL, null, 'POST', data)
      .then(response => {
        const { usertype } = jwt(response.token);
        if (usertype !== USERTYPE_ADMINISTRATOR && usertype !== USERTYPE_TECHNICIAN) {
          throw new HttpUnauthorizedError();
        }

        localStorage.setItem('token', response.token);
        dispatch(authLoginAction(response.token));
        dispatch(setLogoutWarningTimeout(response.token));

        return response.token;
      })
      .catch(error => {
        dispatch(authLoginAction(null));
        throw error;
      });
  };
}
