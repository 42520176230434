import React, { useRef } from 'react';
import styled from 'styled-components';

import ContainerLoggedIn from '../ContainerLoggedIn';

import ScanSearch from '../scans-page/ScanSearch';
import ScansTable from '../scans-page/ScansTable';

export default function ScansPage() {
  const tableContainerRef = useRef();

  return (
    <ContainerLoggedIn>
      <SearchContainer>
        <ScanSearch />
      </SearchContainer>
      <ContentContainer ref={tableContainerRef}>
        <ScansTable containerRef={tableContainerRef} />
      </ContentContainer>
    </ContainerLoggedIn>
  );
}

const SearchContainer = styled.div`
  width: 100%;
  padding: 40px 0 40px 55px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;

const ContentContainer = styled(ContainerLoggedIn.Content)`
  background-color: #ffffff;

  padding-left: 0;
  padding-right: 0;
  @media (max-width: 1440px) {
    padding-left: 0;
    padding-right: 0;
  }
`;
