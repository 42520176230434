import { BoxGeometry, MeshBasicMaterial, Vector3, Mesh, Group } from 'three';

import removeSegBB from './removeSegBB';
import { SEG_BB_NAME, SEG_STATUS } from '../../../../settings/segmentations';
import { getSelectedSeg } from '../../../viewer/selectors';
import store from '../../../../store';

export default function addSegBB(bb) {
  return (notify, getSceneState, callSceneAction) => {
    const { scene } = getSceneState();
    const { viewer, segmentations } = store.getState();
    const segStatus = getSelectedSeg(viewer, segmentations).get('status');

    callSceneAction(removeSegBB());

    const center = new Vector3();
    const size = new Vector3();
    bb.getSize(size);
    bb.getCenter(center);

    const geo = new BoxGeometry(size.x, size.y, size.z, 1, 1, 1);

    const boxWireframe = new Mesh(
      geo,
      new MeshBasicMaterial({
        color: 0xffff00,
        wireframe: true,
      }),
    );
    const boxSolid = new Mesh(
      geo,
      new MeshBasicMaterial({ color: 0xffff00, transparent: true, opacity: 0.1 }),
    );

    const box = new Group();
    box.add(boxWireframe, boxSolid);
    box.position.copy(center);
    box.renderOrder = 1;
    box.name = SEG_BB_NAME;
    box.visible = segStatus === SEG_STATUS.CREATED;

    scene.add(box);

    notify();
  };
}
