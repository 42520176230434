import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import ButtonDefault from '../../buttons/ButtonDefault';
import ScanRejectModal from '../../modals/ScanRejectModal';

import { getShowRejectModal } from '../../../modules/viewer/selectors';
import setShowRejectModal from '../../../modules/viewer/setShowRejectModal';

import { SCAN_STATUS } from '../../../settings/segmentations';

export default function RejectScanBtn({ scanStatus }) {
  const dispatch = useDispatch();

  const show = useSelector(({ viewer }) => getShowRejectModal(viewer));

  if (scanStatus === SCAN_STATUS.VERIFIED || scanStatus === SCAN_STATUS.REJECTED) {
    return null;
  }

  return (
    <>
      <RejectBtn onClick={() => dispatch(setShowRejectModal(true))}>Reject Scan</RejectBtn>
      <ScanRejectModal show={show} onClose={() => dispatch(setShowRejectModal(false))} />
    </>
  );
}

RejectScanBtn.propTypes = {
  scanStatus: PropTypes.string.isRequired,
};

const RejectBtn = styled(ButtonDefault)`
  width: 160px;
  height: 100%;
  background-image: linear-gradient(to top, #4a0000, #930202);

  :hover {
    background-image: none;
    border: solid 1px #8f0000;
    background-color: #930202;
  }

  :active {
    background-image: none;
    border: solid 1px #930202;
    background-color: #4b0000;
  }
`;
