export default {
  black: '#000000',
  blue30: '#387af3',
  blue40: '#5b7ae4',
  blue50: '#5c6bc0',
  blue60: '#3f51b5',
  blue80: '#303f9f',
  cyan10: '#e5f6fd',
  cyan60: '#05aeef',
  green70: '#26a65b',
  green90: '#128243',
  grey30: '#e5e5e5',
  grey40: '#d4d4d4',
  grey50: '#b9b9b9',
  grey60: '#9d9d9d',
  grey70: '#666666',
  grey80: '#545353',
  grey90: '#4e4e4e',
  purple50: '#854cc5',
  purple60: '#6f29ba',
  purple70: '#6624b4',
  purple90: '#550c94',
  red70: '#db3632',
  red100: '#ad1a1a',
  yellow30: '#ffdc7c',
  yellow40: '#ffcf45',
  yellow60: '#feb900',
  yellow70: '#fe9800',
  yellow90: '#fe8600',
  white: '#ffffff',

  colorHeaderGradientPrimary: '#fafafa',
  colorHeaderGradientSecondary: '#f3f3f3',
  colorOverlayGradientTop: '#3f414f',
  colorShadowActiveDark: 'rgba(0, 0, 0, 0.25)',
  colorShadowActiveMedium: 'rgba(0, 0, 0, 0.18)',
  colorShadowActiveLight: 'rgba(0, 0, 0, 0.15)',
  colorShadowHoverDark: 'rgba(0, 0, 0, 0.2)',
  colorShadowHoverMedium: 'rgba(0, 0, 0, 0.14)',
  colorShadowHoverLight: 'rgba(0, 0, 0, 0.12)',
  colorViewerGradientBottom: '#eeeeee',
  colorViewerGradientTop: '#f2f2f2',

  gradientTop: '#232323',
  gradientBottom: '#000000',
  gradientMiddle: '#222222',
  reviewGreen50: '#178c51',
  segmentingGradientRight: 'rgba(39, 39, 39, 0.28)',
  segmentingGradientLeft: '#396bc3',
  lightGrey: '#3d3d3d',
  rowGrey: '#2b2b2b',
  header: '#1d1d1d',

  colorSeedPending: '#d7b22d',
  colorSeedCompleted: '#ff54e1',
};
