import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Form from 'react-bootstrap/Form';

import useSelectorToJS from '../../hooks/useSelectorToJS';
import { getScansStatusFilter } from '../../modules/scansTable/selectors';
import setScansStatusFilters from '../../modules/scansTable/setScansStatusFilters';

import { SCANSEG_STATUS } from '../../settings/segmentations';

const ScanStatusFilter = ({ status }) => {
  const dispatch = useDispatch();

  const { isFilterActive } = useSelectorToJS(({ scansTable }) => ({
    isFilterActive: getScansStatusFilter(scansTable, status),
  }));

  const handleChange = () => {
    dispatch(setScansStatusFilters({ [status]: !isFilterActive }));
  };

  const statusLabel = {
    [SCANSEG_STATUS.PENDING]: 'Pending',
    [SCANSEG_STATUS.SEGMENTING]: 'Segmenting',
    [SCANSEG_STATUS.ERROR]: 'Error',
    [SCANSEG_STATUS.NEEDS_REVIEW]: 'Needs Review',
    [SCANSEG_STATUS.REJECTED]: 'Rejected',
    [SCANSEG_STATUS.VERIFIED]: 'Verified',
  }[status];

  return (
    <Container status={status}>
      <StatusCheckbox
        type='checkbox'
        checked={isFilterActive}
        onChange={handleChange}
        label={statusLabel}
        status={status}
        id={`status-checkbox-${status}`}
        custom
        inline
      />
    </Container>
  );
};

ScanStatusFilter.propTypes = {
  status: PropTypes.oneOf(Object.values(SCANSEG_STATUS)).isRequired,
};

export default ScanStatusFilter;

const status2BgColor = status =>
  ({
    [SCANSEG_STATUS.PENDING]: 'background: #faba4e;',
    [SCANSEG_STATUS.SEGMENTING]: 'background: #027cdf',
    [SCANSEG_STATUS.ERROR]: 'background: #ff6c6b;',
    [SCANSEG_STATUS.NEEDS_REVIEW]: 'background: #11b178;',
    [SCANSEG_STATUS.REJECTED]: `border: none; background-color: transparent;`,
    [SCANSEG_STATUS.VERIFIED]: `border: none; background-color: transparent;`,
  }[status]);

const status2BgImage = status => {
  switch (status) {
    case SCANSEG_STATUS.VERIFIED:
      return `background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E %3Cg fill='none' fill-rule='evenodd'%3E %3Crect width='11' height='11' x='.5' y='.5' fill-rule='nonzero' stroke='%230EFBA7' rx='1'/%3E %3Cpath fill='%230EFBA7' d='M3.385 3.446L3.446 6.388 10.19 6.597 10.19 8.32 1.723 8.111 1.662 3.446z' transform='rotate(-45 5.926 5.883)'/%3E %3C/g%3E %3C/svg%3E")`;
    case SCANSEG_STATUS.REJECTED:
      return `background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E %3Cg fill='none' fill-rule='evenodd' stroke='%23FF6060'%3E %3Crect width='11' height='11' x='.5' y='.5' fill-rule='nonzero' rx='1'/%3E %3Cpath fill='%23FF6060' d='M8.901 2.571l.528.528-2.902 2.9L9.43 8.901 8.9 9.43 6 6.527 3.1 9.43 2.57 8.9 5.472 6l-2.9-2.9.527-.528 2.9 2.901 2.902-2.9z'/%3E %3C/g%3E %3C/svg%3E")`;
    default:
      return 'background-image: none';
  }
};

const Container = styled.div`
  user-select: none;
`;

const StatusCheckbox = styled(Form.Check)`
  height: 30px;
  margin-right: 20px;

  font-size: 13px;

  align-items: center;

  label {
    cursor: pointer;
  }

  /* UNCHECKED */
  .custom-control-label {
    color: #b9b9b9;

    ::before {
      top: 2px;
      border-color: #fafafa;
      background-color: ${props => props.theme.colors.lightGrey};
    }

    ::after {
      top: 2px;
      background-size: 100%;
    }
  }

  /* CHECKED */
  .custom-control-input:checked ~ .custom-control-label {
    color: #ffffff;

    ::before {
      border-color: #fafafa;

      ${({ status }) => status2BgColor(status)};
    }

    ::after {
      ${({ status }) => status2BgImage(status)};
    }
  }

  /* remove blue border */
  .custom-control-input:focus ~ .custom-control-label::before {
    border-color: #fafafa !important;
    box-shadow: none !important;
  }
`;
