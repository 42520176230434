import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSceneSelector } from '@visionair/scene-state-3js';

import SegStatusBar from './SegStatusBar';
import SegHistoryDropdown from './SegHistoryDropdown';
import DownloadScanBtn from './DownloadScanBtn';
import OverwriteScanBtn from './OverwriteScanBtn';
import RejectScanBtn from './RejectScanBtn';
import RunSegBtn from './RunSegBtn';
import VerifySegBtn from './VerifySegBtn';

import Modal from '../../modals/ModalStyled';
import ButtonDefault from '../../buttons/ButtonDefault';

import { SCAN_STATUS, SEG_STATUS } from '../../../settings/segmentations';

import goBack from '../../../images/go-back.svg';
import logo from '../../../images/logo-teal.svg';
import { AIRWAY_VIEWER_NAME, getCenterlineNeedsUpdate } from '../../../modules/scene/selectors';

export default function ViewerHeader({ seg, scan }) {
  const history = useHistory();
  const [showSaveModal, setShowSaveModal] = useState(false);

  const centerlineNeedsUpdate = useSceneSelector(
    state => getCenterlineNeedsUpdate(state),
    AIRWAY_VIEWER_NAME,
  );

  const hasPendingSeeds = seg.seedPoints.filter(seed => seed.pending).length > 0;

  const showRunSegBtn =
    hasPendingSeeds ||
    seg.status === SEG_STATUS.CREATED ||
    seg.status === SEG_STATUS.RUNNING ||
    seg.status === SEG_STATUS.ERROR;

  const handleBackClick = () => {
    if (centerlineNeedsUpdate) {
      setShowSaveModal(true);
    } else {
      history.push('/scans');
    }
  };

  return (
    <>
      <Container>
        <FixedWidth>
          <BackBtn onClick={handleBackClick}>
            <img src={goBack} alt='Go Back' />
          </BackBtn>
          <Logo src={logo} alt='VisionAir Solutions' />
        </FixedWidth>

        <DynamicWidth>
          <SegStatusBar seg={seg} scan={scan} />
          <SegHistoryDropdown scan={scan} />
        </DynamicWidth>

        <ButtonContainer>
          <DownloadScanBtn seg={seg} scan={scan} />

          {scan.status !== SCAN_STATUS.VERIFIED && scan.status !== SCAN_STATUS.REJECTED && (
            <OverwriteScanBtn scan={scan} />
          )}

          <RejectScanBtn scanStatus={scan.status} />

          {showRunSegBtn && <RunSegBtn scanID={scan.id} seg={seg} scanStatus={scan.status} />}

          {!showRunSegBtn && <VerifySegBtn seg={seg} scanStatus={scan.status} />}
        </ButtonContainer>
      </Container>

      <Modal
        show={showSaveModal}
        centered
        backdropClassName='modal-backdrop-blur'
        data-test='unsaved-changes-warning'
      >
        <Modal.Header>Unsaved Changes</Modal.Header>
        <Modal.Body>
          You have unsaved changes made to the centerline. Are you sure you want to leave?
        </Modal.Body>
        <Modal.Footer>
          <ConfirmBtn
            onClick={() => {
              setShowSaveModal(false);
              history.push('/scans');
            }}
          >
            Go to scans page
          </ConfirmBtn>
          <CancelBtn onClick={() => setShowSaveModal(false)}>Back to Viewer</CancelBtn>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ViewerHeader.propTypes = {
  seg: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    seedPoints: PropTypes.array,
  }).isRequired,
  scan: PropTypes.shape({
    id: PropTypes.string,
    scanSegStatus: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

const Container = styled.div`
  height: 70px;
  min-width: 1280px;
  padding: 12px 25px;

  background-color: #141414;

  display: flex;
  justify-content: flex-start;
`;

const FixedWidth = styled.div`
  display: flex;
  align-items: center;
`;

const DynamicWidth = styled.div`
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-start;
  padding: 0 25px;
`;

const BackBtn = styled.button`
  background: transparent;
  border: none;
  :focus {
    outline: none;
  }
`;

const Logo = styled.img`
  margin-left: 15px;
  width: 45px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 25px;
`;

const CancelBtn = styled(ButtonDefault)`
  width: 190px;
  background-color: #969696;
  margin-right: 20px !important;
`;

const ConfirmBtn = styled(ButtonDefault)`
  width: 190px;
  background-color: #aa0014;
`;
