import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { OverlayTrigger } from 'react-bootstrap';
import { useSceneDispatch } from '@visionair/scene-state-3js';
import { format, parse } from 'date-fns';
import { MdWarningAmber } from 'react-icons/md';

import Tooltip from '../../Tooltip.styled';

import useScanSegs from '../../../hooks/useScanSegs';
import useSelectorToJS from '../../../hooks/useSelectorToJS';
import { isAnonymized, matchesConfirmedPatientInfo } from '../../../utils/dicom/dicom';

import Modal from '../../modals/ModalStyled';
import ButtonDefault from '../../buttons/ButtonDefault';

import verifySegmentation from '../../../modules/scene/actions/airwayView/verifySegmentation';
import { showNotificationError } from '../../../modules/notifications/showNotification';
import { SEG_STATUS, SCAN_STATUS } from '../../../settings/segmentations';
import { getScanByID } from '../../../modules/scans/selectors';
import checkCenterlineBounds from '../../../modules/scene/actions/airwayView/checkCenterlineBounds';
import { AIRWAY_VIEWER_NAME } from '../../../modules/scene/selectors';
import setShowCenterlineBoundsWarning from '../../../modules/viewer/setShowCenterlineBoundsWarning';
import { getSelectedVol } from '../../../modules/viewer/selectors';
import { getVolsByScanID } from '../../../modules/volume3Ds/selectors';

export default function VerifySegBtn({ seg, scanStatus }) {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sceneDispatch = useSceneDispatch();
  const reduxDispatch = useDispatch();
  const scanSegs = useScanSegs();
  const history = useHistory();

  const { scanID } = useParams();

  const { selectedScan, selectedVol, scanVols } = useSelectorToJS(
    ({ scans, viewer, volume3Ds }) => ({
      selectedScan: getScanByID(scans, scanID),
      selectedVol: getSelectedVol(viewer, volume3Ds),
      scanVols: getVolsByScanID(volume3Ds, scanID),
    }),
  );

  const handleBtnClick = () => {
    setShowModal(true);
  };

  const verify = useCallback(() => {
    const isEnclosedInAirway = sceneDispatch(checkCenterlineBounds(), AIRWAY_VIEWER_NAME);
    if (!isEnclosedInAirway) {
      reduxDispatch(setShowCenterlineBoundsWarning(true));
      setShowModal(false);
      return;
    }

    setIsSubmitting(true);

    sceneDispatch(verifySegmentation(), AIRWAY_VIEWER_NAME)
      .then(() => {
        setIsSubmitting(false);
        setShowModal(false);
        history.push('/scans');
      })
      .catch(err => {
        setIsSubmitting(false);
        setShowModal(false);
        reduxDispatch(
          showNotificationError('An unexpected error occurred while verifying segmentation.'),
        );
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, [reduxDispatch, sceneDispatch, history]);

  if (scanStatus === SCAN_STATUS.VERIFIED || scanStatus === SCAN_STATUS.REJECTED) {
    return null;
  }

  const patientInfoTooltipContents = getPatientInfoTooltipContents(
    scanVols,
    selectedVol,
    selectedScan,
  );

  const isDisabled = seg.status !== SEG_STATUS.SUCCESS || patientInfoTooltipContents;

  const runningSegs = scanSegs.filter(s => s.status === SEG_STATUS.RUNNING);

  const verifyButton = isDisabled ? (
    <OverlayTrigger
      placement='bottom'
      overlay={<S.Tooltip id='tooltip-verifyBtn-message'>{patientInfoTooltipContents}</S.Tooltip>}
    >
      <span>
        <S.Button disabled={isDisabled} onClick={handleBtnClick}>
          Verify Segmentation
        </S.Button>
      </span>
    </OverlayTrigger>
  ) : (
    <S.Button disabled={isDisabled} onClick={handleBtnClick}>
      Verify Segmentation
    </S.Button>
  );

  return (
    <>
      {verifyButton}

      <ConfirmModal
        show={showModal}
        scanVols={scanVols}
        selectedVol={selectedVol}
        selectedScan={selectedScan}
        runningSegs={runningSegs}
        onCancel={() => setShowModal(false)}
        onVerify={verify}
        isSubmitting={isSubmitting}
      />
    </>
  );
}

function ConfirmModal({
  show,
  scanVols,
  selectedVol,
  selectedScan,
  runningSegs,
  onCancel,
  onVerify,
  isSubmitting,
}) {
  const newerVols = getNewerVols(selectedVol, scanVols);

  return (
    <Modal show={show} centered backdropClassName='modal-backdrop-blur'>
      <Modal.Header>Verify Segmentation?</Modal.Header>

      <Modal.Body>
        {newerVols.length > 0 && (
          <S.ModalWarning>
            <S.WarningHeading style={{ color: '#ff0000' }}>
              <MdWarningAmber color='#ff0000' size={24} />
              The selected CT series has older acquisition date than other series
            </S.WarningHeading>
            <ul>
              <li>
                Series {selectedVol.seriesNumber} -{' '}
                {selectedVol.scanDate
                  ? format(parse(selectedVol.scanDate, 'yyyyMMdd', new Date()), 'yyyy-MM-dd')
                  : 'Unknown'}{' '}
                (selected)
              </li>

              {newerVols.map(v => (
                <li key={v.id}>
                  Series {v.seriesNumber} -{' '}
                  {v.scanDate
                    ? format(parse(v.scanDate, 'yyyyMMdd', new Date()), 'yyyy-MM-dd')
                    : 'Unknown'}
                </li>
              ))}
            </ul>

            <div>
              Date Confirmed by Doctor:{' '}
              {format(parse(selectedScan.ct_scan_date, 'yyyyMMdd', new Date()), 'yyyy-MM-dd')}
            </div>
          </S.ModalWarning>
        )}

        {runningSegs.length > 0 && (
          <S.ModalWarning>
            <S.WarningHeading>
              <MdWarningAmber color='#ff9800' size={24} />
              This scan has running segmentations:
            </S.WarningHeading>
            <ul>
              {runningSegs.map(s => (
                <li key={s.id}>{s.segAlphaNum}</li>
              ))}
            </ul>
          </S.ModalWarning>
        )}
      </Modal.Body>

      <Modal.Footer>
        <S.CancelBtn disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </S.CancelBtn>
        <S.ConfirmBtn disabled={isSubmitting} onClick={onVerify}>
          {newerVols.length > 0 || runningSegs.length > 0 ? 'Verify Anyway' : 'Verify'}
        </S.ConfirmBtn>
      </Modal.Footer>
    </Modal>
  );
}

function getNewerVols(selectedVol, scanVols) {
  const newerVols = [];

  for (const v of scanVols) {
    if (v.scanDate === selectedVol.scanDate) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (!v.scanDate) {
      newerVols.push(v);
      // eslint-disable-next-line no-continue
      continue;
    }

    if (v.scanDate && !selectedVol.scanDate) {
      newerVols.push(v);
      // eslint-disable-next-line no-continue
      continue;
    }

    const volDate = parse(v.scanDate, 'yyyyMMdd', new Date());
    const selectedVolDate = parse(selectedVol.scanDate, 'yyyyMMdd', new Date());

    if (volDate > selectedVolDate) {
      newerVols.push(v);
    }
  }

  return newerVols;
}

VerifySegBtn.propTypes = {
  seg: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  scanStatus: PropTypes.string.isRequired,
};

function getPatientInfoTooltipContents(scanVols, selectedVol, selectedScan) {
  if (!isAnonymized(selectedVol) && !matchesConfirmedPatientInfo(selectedVol, selectedScan)) {
    return 'The selected series has unconfirmed patient info';
  }

  const seriesExistsWithConfirmedInfo = scanVols.some(
    v => matchesConfirmedPatientInfo(v, selectedScan),
    selectedScan,
  );
  if (isAnonymized(selectedVol) && seriesExistsWithConfirmedInfo) {
    return 'A non-selected series has confirmed patient info';
  }

  const seriesExistsWithUnconfirmedInfo = scanVols.some(
    v => !matchesConfirmedPatientInfo(v, selectedScan) && !isAnonymized(v),
    selectedScan,
  );
  if (isAnonymized(selectedVol) && seriesExistsWithUnconfirmedInfo) {
    return 'The selected series is anonymized & a non-selected series has unconfirmed patient info';
  }

  return null;
}

const S = {};

S.Tooltip = styled(Tooltip)`
  .tooltip-inner {
    padding: 8px;
    max-width: 225px;
  }
`;

S.Button = styled(ButtonDefault)`
  width: 215px;
  height: 100%;
  background-image: linear-gradient(to top, #024334, #09a080);

  :hover {
    background-image: none;
    background-color: #09a080;
  }

  :active {
    background-image: none;
    border: solid 1px #09a080;
    background-color: #024536;
  }

  :disabled {
    background-image: none;
    background-color: #14715d;
    opacity: 0.5;
  }
`;

S.ModalWarning = styled.div``;

S.WarningHeading = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 10px;

  font-size: 16px;

  svg {
    margin-right: 15px;
  }
`;

S.CancelBtn = styled(ButtonDefault)`
  width: 190px;
  background-color: #969696;
  margin-right: 20px !important;
`;

S.ConfirmBtn = styled(ButtonDefault)`
  width: 190px;
  background-color: #09a080;
`;
