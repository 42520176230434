import React from 'react';
import styled from 'styled-components';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function ToolBtn({ tooltip, children, ...props }) {
  const tooltipComp = <TT>{tooltip}</TT>;

  return (
    <OverlayTrigger placement='left' overlay={tooltipComp}>
      <Btn {...props}>{children}</Btn>
    </OverlayTrigger>
  );
}

const Btn = styled.button`
  border: none;
  background: none;
  color: white;
  height: 42px;
  width: 42px;
  border-radius: 6px;
  ${props => props.active && 'border: solid 1px #6cff00;'}

  && {
    outline: none;
  }
`;

const TT = styled(Tooltip)`
  margin-right: 6px;
  font-size: 12px;
  pointer-events: none;
`;
