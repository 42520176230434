import * as THREE from 'three';

export default class Volume3DUtil {
  constructor(volume3D) {
    Object.entries(volume3D).forEach(([key, value]) => {
      this[key] = value;
    });
  }

  get boundingBox() {
    if (!(this.bb instanceof THREE.Box3)) {
      const bbMin = new THREE.Vector3(this.bbMin.X, this.bbMin.Y, this.bbMin.Z);
      const bbMax = new THREE.Vector3(this.bbMax.X, this.bbMax.Y, this.bbMax.Z);
      this.bb = new THREE.Box3(bbMin, bbMax);

      return this.bb;
    }

    return this.bb;
  }

  get size() {
    if (!(this.boundingSize instanceof THREE.Vector3)) {
      this.boundingSize = new THREE.Vector3();
      this.boundingBox.getSize(this.boundingSize);
    }

    return this.boundingSize;
  }

  get center() {
    if (!(this.boundingCenter instanceof THREE.Vector3)) {
      this.boundingCenter = new THREE.Vector3();
      this.boundingBox.getCenter(this.boundingCenter);
    }

    return this.boundingCenter;
  }
}
