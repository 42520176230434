import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    ${props => props.theme.text.bodyDefault}
    height: 100%;
    min-width: 1024px;
  }

  #app {
    height: 100vh;
  }

  .modal-backdrop-blur.show {
    background-color: #080808;
    opacity: 0.65;
    backdrop-filter: blur(10px);
  }

  .modal-backdrop-fit-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
  }

  .backdrop-viewer.show {
    opacity: 0.8;
  }
`;

export default GlobalStyle;
