export const VIEWER_SET_SCAN_ID = 'VIEWER_SET_SCAN_ID';
export const VIEWER_SET_SEG_ID = 'VIEWER_SET_SEG_ID';
export const VIEWER_SET_VOL_ID = 'VIEWER_SET_VOL_ID';
export const VIEWER_SET_IS_PLACING_SEED = 'VIEWER_SET_IS_PLACING_SEED';
export const VIEWER_SET_EDIT_SEED_ID = 'VIEWER_SET_EDIT_SEED_ID';
export const VIEWER_RESET = 'VIEWER_RESET';
export const VIEWER_SET_IS_LOADING_AIRWAY = 'VIEWER_SET_IS_LOADING_AIRWAY';
export const VIEWER_SET_IS_LOADING_CENTERLINE = 'VIEWER_SET_IS_LOADING_CENTERLINE';
export const VIEWER_SET_IS_CROSS_SECTION_VISIBLE = 'VIEWER_SET_IS_CROSS_SECTION_VISIBLE';
export const VIEWER_IS_SELECTING_CENTERLINE_START = 'VIEWER_IS_SELECTING_CENTERLINE_START';
export const VIEWER_SET_SHOW_SLICE_VIEWS = 'VIEWER_SET_SHOW_SLICE_VIEWS';
export const VIEWER_SET_SHOW_SEED_LIST = 'VIEWER_SET_SHOW_SEED_LIST';
export const VIEWER_SET_SHOW_SCAN_INFO_TABLE = 'VIEWER_SET_SHOW_SCAN_INFO_TABLE';
export const VIEWER_SET_SHOW_CENTERLINE_BOUNDS_WARNING =
  'VIEWER_SET_SHOW_CENTERLINE_BOUNDS_WARNING';
export const VIEWER_SET_SHOW_CT_WINDOWING = 'VIEWER_SET_SHOW_CT_WINDOWING';
export const VIEWER_SET_SHOW_REJECT_MODAL = 'VIEWER_SET_SHOW_REJECT_MODAL';
export const VIEWER_SET_SHOW_OVERWRITE_SCAN_MODAL = 'VIEWER_SET_SHOW_OVERWRITE_SCAN_MODAL';
export const VIEWER_SET_IS_AXIAL_VIEW_LOADED = 'VIEWER_SET_IS_AXIAL_VIEW_LOADED';
export const VIEWER_SET_IS_CORONAL_VIEW_LOADED = 'VIEWER_SET_IS_CORONAL_VIEW_LOADED';
