import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  getIsPlacingSeed,
  getEditSeedID,
  getSelectedSeg,
  getShowSliceViews,
} from '../../../modules/viewer/selectors';
import setIsPlacingSeed from '../../../modules/viewer/setIsPlacingSeed';
import setEditSeedID from '../../../modules/viewer/setEditSeedID';
import { SCAN_STATUS, SEG_STATUS } from '../../../settings/segmentations';

import addSeedIcon from '../../../images/add-seed.svg';
import addSeedIconActive from '../../../images/add-seed-active.svg';
import { getScanByID } from '../../../modules/scans/selectors';
import useSelectorToJS from '../../../hooks/useSelectorToJS';
import setShowSliceViews from '../../../modules/viewer/setShowSliceViews';

export default function AddSeedBtn({ className }) {
  const { scanID } = useParams();
  const { isPlacingSeed, editSeedID, scan, seg, showSliceViews } = useSelectorToJS(
    ({ viewer, scans, segmentations }) => ({
      isPlacingSeed: getIsPlacingSeed(viewer),
      editSeedID: getEditSeedID(viewer),
      scan: getScanByID(scans, scanID),
      seg: getSelectedSeg(viewer, segmentations),
      showSliceViews: getShowSliceViews(viewer),
    }),
  );
  const dispatch = useDispatch();

  const handleClick = () => {
    if (editSeedID) {
      dispatch(setEditSeedID(null)); // updates isPlacingSeed
    } else {
      dispatch(setIsPlacingSeed(!isPlacingSeed));
    }

    if (!showSliceViews) {
      dispatch(setShowSliceViews(true));
    }
  };

  const isDisabled = scan.status !== SCAN_STATUS.UPLOADED || seg.status === SEG_STATUS.RUNNING;

  return (
    <Button
      disabled={isDisabled}
      className={className}
      onClick={handleClick}
      data-test='add-seed-btn'
    >
      <img
        src={isPlacingSeed ? addSeedIconActive : addSeedIcon}
        alt='Add Seed'
        style={isDisabled ? { opacity: '0.4' } : {}}
      />
    </Button>
  );
}

const Button = styled.button`
  background: none;
  border: none;
  height: 60px;
  width: 60px;

  && {
    outline: none;
  }
`;
