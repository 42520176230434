import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import HomeBackgroundImage from '../images/home-bg-black.jpg';
import VASLogo from '../images/home-logo.svg';

const ContainerHome = ({ children, className }) => (
  <BackgroundContainer className={className}>
    <Background />
    <PageContainer>
      <ImageContainer>
        <Logo src={VASLogo} alt='VisionAir Solutions Airway Segmentation' />
      </ImageContainer>
      <Divider />
      {children}
    </PageContainer>
  </BackgroundContainer>
);

ContainerHome.defaultProps = {
  className: '',
};

ContainerHome.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
  className: PropTypes.string,
};

export default ContainerHome;

const BackgroundContainer = styled.div`
  height: 100%;
  min-height: 825px;
  min-width: 1024px;
  width: 100%;
  overflow: hidden;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const Background = styled.div`
  height: 100%;
  position: absolute;
  display: flex;
  width: 100%;

  background: url(${HomeBackgroundImage}) no-repeat center center fixed;
  background-size: cover;
`;

const PageContainer = styled.div`
  margin: auto;
  padding: 0 15px 5vh 15px;

  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 1024px;
  height: 100%;
  min-height: 100vh;

  position: relative;
  z-index: 2;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -95px;
`;

const Logo = styled.img`
  width: 400px;
`;

const Divider = styled.div`
  height: 500px;
  width: 1px;
  margin: 0 50px;
  margin-top: 50px;
  border-right: 1px solid ${props => props.theme.colors.white};
`;
