import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import BaseComponent from './BaseComponent';
import authRefresh from '../modules/auth/authRefresh';
import { LOGOUT_WARNING_INTERVAL_MS } from '../settings/broncUsers';

export class LogoutWarningComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.countdownInterval = null;
  }

  componentDidMount() {
    this.startCountdown();
  }

  componentWillUnmount() {
    this.resetCountdown();
  }

  startCountdown() {
    this.resetCountdown();
    this.setState({ countdown: LOGOUT_WARNING_INTERVAL_MS / 1000 });
    this.countdownInterval = window.setInterval(this.tickCountdown, 1000);
  }

  tickCountdown() {
    this.setState(
      prevState => ({ countdown: prevState.countdown - 1 }),
      () => {
        if (this.state.countdown <= 0) {
          this.props.history.push('/logout');
          this.resetCountdown();
        }
      },
    );
  }

  resetCountdown() {
    clearInterval(this.countdownInterval);
    this.countdownInterval = null;
  }

  handleContinue() {
    this.props.authRefresh();
  }

  render() {
    const countdownSentence = `Logging out in ${this.state.countdown} seconds...`;

    return (
      <Modal id='modal-logout-warning' show={this.props.show} backdrop='static' centered>
        <ModalHeader>Inactivity Warning</ModalHeader>
        <ModalBody>
          You are about to be logged out due to inactivity.
          <br />
          <br />
          {countdownSentence}
        </ModalBody>
        <ModalFooter>
          Click continue to extend session.
          <Button onClick={this.handleContinue} variant='primary'>
            Continue
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

LogoutWarningComponent.displayName = 'LogoutWarning'; // fixes jest snapshot tests

LogoutWarningComponent.propTypes = {
  authRefresh: PropTypes.func.isRequired,
};

export default withRouter(
  connect(null, {
    authRefresh,
  })(LogoutWarningComponent),
);

const ModalHeader = styled(Modal.Header)`
  font-size: 20px;
`;

const ModalBody = styled(Modal.Body)`
  font-size: 16px;
`;

const ModalFooter = styled(Modal.Footer)`
  justify-content: space-between;
`;
