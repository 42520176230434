import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { saveAs } from 'file-saver';
import { useDispatch } from 'react-redux';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

import { OverlayTrigger } from 'react-bootstrap';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Tooltip from '../../Tooltip.styled';

import { showNotificationError } from '../../../modules/notifications/showNotification';
import downloadIcon from '../../../images/download.svg';

import { fullUrl, initialize, downloadScanZip } from '../../../utils/requests';
import { getAirwayURL } from '../../../settings/api';
import { SEG_STATUS } from '../../../settings/segmentations';

export default function DownloadScanBtn({ seg, scan }) {
  const reduxDispatch = useDispatch();

  const showIconButton = useMediaQuery('(max-width:1400px)');

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { id: scanID, dicom: fileName, scanNum } = scan;

  const isDownloadAirwayEnabled =
    seg.status === SEG_STATUS.SUCCESS || seg.status === SEG_STATUS.VERIFIED;

  const onDownloadScan = useCallback(async () => {
    try {
      const { buffer } = await downloadScanZip(scanID, fileName, localStorage.getItem('token'));

      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      saveAs(blob, `DICOM_${scanNum}.zip`);
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      reduxDispatch(showNotificationError('There was a problem downloading CT Scan'));
    }
  }, [reduxDispatch, scanNum, scanID, fileName]);

  const onDownloadAirway = useCallback(async () => {
    try {
      const res = await fetch(
        fullUrl(getAirwayURL(seg.id)),
        initialize(localStorage.getItem('token'), 'GET'),
      );
      if (!res.ok) {
        throw new Error('airway.stl not found');
      }

      const buf = await res.arrayBuffer();

      const blob = new Blob([buf], { type: 'application/octet-stream' });
      saveAs(blob, `airway_${scanNum}_${seg.id}.stl`);
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      reduxDispatch(showNotificationError('There was a problem downloading Airway STL'));
    }
  }, [reduxDispatch, scanNum, seg]);

  return (
    <S.DownloadDropdown>
      {!showIconButton && (
        <S.MenuButton
          onClick={evt => setAnchorEl(evt.currentTarget)}
          aria-controls='viewerPage-downloadMenu'
          aria-haspopup='true'
          startIcon={<img src={downloadIcon} alt='dl-icon' />}
          endIcon={anchorEl ? <MdArrowDropUp /> : <MdArrowDropDown />}
        >
          Download
        </S.MenuButton>
      )}

      {showIconButton && (
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip id='tooltip-download'>Download Files</Tooltip>}
        >
          <S.MenuIconButton
            onClick={evt => setAnchorEl(evt.currentTarget)}
            aria-controls='viewerPage-downloadMenu'
            aria-haspopup='true'
          >
            <img src={downloadIcon} alt='dl-icon' />
            {anchorEl ? <MdArrowDropUp size={18} /> : <MdArrowDropDown size={18} />}
          </S.MenuIconButton>
        </OverlayTrigger>
      )}

      <S.Menu
        id='viewerPage-downloadMenu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={onDownloadScan}>CT Scan</MenuItem>

        <MenuItem onClick={onDownloadAirway} disabled={!isDownloadAirwayEnabled}>
          Airway STL
        </MenuItem>
      </S.Menu>
    </S.DownloadDropdown>
  );
}

const S = {};

S.DownloadDropdown = styled.div`
  color: #fff;
`;

S.MenuButton = styled(Button)`
  text-transform: none;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;

  min-width: 160px;
  width: 100%;
  height: 100%;

  background-color: #1d1d1d;
  border: 1px solid #494949;
  border-radius: 6px;
  box-shadow: none;

  &:focus {
    outline: none;
  }

  .MuiButton-startIcon {
    margin-right: 14px;
  }
`;

S.MenuIconButton = styled(IconButton)`
  text-transform: none;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;

  min-width: 75px;
  width: 100%;
  height: 100%;

  background-color: #1d1d1d;
  border: 1px solid #494949;
  border-radius: 6px;
  box-shadow: none;

  &:focus {
    outline: none;
  }

  svg {
    margin-left: 7px;
  }
`;

S.Menu = styled(Menu)`
  .MuiMenu-paper {
    background-color: #1d1d1d;
    color: #ffffff;
    border: 1px solid #494949;
    margin-top: 8px;
  }

  .MuiMenu-list {
    padding: 8px;
  }

  .MuiMenuItem-root {
    font-size: 14px;
    font-weight: 700;
    border-radius: 4px;

    &:hover {
      background-color: #353535;
    }
  }
`;
