import { apiFetch } from '../../../../utils/requests';
import { uploadCenterlineURL } from '../../../../settings/api';

export default function saveCenterline(segID) {
  return (notify, getSceneState) => {
    const { centerlineChanges } = getSceneState();

    const centerlineData = centerlineChanges.states[centerlineChanges.idx];

    centerlineChanges.needsUpdate = false;
    notify();

    return apiFetch(
      uploadCenterlineURL(segID),
      localStorage.getItem('token'),
      'POST',
      centerlineData,
    ).catch(err => {
      centerlineChanges.needsUpdate = true;
      notify();

      throw err;
    });
  };
}
