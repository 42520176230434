import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { MdInfo } from 'react-icons/md';

import useSelectorToJS from '../../../hooks/useSelectorToJS';
import { getScanByID } from '../../../modules/scans/selectors';
import { getVolByID } from '../../../modules/volume3Ds/selectors';

import AirwayLogo from '../../AirwayLogo';
import SeedListBtn from './SeedListBtn';
import StartOverBtn from './StartOverBtn';
import ClearPendingSeedsBtn from './ClearPendingSeedsBtn';
import SeriesSegSelect from './SeriesSegSelect';
import TooltipStyled from '../../Tooltip.styled';

import {
  getSelectedSeg,
  getShowSeedList,
  getShowSliceViews,
  getShowScanInfoTable,
} from '../../../modules/viewer/selectors';
import setShowScanInfoTable from '../../../modules/viewer/setShowScanInfoTable';

import { SCAN_STATUS } from '../../../settings/segmentations';

import colors from '../../../stylesheets/theme/colors';
import { formatDate } from '../../../utils/general';

import seedIcon from '../../../images/seed-list.svg';
import minIcon from '../../../images/minimize.svg';
import setShowSeedList from '../../../modules/viewer/setShowSeedList';
import SensitivitySlider from './SensitivitySlider';

export default function SeedPanel({ volID, className }) {
  const { scanID } = useParams();
  const { scan, vol3D, seg, showSeedList, showSliceViews, showScanInfoTable } = useSelectorToJS(
    ({ scans, viewer, segmentations, volume3Ds }) => ({
      scan: getScanByID(scans, scanID),
      vol3D: getVolByID(volume3Ds, volID),
      seg: getSelectedSeg(viewer, segmentations),
      showSeedList: getShowSeedList(viewer),
      showSliceViews: getShowSliceViews(viewer),
      showScanInfoTable: getShowScanInfoTable(viewer),
    }),
  );

  const dispatch = useDispatch();

  if (!showSeedList) {
    return null;
  }

  const completedSeeds = seg.seedPoints.filter(seed => !seed.pending).sort((a, b) => a.id - b.id);
  const pendingSeeds = seg.seedPoints.filter(seed => seed.pending).sort((a, b) => a.id - b.id);

  const wasScanOverwritten = scan.overwriteReason !== '' || scan.overwriteFix !== '';

  return (
    <Container className={className} showBorder={!showSliceViews} data-test='panel-seed-list'>
      <Header>
        <div>
          <img src={seedIcon} alt='' />
          Seeding
        </div>
        <button
          type='button'
          onClick={() => dispatch(setShowSeedList(false))}
          data-test='min-seed-btn'
        >
          <img src={minIcon} alt='Minimize' />
        </button>
      </Header>
      <ScanInfo>
        <ScanInfoTable>
          <tbody>
            <tr>
              <th style={{ width: '75px' }}>CT</th>
              <th style={{ width: '115px' }}>Series</th>
              <th>Seg ID</th>
            </tr>
            <tr>
              <td data-test='sidebar-scanNum'>{scan.scanNum}</td>
              <td data-test='sidebar-seriesNumber'>{vol3D.seriesNumber || '-'}</td>
              <td data-test='sidebar-segID'>{seg.id}</td>
            </tr>
            <tr style={{ height: '10px' }} />
            <tr>
              <th>Uploaded</th>
            </tr>
            <tr>
              <td colSpan={wasScanOverwritten ? '2' : '3'}>
                {formatDate(scan.uploaded_at, 'yyyy-MM-dd HH:mm XXX')}
              </td>
              {wasScanOverwritten && <ScanOverwrittenCell scan={scan} />}
            </tr>
          </tbody>
        </ScanInfoTable>
        <ScanInfoButton
          variant='link'
          onClick={() => dispatch(setShowScanInfoTable(!showScanInfoTable))}
          data-test='view-scan-info-button'
        >
          <MdInfo size={22} color='#23D3CD' />
        </ScanInfoButton>
      </ScanInfo>
      <BtnContainer>
        <StartOverButton scan={scan} />
        <SeriesSegButton scan={scan} />
      </BtnContainer>
      <SensitivitySlider segID={seg.id} scanStatus={scan.status} />
      <SeedContainer>
        {Boolean(completedSeeds.length) && (
          <CurrentSeedsHeader>
            <SeedStatusContainer>
              <StyledAirwayLogo color={colors.colorSeedCompleted} />
              <div>Current Segmentation</div>
            </SeedStatusContainer>
          </CurrentSeedsHeader>
        )}
        {completedSeeds.map((seed, i) => (
          <SeedListBtn seed={seed} scanStatus={scan.status} key={seed.id}>
            Seed&nbsp;
            {i + 1}
          </SeedListBtn>
        ))}
        {Boolean(pendingSeeds.length) && (
          <PendingSeedsHeader>
            <SeedStatusContainer>
              <StyledAirwayLogo color={colors.colorSeedPending} />
              <div>Awaiting Segmentation</div>
            </SeedStatusContainer>
            {scan.status === SCAN_STATUS.UPLOADED && <ClearPendingSeedsBtn />}
          </PendingSeedsHeader>
        )}
        {pendingSeeds.map((seed, i) => (
          <SeedListBtn seed={seed} scanStatus={scan.status} key={seed.id}>
            Seed&nbsp;
            {completedSeeds.length + i + 1}
          </SeedListBtn>
        ))}
      </SeedContainer>
    </Container>
  );
}

const Container = styled.div`
  background: #1d1d1d;
  position: relative;

  display: flex;
  flex-direction: column;

  ${props =>
    props.showBorder &&
    `
  border-right: 5px solid black;
  `}
`;

const Header = styled.div`
  height: 48px;
  background-image: linear-gradient(to bottom, #414141, #0d0d0d);
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 12px;
  font-weight: bold;

  button {
    border: none;
    background: none;
    outline: none;
  }
`;

const ScanInfo = styled.div`
  color: white;
  padding: 20px;
  display: flex;
`;

const ScanInfoTable = styled.table`
  width: 100%;

  th {
    font-size: 11px;
    font-weight: normal;
    color: #a8a8a8;
  }

  td {
    font-size: 12px;
    font-weight: bold;
  }
`;

const ScanInfoButton = styled(Button)`
  margin-top: 2px;
  padding: 0;
  height: 100%;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 80px;
  border-top: 5px solid #393939;
`;

const StartOverButton = styled(StartOverBtn)`
  flex: 1 1 50px;
  margin-right: 7.5px;
`;

const SeriesSegButton = styled(SeriesSegSelect)`
  flex: 1 1 50px;
  margin-left: 7.5px;
`;

const CurrentSeedsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 20px;
  color: ${props => props.theme.colors.colorSeedCompleted};
  background-image: linear-gradient(to bottom, #404040, #1a1a1a);
`;

const PendingSeedsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 12px 0 20px;
  color: ${props => props.theme.colors.colorSeedPending};
  background-image: linear-gradient(to bottom, #404040, #1a1a1a);
`;

const SeedStatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledAirwayLogo = styled(AirwayLogo)`
  margin-right: 10px;
`;

const SeedContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

function ScanOverwrittenCell({ scan }) {
  const tooltip = (
    <Tooltip id='tooltip-scanOverwritten'>
      <div>The scan uploaded originally was overwritten.</div>

      <div style={{ marginTop: '20px' }}>
        <Label>Reason:</Label>
      </div>
      <Indent>{scan.overwriteReason}</Indent>

      <div style={{ marginTop: '20px' }}>
        <Label>Fix:</Label>
      </div>
      <Indent>{scan.overwriteFix}</Indent>
    </Tooltip>
  );

  return (
    <td colSpan='1'>
      <OverlayTrigger placement='right' overlay={tooltip}>
        <Trigger>Overwritten</Trigger>
      </OverlayTrigger>
    </td>
  );
}

const Tooltip = styled(TooltipStyled)`
  .tooltip-inner {
    flex-direction: column;
    align-items: flex-start;

    max-width: 375px;
    padding: 25px;
  }
`;

const Label = styled.span`
  font-size: 15px;
  font-weight: 500;
`;

const Indent = styled.div`
  margin-left: 20px;
  margin-top: 7px;
  font-weight: 400;
`;

const Trigger = styled.span`
  color: #ffff00;
  font-size: 15px;
  font-weight: 600;
  cursor: default;
`;
