import { getSeedPoints } from '../../selectors';
import displaySlice from './displaySlice';

export default function snapToSeed(seedIdx) {
  return (notify, getSceneState, callSceneAction) => {
    const sceneState = getSceneState();
    const seed = getSeedPoints(sceneState)[seedIdx];
    if (!seed) {
      return;
    }

    callSceneAction(displaySlice(seed.sliceIdx));

    notify();
  };
}
