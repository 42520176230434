import { AIRWAY_LABEL } from '../../../../settings/airway';

export default function removeAirway() {
  return (notify, getSceneState) => {
    const { scene } = getSceneState();

    // remove existing airway from scene, if any
    const existingAirway = scene.getObjectByName(AIRWAY_LABEL);
    if (existingAirway) {
      scene.remove(existingAirway);

      existingAirway.geometry.dispose();
      existingAirway.material.dispose();
    }

    notify();
  };
}
