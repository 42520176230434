import * as types from '../actions/auth';
import { apiFetch } from '../../utils/requests';
import { API_TOKEN_REFRESH_URL } from '../../settings/api';
import setLogoutWarningTimeout from './setLogoutWarningTimeout';

export function authRefreshAction(payload) {
  return {
    type: types.AUTH_REFRESH,
    payload,
  };
}

export default function authRefresh() {
  return (dispatch, getState) => {
    const { auth } = getState();
    const token = auth.get('token');
    const data = { token };

    return apiFetch(API_TOKEN_REFRESH_URL, null, 'POST', data).then(response => {
      localStorage.setItem('token', response.token);

      dispatch(authRefreshAction({ token: response.token, refreshedAtMs: Date.now() }));
      dispatch(setLogoutWarningTimeout(response.token));

      return response;
    });
  };
}
