import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import Modal from 'react-bootstrap/Modal';

// overlay/modal that fits specified container
export default function ContainerOverlay({ containerRef, children, backdropClassName, ...rest }) {
  if (!containerRef || !containerRef.current) {
    return null;
  }

  const backdropClasses = classNames(backdropClassName, 'modal-backdrop-fit-container'); // applied via global styles

  return (
    <OverlayModal
      container={containerRef.current}
      backdrop='static'
      backdropClassName={backdropClasses}
      dialogClassName='modal-dialog-fit-container'
      {...rest}
    >
      <Modal.Body>{children}</Modal.Body>
    </OverlayModal>
  );
}

ContainerOverlay.propTypes = {
  containerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  children: PropTypes.node.isRequired,
  backdropClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
};

ContainerOverlay.defaultProps = {
  containerRef: null,
  backdropClassName: '',
};

const OverlayModal = styled(Modal)`
  position: absolute;

  .modal-dialog-fit-container {
    &.modal-dialog-centered {
      justify-content: center;

      &::before {
        height: unset;
      }
    }
  }
`;
