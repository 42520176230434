import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useSceneSelector } from '@visionair/scene-state-3js';

import { getIsHoveringOverCanvas } from '../../../modules/scene/selectors';
import { getIsPlacingSeed } from '../../../modules/viewer/selectors';

import colors from '../../../stylesheets/theme/colors';

export default function AddSeedCrosshair() {
  const { isHoveringOverCanvas, canvas } = useSceneSelector(state => ({
    isHoveringOverCanvas: getIsHoveringOverCanvas(state),
    canvas: state.canvas,
  }));

  const isPlacingSeed = useSelector(({ viewer }) => getIsPlacingSeed(viewer));

  const horizontalLineRef = useRef();
  const verticalLineRef = useRef();

  useEffect(() => {
    if (!canvas) {
      return () => {};
    }

    const handleMouseMove = evt => {
      if (!isPlacingSeed || !verticalLineRef.current || !horizontalLineRef.current) {
        return;
      }

      verticalLineRef.current.setAttribute('x1', evt.offsetX);
      verticalLineRef.current.setAttribute('x2', evt.offsetX);

      horizontalLineRef.current.setAttribute('y1', evt.offsetY);
      horizontalLineRef.current.setAttribute('y2', evt.offsetY);
    };

    canvas.addEventListener('mousemove', handleMouseMove);

    return () => {
      canvas.removeEventListener('mousemove', handleMouseMove);
    };
  }, [canvas, isPlacingSeed]);

  if (!isPlacingSeed || !isHoveringOverCanvas) {
    return null;
  }

  return (
    <Svg height={canvas.height} width={canvas.width}>
      <line
        y1={0}
        y2={canvas.height}
        stroke={colors.colorSeedPending}
        strokeWidth={1}
        ref={verticalLineRef}
      />
      <line
        x1={0}
        x2={canvas.width}
        stroke={colors.colorSeedPending}
        strokeWidth={1}
        ref={horizontalLineRef}
      />
    </Svg>
  );
}

const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  pointer-events: none;
`;
