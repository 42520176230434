import React, { useCallback, useEffect } from 'react';
import { useSceneDispatch, useSceneSelector } from '@visionair/scene-state-3js';

import ToolBtn from './ToolBtn';
import toggleBBIcon from '../../../images/centerline_tools/toggle_bb.svg';
import toggleBBIconActive from '../../../images/centerline_tools/toggle_bb_active.svg';
import { getAxialSlicePlane } from '../../../modules/scene/selectors';
import setSlicePlanesVisibility from '../../../modules/scene/actions/airwayView/setSlicePlanesVisibility';

export default function SliceVisToggleBtn() {
  const dispatch = useSceneDispatch();
  const isVisible = useSceneSelector(state => getAxialSlicePlane(state)?.visible);

  const handleClick = useCallback(() => {
    dispatch(setSlicePlanesVisibility(!isVisible));
  }, [dispatch, isVisible]);

  const handleKeyPress = useCallback(
    event => {
      if (event.key === 'p') {
        handleClick();
      }
    },
    [handleClick],
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <ToolBtn
      tooltip={isVisible ? 'Hide Slice Planes (p)' : 'Show Slice Planes (p)'}
      onClick={handleClick}
      active={isVisible}
      data-test='slice-planes-btn'
    >
      <img src={isVisible ? toggleBBIconActive : toggleBBIcon} alt='' />
    </ToolBtn>
  );
}
