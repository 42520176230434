import { Map, fromJS } from 'immutable';
import * as types from '../actions';

export const INITIAL_STATE = new Map({
  byId: new Map(),
});

export function clearScans(state) {
  return state.set('byId', new Map());
}

function insertScan(state, payload) {
  const { scan } = payload;

  return state.setIn(['byId', scan.id], fromJS(scan));
}

export function insertScans(state, payload) {
  let newState = state;
  const { scans } = payload;
  if (scans) {
    scans.forEach(scan => {
      newState = insertScan(newState, { scan });
    });
  }

  return newState;
}

export default function scansReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.AUTH_LOGOUT:
      return INITIAL_STATE;
    case types.CLEAR_SCANS:
      return clearScans(state);
    case types.UPDATE_SCAN:
    case types.RECEIVE_SCAN:
    case types.RECEIVE_SCAN_BY_PATIENT_INFO:
      return insertScan(state, action.payload);
    case types.RECEIVE_SCANS:
    case types.RECEIVE_SCANS_BY_PATIENT:
      return insertScans(state, action.payload);
    default:
      return state;
  }
}
