import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { getSelectedSegID } from '../../../modules/viewer/selectors';

import Modal from '../../modals/ModalStyled';
import ButtonDefault from '../../buttons/ButtonDefault';
import removePendingSeeds from '../../../modules/segmentations/removePendingSeeds';

export default function ClearPendingSeedsBtn() {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const segID = useSelector(({ viewer }) => getSelectedSegID(viewer));

  return (
    <>
      <Button onClick={() => setShowModal(true)}>Clear List</Button>
      <Modal show={showModal} centered backdropClassName='modal-backdrop-blur'>
        <Modal.Header>Remove Seeds?</Modal.Header>
        <Modal.Body>
          Are you sure you want to remove all pending seed points?
          <br />
          <br />
          This cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <CloseBtn onClick={() => setShowModal(false)}>Cancel</CloseBtn>
          <ConfirmBtn onClick={() => dispatch(removePendingSeeds(segID))}>
            Remove All Seeds
          </ConfirmBtn>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const Button = styled.button`
  height: 30px;
  padding: 0 20px;
  background: #1d1d1d;
  color: white;
  font-size: 10px;
  border-radius: 5px;
  border: none;
`;

const CloseBtn = styled(ButtonDefault)`
  width: 190px;
  background-color: #969696;
  margin-right: 20px !important;
`;

const ConfirmBtn = styled(ButtonDefault)`
  width: 190px;
  background-color: #aa0014;
`;
