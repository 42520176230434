import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import immutableTransform from 'redux-persist-transform-immutable';

import auth from './modules/auth/reducer';
import notifications from './modules/notifications/reducer';
import scans from './modules/scans/reducer';
import scansTable from './modules/scansTable/reducer';
import segmentations from './modules/segmentations/reducer';
import sliceViews from './modules/sliceViews/reducer';
import volume3Ds from './modules/volume3Ds/reducer';
import viewer from './modules/viewer/reducer';

// TODO: figure out how to specify partial state that is persisted
// https://github.com/vokeit-oss/redux-persist-transform-filter-immutable
// https://github.com/edy/redux-persist-transform-filter/issues/1#issuecomment-343729441
const persistConfig = {
  storage,
  key: 'scansTable',
  whitelist: ['scansTable'],
  transforms: [immutableTransform()],
};

const rootReducer = combineReducers({
  auth,
  notifications,
  scans,
  scansTable,
  segmentations,
  sliceViews,
  volume3Ds,
  viewer,
});

export const reducer = (state, action) => rootReducer(state, action);

const persistedReducer = persistReducer(persistConfig, reducer);

export function makeStore() {
  return createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));
}

export const defaultStore = makeStore();
export const persistor = persistStore(defaultStore);

export default defaultStore;
