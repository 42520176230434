export const LINE_THICKNESS = 5; // pixels

// ignore branches with < N length
export const MIN_BRANCH_LENGTH = 5;
// ignore leaf branches with < N length
export const MIN_LEAF_LENGTH = 10;
// child branches at angle > N from parent are removed
export const MAX_BRANCH_ANGLE = 110;
// points within this dist are considered part of same branch
export const BRANCH_POINT_MAX_DIST = 3;
// how many points from end(s) of branch are considered "close" to end
// used for determining parent/child branches
export const POINTS_FROM_END = 5;

export const CENTERLINE_GROUP_NAME = 'centerline';
export const CARINA_GROUP_NAME = 'carina-point';

export const CENTERLINE_DEFAULT_COLOR = 0x00ff00;
export const CARINA_DEFAULT_COLOR = 0x0000ff;

export const CENTERLINE_DEFAULT_SMOOTH = 4.5; // mm between points

export const CENTERLINE_STATUS_PROCESSING = 'processing';
export const CENTERLINE_STATUS_SUCCESS = 'success';
export const CENTERLINE_STATUS_ERROR = 'error';
