import { getCarinaGroup, getCenterlineGroup } from '../../selectors';

export default function removeCenterline() {
  return (notify, getSceneState) => {
    const sceneState = getSceneState();
    const centerline = getCenterlineGroup(sceneState);
    if (centerline) {
      sceneState.scene.remove(centerline);

      centerline.traverse(obj => {
        if (obj.geometry) {
          obj.geometry.dispose();
          obj.material.dispose();
        }
      });
    }

    const carinaGroup = getCarinaGroup(sceneState);
    if (carinaGroup) {
      sceneState.scene.remove(carinaGroup);

      carinaGroup.traverse(obj => {
        if (obj.geometry) {
          obj.geometry.dispose();
          obj.material.dispose();
        }
      });
    }

    sceneState.centerline = null;

    sceneState.centerlineChanges.states = [];
    sceneState.centerlineChanges.idx = 0;
    sceneState.centerlineChanges.needsUpdate = false;

    notify();
  };
}
