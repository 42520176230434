import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ScanStatusIcon from './ScanStatusIcon';

import { SCANSEG_STATUS } from '../../settings/segmentations';

const ScanStatus = ({ status }) => {
  const statusText = {
    [SCANSEG_STATUS.PENDING]: 'Pending',
    [SCANSEG_STATUS.SEGMENTING]: 'Segmenting ...',
    [SCANSEG_STATUS.ERROR]: 'Error',
    [SCANSEG_STATUS.NEEDS_REVIEW]: 'Needs Review',
    [SCANSEG_STATUS.REJECTED]: 'Rejected',
    [SCANSEG_STATUS.VERIFIED]: 'Verified',
  }[status];

  return (
    <StatusContainer status={status}>
      <ScanStatusIcon status={status} />
      {statusText}
    </StatusContainer>
  );
};

ScanStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(SCANSEG_STATUS)).isRequired,
};

export default ScanStatus;

const StatusContainer = styled.div`
  width: 120px;
  height: 20px;

  border-radius: 15px;

  font-size: 13px;
  color: ${props => props.theme.colors.white};

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
