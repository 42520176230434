import setSliceImageState from './setSliceImageState';
import { getMouse, getSliceImageState } from '../../selectors';

export default function handleMouseMove(evt) {
  return (notify, getSceneState, callSceneAction) => {
    const sceneState = getSceneState();

    const mouse = getMouse(sceneState);
    if (!mouse.isDown) {
      return;
    }

    const sliceImageState = getSliceImageState(sceneState);
    const canvasX = sliceImageState.sCanvas.x - evt.movementX / sliceImageState.zoom;
    const canvasY = sliceImageState.sCanvas.y - evt.movementY / sliceImageState.zoom;
    callSceneAction(setSliceImageState(0, canvasX, canvasY));
  };
}
