import React, { useState } from 'react';
import styled from 'styled-components';

import {
  CorruptZipError,
  FileTooLargeError,
  InvalidDICOMError,
  NoDICOMFoundError,
  NoViableSeriesError,
  NumberSlicesError,
  SliceThicknessError,
} from '../../../utils/dicom/dicom-errors';
import { MAX_SLICE_THICKNESS } from '../../../utils/dicom/DicomSeries';
import { formatFileSize } from '../../../utils/files';

import { ReactComponent as UploadErrorIcon } from '../../../images/scan-overwrite-modal/ct-upload-error-icon.svg';

import ScanUploadDialog from './ScanUploadDialog';
import * as S from './ScanUploadDialog.styled';
import { MAX_ZIP_SIZE } from './StepSelectScanZip';
import { filterSeries, MAX_SLICE_THICKNESS_MM } from '../../viewer-page/ScanIssuesWarning';

export default function StepUploadError({ error, onClose }) {
  const [openScanParams, setOpenScanParams] = useState(false);

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setOpenScanParams(false);
    }, 1000);
  };

  const heading =
    {
      [CorruptZipError.className]: 'Error: Corrupt .zip file',
      [InvalidDICOMError.className]: 'Error: No valid DICOMs found in zip',
      [NoDICOMFoundError.className]: 'Error: No DICOM files found',
      [NumberSlicesError.className]: 'Error: Incomplete DICOM series',
      [SliceThicknessError.className]: `Error: Slice thickness > ${MAX_SLICE_THICKNESS} mm`,
      [FileTooLargeError.className]: `Error: .zip file too large (> ${formatFileSize(
        MAX_ZIP_SIZE,
        0,
      )})`,
      [NoViableSeriesError.className]: 'Error: No viable series found',
    }[error.name] || 'Unexpected Error';

  const errorMessage =
    {
      [CorruptZipError.className]: 'Your .zip file appears to be corrupt.',
      [InvalidDICOMError.className]:
        'We were unable to detect valid DICOM files within your .zip file.',
      [NoDICOMFoundError.className]: 'No DICOM files were found within your .zip file.',
      [NumberSlicesError.className]:
        'No CT series with enough DICOM slices was found within your .zip file.',
      [SliceThicknessError.className]: `No CT series with a valid slice thickness (≤ ${MAX_SLICE_THICKNESS} mm) was found within your .zip file.`,
      [FileTooLargeError.className]: `Your .zip file size is too large (> ${formatFileSize(
        MAX_ZIP_SIZE,
        0,
      )})`,
      [NoViableSeriesError.className]: 'No viable series were found within your .zip file.',
    }[error.name] || 'An unexpected error occurred while processing your CT scan.';

  return (
    <>
      <DialogTitle heading={heading} icon={<UploadErrorIcon />} showCloseBtn onClose={onClose} />

      <ScanUploadDialog.Content openScanParameters={openScanParams}>
        <Instructions>This CT scan cannot be uploaded.</Instructions>

        <ErrorMessage data-test='scanUploader-errorMessage'>
          {errorMessage}

          {error instanceof NoDICOMFoundError && error.jpegsFound && (
            <>
              <br />
              <br />
              However, we did detect JPEG files.
            </>
          )}
        </ErrorMessage>

        {error instanceof NoViableSeriesError && (
          <NonViableSeriesList nonViableSeries={error.nonViableSeries} />
        )}

        {!(error instanceof NoViableSeriesError) && (
          <>
            <SuggestedLabel>Suggested Action:</SuggestedLabel>
            <SuggestedAction>
              {error instanceof CorruptZipError && (
                <>
                  Re-compress your DICOMs into a <strong>new .zip file</strong>.
                  <ul>
                    <li>
                      Ensure you are <strong>not</strong> compressing a folder that already contains
                      .zip files (<strong>no nested .zip files</strong>).
                    </li>
                    <li>
                      If your DICOMs are on a CD-ROM, try copying the files onto your local file
                      system before zipping them.
                    </li>
                  </ul>
                </>
              )}

              {error instanceof InvalidDICOMError && (
                <>
                  Re-compress your DICOMs into a <strong>new .zip file</strong>.
                  <ul>
                    <li>
                      Make sure you are zipping the correct folder{' '}
                      <strong>containing your raw DICOM files</strong>.
                    </li>
                    <li>
                      Ensure you are <strong>not</strong> compressing a folder that already contains
                      .zip files (<strong>no nested .zip files</strong>).
                    </li>
                    <li>
                      If your DICOMs are on a CD-ROM, try copying the files onto your local file
                      system before zipping them.
                    </li>
                  </ul>
                </>
              )}

              {error instanceof NoDICOMFoundError && (
                <>
                  Make sure you are zipping/selecting the correct folder{' '}
                  <strong>containing your raw DICOM (.dcm) files</strong>
                  <ul>
                    {error.jpegsFound && (
                      <li>
                        We detected JPEG files (.jpeg or .jpg file extension) in your zip file which
                        indicates you might have exported your DICOM images from PACS in the
                        incorrect format.{' '}
                        <strong>
                          Try saving/exporting the images from PACS as DICOM (.dcm) files
                        </strong>{' '}
                        before zipping them.
                      </li>
                    )}
                    {!error.jpegsFound && (
                      <li>
                        If your DICOMs are on a CD-ROM, try copying the files onto your local file
                        system before zipping them.
                      </li>
                    )}
                    <li>
                      Be sure to select <strong>all</strong> desired DICOM files (or all folders
                      containing the DICOMs) before compressing them.
                    </li>
                  </ul>
                </>
              )}

              {error instanceof NumberSlicesError && (
                <>
                  Re-compress your DICOMs into a{' '}
                  <strong>new .zip file that contains the entire CT study/series</strong>.
                  <ul>
                    <li>
                      In some cases, DICOM files from the same CT study/series are stored across
                      multiple folders. If this is true, ensure you are creating a .zip file that
                      contains all of these subfolders.
                    </li>
                    <li>
                      If your DICOMs are on a CD-ROM, try copying the files onto your local file
                      system before zipping them.
                    </li>
                  </ul>
                </>
              )}

              {error instanceof SliceThicknessError && (
                <>
                  Make sure the folder that you are zipping contains a CT series with a{' '}
                  <strong>slice thickness ≤ {MAX_SLICE_THICKNESS} mm</strong>.
                  <ul>
                    <li>
                      Commonly, a CT study contains multiple series with varying slice thicknesses.
                      Ensure your .zip file includes <strong>at least one series</strong> ≤{' '}
                      {MAX_SLICE_THICKNESS} mm.
                    </li>
                  </ul>
                </>
              )}

              {error instanceof FileTooLargeError && (
                <div>
                  To reduce your .zip file size, you can try deleting any unnecessary files before
                  re-zipping your DICOM files.
                  <div style={{ marginTop: '10px' }}>
                    <strong>You can delete:</strong>
                    <ul style={{ marginBottom: '0' }}>
                      <li>any DICOM series with non-axial orientation</li>
                      <li>{`any DICOM series with slice thickness > ${MAX_SLICE_THICKNESS} mm`}</li>
                      <li>any executable files (.exe) such as DICOM Viewer applications</li>
                    </ul>
                  </div>
                </div>
              )}

              {![
                CorruptZipError.className,
                InvalidDICOMError.className,
                NoDICOMFoundError.className,
                NumberSlicesError.className,
                SliceThicknessError.className,
                FileTooLargeError.className,
              ].includes(error.name) && (
                <>
                  Click the button below to contact us.
                  <br />
                  <br />
                  We&apos;ve just been automatically alerted of this error and will begin to look
                  into it immediately, but we&apos;ll likely need to start a communication with you
                  to resolve it.
                </>
              )}
            </SuggestedAction>

            <HelpButton onClick={() => window?.groove?.widget.open()}>Contact Us</HelpButton>
          </>
        )}
      </ScanUploadDialog.Content>

      <ScanUploadDialog.ActionButtons
        ScanParametersProps={{
          showBtn: true,
          open: openScanParams,
          onClick: () => setOpenScanParams(!openScanParams),
        }}
      >
        <S.SecondaryButton onClick={handleClose} variant='outlined'>
          Close
        </S.SecondaryButton>
      </ScanUploadDialog.ActionButtons>
    </>
  );
}

const DialogTitle = styled(ScanUploadDialog.Title)`
  color: #ff0000;
`;

const Instructions = styled(S.Instructions)`
  font-weight: 400;
`;

const ErrorMessage = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #ff0000;
  margin: 20px 0;
`;

const SuggestedLabel = styled.div`
  font-size: 17px;
  font-weight: 600;
  margin: 20px 0;
`;

const SuggestedAction = styled.div`
  font-size: 16px;

  ul {
    margin-top: 20px;
  }

  li:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const HelpButton = styled(S.PrimaryButton)`
  width: 275px;
  margin-top: 30px;
  align-self: center;
`;

function NonViableSeriesList({ nonViableSeries }) {
  nonViableSeries.sort((a, b) => {
    return parseInt(a.seriesNumber, 10) < parseInt(b.seriesNumber, 10) ? -1 : 1;
  });

  const {
    seriesNonAxial,
    seriesNonSquareVoxels,
    seriesMissingSlices,
    seriesInvalidSliceThickness,
    seriesExpiration,
  } = filterSeries(nonViableSeries);

  return (
    <WarningsList>
      {seriesNonAxial.length > 0 && (
        <li>
          Series is not axial supine{' '}
          <SeriesList>
            {seriesNonAxial.map(v => (
              <li key={v.id}>
                Series {v.seriesNumber} - {v.seriesDescription}
              </li>
            ))}
          </SeriesList>
        </li>
      )}

      {seriesNonSquareVoxels.length > 0 && (
        <li>
          Series has non-square voxels{' '}
          <SeriesList>
            {seriesNonSquareVoxels.map(v => (
              <li key={v.id}>
                Series {v.seriesNumber} - {v.seriesDescription}
              </li>
            ))}
          </SeriesList>
        </li>
      )}

      {seriesMissingSlices.length > 0 && (
        <li>
          Series is missing slices{' '}
          <SeriesList>
            {seriesMissingSlices.map(v => (
              <li key={v.id}>
                Series {v.seriesNumber} - {v.seriesDescription}
              </li>
            ))}
          </SeriesList>
        </li>
      )}

      {seriesExpiration.length > 0 && (
        <li>
          Series is expiration{' '}
          <SeriesList>
            {seriesExpiration.map(v => (
              <li key={v.id}>
                Series {v.seriesNumber} - {v.seriesDescription}
              </li>
            ))}
          </SeriesList>
        </li>
      )}

      {seriesInvalidSliceThickness.length > 0 && (
        <li>
          Series has invalid slice thickness ( &gt; {MAX_SLICE_THICKNESS_MM} mm ){' '}
          <SeriesList>
            {seriesInvalidSliceThickness.map(v => (
              <li key={v.id}>
                Series {v.seriesNumber} - {v.seriesDescription}
                <ul>
                  <li>{v.sliceThickness} mm</li>
                </ul>
              </li>
            ))}
          </SeriesList>
        </li>
      )}
    </WarningsList>
  );
}

const WarningsList = styled.ul`
  margin-bottom: 0;
  padding-inline-start: 25px;

  li:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const SeriesList = styled.ul`
  margin-top: 8px;
  padding-inline-start: 30px;
`;
