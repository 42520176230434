import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import * as THREE from 'three';
import { useDispatch } from 'react-redux';
import { useSceneDispatch, useSceneSelector } from '@visionair/scene-state-3js';

import displaySlice from '../../../modules/scene/actions/sliceView/displaySlice';
import sliderThumbImg from '../../../images/slice-slider-thumb.png';
import { getSliceView, getSliceIdx } from '../../../modules/scene/selectors';
import useSelectorToJS from '../../../hooks/useSelectorToJS';
import { getSegSeedPoints } from '../../../modules/segmentations/selectors';
import { getSelectedSegID } from '../../../modules/viewer/selectors';
import { showNotificationError } from '../../../modules/notifications/showNotification';

const SEED_MARKER_HEIGHT_PX = 4;
const THUMB_HEIGHT_PX = 24;

// Coordinate system for this component...

// Axial slices
// top of slider is sliceView.numSlices - 1 sliceIdx
// bottom of slider is 0 sliceIdx

// Coronal slices
// top of slider 0 sliceIdx
// bottom of slider is sliceView.numSlices - 1 sliceIdx

export default function SliceSlider() {
  const sceneProps = useSceneSelector(sceneState => ({
    sliceView: getSliceView(sceneState),
    sliceIdx: getSliceIdx(sceneState),
  }));

  const { sliceView } = sceneProps;

  let { sliceIdx } = sceneProps;
  if (sliceView && sliceView.isCoronalView) {
    sliceIdx = sliceView.numSlices - 1 - sliceIdx;
  }

  const seeds = useSelectorToJS(({ segmentations, viewer }) => {
    const segID = getSelectedSegID(viewer);
    return getSegSeedPoints(segmentations, segID);
  });

  const sceneDispatch = useSceneDispatch();
  const reduxDispatch = useDispatch();

  const trackRef = useRef();
  const thumbRef = useRef();
  const mouseState = useRef({
    isDown: false,
    yOffset: 0,
  });

  const handleSliceError = () => {
    reduxDispatch(showNotificationError('An unexpected error occurred while loading scan images.'));
  };

  useEffect(() => {
    if (!sliceView) {
      return () => {};
    }

    trackRef.current.addEventListener('click', evt => {
      const sliceNum = Math.round(
        (evt.offsetY / trackRef.current.clientHeight) * (sliceView.numSlices - 1),
      );
      const sliceToFetch = sliceView.isAxialView ? sliceView.numSlices - 1 - sliceNum : sliceNum;

      sceneDispatch(displaySlice(clampSliceIdx(sliceToFetch, sliceView))).catch(handleSliceError);
    });

    thumbRef.current.addEventListener('mousedown', evt => {
      mouseState.current.isDown = true;
      mouseState.current.yOffset = evt.offsetY;
    });

    const handleMouseUp = () => {
      mouseState.current.isDown = false;
    };
    document.body.addEventListener('mouseup', handleMouseUp);

    const handleMouseLeave = () => {
      mouseState.current.isDown = false;
    };
    document.body.addEventListener('mouseleave', handleMouseLeave);

    const handleMouseMove = evt => {
      if (!mouseState.current.isDown) {
        return;
      }

      const { top, height } = trackRef.current.getBoundingClientRect();
      const trackOffset = evt.clientY - top - mouseState.current.yOffset;
      const sliceNum = Math.round((trackOffset / height) * (sliceView.numSlices - 1));
      const sliceToFetch = sliceView.isAxialView ? sliceView.numSlices - 1 - sliceNum : sliceNum;

      sceneDispatch(displaySlice(clampSliceIdx(sliceToFetch, sliceView))).catch(handleSliceError);
    };
    document.body.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.body.removeEventListener('mouseup', handleMouseUp);
      document.body.removeEventListener('mousemove', handleMouseMove);
      document.body.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [sceneDispatch, sliceView]);

  if (!sliceView) {
    return null;
  }

  const sliderPercentage = (sliceIdx / (sliceView.numSlices - 1)) * 100;

  return (
    <>
      <Container>
        <Track ref={trackRef} />
        {seeds &&
          seeds.map(seed => {
            const seedSliceIdx = sliceView.calcSliceIdx(
              new THREE.Vector3().fromArray(seed.position),
            );
            const adjustedSliceIdx = sliceView.isCoronalView
              ? sliceView.numSlices - 1 - seedSliceIdx
              : seedSliceIdx;
            const seedPercentage = (adjustedSliceIdx / (sliceView.numSlices - 1)) * 100;

            return (
              <SeedMarker
                key={seed.id}
                style={{ bottom: `calc(${seedPercentage}% - ${SEED_MARKER_HEIGHT_PX}px)` }}
                isPending={seed.pending}
                onClick={() => {
                  sceneDispatch(displaySlice(seedSliceIdx)).catch(handleSliceError);
                }}
              />
            );
          })}
        <Thumb
          ref={thumbRef}
          style={{ bottom: `calc(${sliderPercentage}% - ${THUMB_HEIGHT_PX}px)` }}
          data-test='slice-slider-thumb'
        />
      </Container>
      <Background />
    </>
  );
}

function clampSliceIdx(sliceIdx, sliceView) {
  return Math.max(0, Math.min(sliceView.numSlices - 1, sliceIdx));
}

const Container = styled.div`
  z-index: 2;
  position: relative;
  width: 15px;
  height: calc(100% - ${SEED_MARKER_HEIGHT_PX / 2 + THUMB_HEIGHT_PX}px);
`;

const Background = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 15px;
  background: #2d2d2d;
`;

const Track = styled.div`
  background: #2d2d2d;
  position: absolute;
  width: 15px;
  height: 100%;
  top: ${SEED_MARKER_HEIGHT_PX / 2}px;
`;

const Thumb = styled.div`
  background-image: url(${sliderThumbImg});
  position: absolute;
  right: 15px;
  z-index: 5;
  height: 24px;
  width: 60px;
  cursor: pointer;
`;

const SeedMarker = styled.button`
  position: absolute;
  background: ${props =>
    props.isPending ? props.theme.colors.colorSeedPending : props.theme.colors.colorSeedCompleted};
  height: ${SEED_MARKER_HEIGHT_PX}px;
  width: 100%;
  cursor: pointer;

  && {
    outline: none;
    border: none;
  }
`;
