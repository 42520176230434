import React from 'react';

export default function AirwayLogo({ color, className }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      // xmlns:xlink='http://www.w3.org/1999/xlink'
      width='28'
      height='34'
      viewBox='0 0 28 34'
      className={className}
    >
      <defs>
        <filter
          id='prefix__a'
          width='165%'
          height='150%'
          x='-32.5%'
          y='-21.2%'
          filterUnits='objectBoundingBox'
        >
          <feOffset dy='1' in='SourceAlpha' result='shadowOffsetOuter1' />
          <feGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='2' />
          <feColorMatrix in='shadowBlurOuter1' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0' />
        </filter>
        <path
          id='prefix__b'
          d='M29.426 14.347c.393-.616 1.758-.242 2.068-.152.212.06.363.344.455.852v2.77c-.158-.485-.151-.45.035.104.269.83.29-.076.475 2.077.128 1.063.128 2.137 0 3.2-.323 1.919-.013 3.892.883 5.617.824 1.67 2.212 2.99 3.916 3.726l2.007.118c.475-.111 1.179-.291 1.379-.603.205-.382.3-.813.275-1.246l-.179-.319.186-.422.69.124.358-.353c.07 2.874 0 4.432-.22 4.675-.117.094-.266.136-.414.118-.103-.205-.26-.378-.455-.5 0 0-.22.34-.248.326l-.076-.034 1.055 1.184.358.36-.11.561c0 .651-.303 1.17-1.096.083-.57-.725-1.233-1.37-1.972-1.918 0 0-.628-.25-1.255-.471h-2.234l-2.82-1.718c-.305-.185-.698-.127-.938.139l-.586.644-1.758.603c-.285-.19-.623-.285-.965-.27-.362-.015-.72.079-1.027.27-1.083 1.15-1.82 1.98-2.234 2.493-.377.433-.638.955-.759 1.517-.117.408.076.519-.165 1.128-.142.455-.49.814-.938.97-.131 0 .22-.332.345-.97 0 0-.186-.346-.648-.048-.26.09-.539.108-.807.048.215-.106.41-.252.572-.429.469-.499.772-.596.883-.977.034-.11-.262-.193-.883-.242-.103-.692 0-1.08.4-1.212 1.117-.409 2.255-2.182 2.255-2.182-.293-.241-.646-.4-1.02-.457-.251.164-.533.273-.828.319-.173 0-.262-.139-.276-.402-.183-.013-.367.016-.538.083-.158.104-.103.36-.234.395-.131.035-.338-.16-.338-.63l.572-.568c-.386 0-.351-.132.104-.388.455-.256.62-.34.51-.25.024-.462-.001-.927-.076-1.384-.083-.277-.49-.125-.434-.333.055-.208.49-.485.613-.374.225.096.382.304.414.547.05.441.129.878.234 1.31.25.46.649.819 1.131 1.017.44-.033.878-.093 1.31-.18.635-.152 1.103.208 1.283 0 .427-.491 1.482-1.33 1.592-2.043.132-1.564.074-3.138-.172-4.688-.303-1.801 0-7.106 0-7.411.015-.407-.062-.812-.228-1.184-.167-.51-.125-1.066.118-1.545.025-.522.182-1.03.455-1.475z'
        />
      </defs>
      <g fill='none' fillRule='evenodd' transform='translate(-18 -11)'>
        <use fill='#000' filter='url(#prefix__a)' xlinkHref='#prefix__b' />
        <use fill={color} xlinkHref='#prefix__b' />
      </g>
    </svg>
  );
}
