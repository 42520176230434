import { Branch } from '@visionair/centerline-3js';

import { findClosestPoint } from '../../../../utils/threeUtils';
import { CP_MARKER_NAME } from '../../../../components/viewer-page/airway-viewer/AdjustToCentroidTool';
import { getBranchMesh } from '../../selectors';

const BRANCH_EDIT_LENGTH = 10;

export default function adjustBranchCenterPoint(newPoint) {
  return (notify, getSceneState) => {
    const sceneState = getSceneState();
    const { selectedBranch, scene } = sceneState;

    if (!sceneState.tempBranch) {
      sceneState.tempBranch = new Branch(selectedBranch.airway);
    }

    const { closestPoint } = findClosestPoint(newPoint, selectedBranch.points);

    const stepSize = 1;

    let newPointAdded = false;
    const newBranchPoints = [selectedBranch.start];

    const newSegmentPoints = [null];

    selectedBranch.forEachSpacedPoint((point, u) => {
      const dist = point.distanceTo(closestPoint);
      if (u === 0 || u === 1) {
        return;
      }

      if (dist >= BRANCH_EDIT_LENGTH / 2) {
        newBranchPoints.push(point);
        if (!newSegmentPoints[2]) {
          newSegmentPoints.splice(-1, 1, point);
        }
      } else if (!newPointAdded) {
        newBranchPoints.push(newPoint.clone());
        newSegmentPoints.push(newPoint, null);
        newPointAdded = true;
      }
    }, stepSize);

    newBranchPoints.push(selectedBranch.end);

    if (newSegmentPoints[0] === null) {
      newSegmentPoints.splice(0, 1, selectedBranch.start);
    }
    if (newSegmentPoints[2] === null) {
      newSegmentPoints.splice(-1, 1, selectedBranch.end);
    }

    sceneState.tempBranch.setPoints(newSegmentPoints);
    sceneState.tempBranch.samplePointsAtDist(1);
    sceneState.tempBranch.visualize(scene, 0xffff00);
    const tempLine = getBranchMesh(sceneState, sceneState.tempBranch);
    tempLine.onBeforeRender = renderer => {
      renderer.clearDepth();
    };
    tempLine.renderOrder = 2;

    selectedBranch.setPoints(newBranchPoints);
    selectedBranch.samplePointsAtDist(1);

    const cpMarker = scene.getObjectByName(CP_MARKER_NAME);
    cpMarker.position.copy(newPoint);
  };
}
