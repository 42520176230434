import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import parseISO from 'date-fns/parseISO';

import ScanStatus from './ScanStatus';

import useSelectorToJS from '../../hooks/useSelectorToJS';
import {
  getIsLoadingScans,
  getScansSearch,
  getScansSort,
  getScansStatusFilters,
} from '../../modules/scansTable/selectors';
import InfiniteScrollTable, { SORT_DESC } from '../InfiniteScrollTable';
import setScansSearch from '../../modules/scansTable/setScansSearch';
import { searchScans } from '../../utils/requests';
import { showNotificationError } from '../../modules/notifications/showNotification';
import setIsLoadingScans from '../../modules/scansTable/setIsLoadingScans';
import { searchFields } from '../../modules/scansTable/reducer';
import { dateRangeFromISO8601Date, formatDate } from '../../utils/general';
import setScansSort from '../../modules/scansTable/setScansSort';

const columns = [
  {
    Header: 'CT #',
    accessor: 'scanNum',
  },
  {
    Header: 'Physician',
    accessor: row => `${row.doctorFirstName} ${row.doctorLastName}`,
  },
  {
    Header: 'Uploaded',
    accessor: 'uploaded_at',
    Cell: ({ cell: { value } }) => value && formatDate(parseISO(value), 'yyyy-MM-dd HH:mm'),
  },
  {
    Header: 'Verified / Rejected',
    accessor: 'verifiedRejectedAt',
    Cell: ({ cell: { value } }) => value && formatDate(parseISO(value), 'yyyy-MM-dd HH:mm'),
  },
  {
    Header: 'Status',
    accessor: 'scanSegStatus',
    // eslint-disable-next-line react/prop-types
    Cell: ({ cell: { value } }) => (
      <div className='center-cell'>
        <ScanStatus status={value} />
      </div>
    ),
    headerClassName: 'center-cell',
  },
];

const NUM_SCANS_TO_FETCH = 50;
const ROW_HEIGHT = 45;
const SEG_LIST_REFRESH_INTERVAL_MS = 30 * 1000; // 30 sec

export default function ScansTable({ containerRef }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [scans, setScans] = useState([]);
  const [numTotalScans, setNumTotalScans] = useState(NUM_SCANS_TO_FETCH);
  const nScansRef = useRef(0);
  const initialScansLoadedRef = useRef(false);
  const [tick, setTick] = useState(0);

  const { sort, search, statuses } = useSelectorToJS(({ scansTable }) => ({
    sort: getScansSort(scansTable),
    search: getScansSearch(scansTable),
    statuses: getScansStatusFilters(scansTable),
  }));

  const isLoading = useSelector(({ scansTable }) => getIsLoadingScans(scansTable));

  const defaultSorted = useMemo(() => {
    return [{ id: sort.field, desc: sort.dir === SORT_DESC }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prevSearchFieldRef = useRef(search.field);

  useEffect(() => {
    return () => {
      dispatch(setScansSearch(undefined, ''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch scans every 30 seconds if there are fewer than NUM_SCANS_TO_FETCH rows
  useEffect(() => {
    const timerID = setInterval(() => {
      if (scans.length < NUM_SCANS_TO_FETCH) {
        setTick(Date.now());
      }
    }, SEG_LIST_REFRESH_INTERVAL_MS);

    return () => {
      clearInterval(timerID);
    };
  }, [scans]);

  const fetchScans = useCallback(
    (offset = 0) => {
      const searchScansRequest = async searchVal => {
        const scanSegStatus = [];
        Object.entries(statuses).forEach(([status, isActive]) => {
          if (isActive) {
            scanSegStatus.push(status);
          }
        });

        try {
          const resScans = await searchScans({
            [search.field]: searchVal,
            sortField: sort.field,
            sortDir: sort.dir,
            limit: NUM_SCANS_TO_FETCH,
            withSegs: true,
            scanSegStatus,
            offset,
          });

          initialScansLoadedRef.current = true;

          // append to scans list, or replace if offset is 0
          setScans(oldScans => {
            const newScans = offset > 0 ? oldScans.concat(resScans) : resScans;
            nScansRef.current = newScans.length;

            return newScans;
          });

          const nScans =
            resScans.length === NUM_SCANS_TO_FETCH
              ? nScansRef.current + NUM_SCANS_TO_FETCH
              : nScansRef.current;
          setNumTotalScans(nScans);
        } catch (err) {
          console.error(err); // eslint-disable-line no-console
          dispatch(
            showNotificationError('An unexpected error occurred while searching for scans.', 10000),
          );
        } finally {
          dispatch(setIsLoadingScans(false));
        }
      };

      // don't send request if search field was changed, but new search value hasn't been entered yet
      if (search.field !== prevSearchFieldRef.current) {
        prevSearchFieldRef.current = search.field;
        return;
      }
      prevSearchFieldRef.current = search.field;

      let searchVal = search.value;

      // don't send request if not valid date string
      if (search.field === searchFields.UPLOAD_DATE) {
        const uploadDateSearch = search.value.replace(/-/g, '');
        if (
          uploadDateSearch.length && // send request if value empty
          uploadDateSearch.length !== 'YYYY'.length &&
          uploadDateSearch.length !== 'YYYYMM'.length &&
          uploadDateSearch.length !== 'YYYYMMDD'.length
        ) {
          return;
        }

        const { startDate, endDate } = dateRangeFromISO8601Date(search.value);

        searchVal = {
          start: startDate?.toISOString(),
          end: endDate?.toISOString(),
        };
      }

      // if offset is 0, set table length to 0 so page will be rerendered on sorting
      // otherwise set page length to the current length of rows to prevent fetching more data
      setNumTotalScans(offset === 0 ? 0 : nScansRef.current);

      dispatch(setIsLoadingScans(true));

      searchScansRequest(searchVal);
    },
    [search.value, search.field, dispatch, sort.field, sort.dir, statuses],
  );

  // fetch scans from beginning when search param changes
  useEffect(() => {
    // prevent multiple requests being sent on page load
    // <InfiniteLoader /> will trigger the first request
    if (!initialScansLoadedRef.current) {
      return;
    }

    fetchScans(0);
    // include tick in deps to force refetching rows
  }, [fetchScans, tick]);

  const handleSort = useCallback(
    (sortField, sortDir) => {
      dispatch(setScansSort(sortField, sortDir));
    },
    [dispatch],
  );

  const handleRowClick = rowVals => {
    const { id: scanID, volume3DID, segID } = rowVals;
    history.push({
      pathname: `/viewer/${scanID}`,
      state: {
        volume3DID,
        segID,
      },
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <InfiniteScrollTable
        containerRef={containerRef}
        columns={columns}
        data={scans}
        defaultSorted={defaultSorted}
        rowHeight={ROW_HEIGHT}
        loadMoreRows={fetchScans}
        onRowClick={handleRowClick}
        onRowSort={handleSort}
        numTotalRows={numTotalScans}
        isLoading={isLoading}
      />
      {scans.length === 0 && <RowNoData>No scans found...</RowNoData>}
    </div>
  );
}

const RowNoData = styled.div`
  color: white;
  position: absolute;
  top: 69px;
  left: 50%;
  transform: translateX(-50%);
`;
