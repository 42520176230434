import addNewSegmentation from './addNewSegmentation';
import setSegID from '../viewer/setSegID';
import { clearSegmentationURL } from '../../settings/api';
import { apiFetch } from '../../utils/requests';
import { getToken } from '../auth/selectors';

export default function clearSegmentation(segID) {
  return (dispatch, getState) => {
    const token = getToken(getState().auth);

    return apiFetch(clearSegmentationURL(segID), token, 'POST').then(seg => {
      dispatch(addNewSegmentation(seg));
      dispatch(setSegID(seg.id));
    });
  };
}
