import FileSaver from 'file-saver';
import JSZip from 'jszip';

export function exportToFile(data, filetype, filename) {
  const blob = new Blob([data], { type: filetype });
  FileSaver.saveAs(blob, filename);
}

// returns formatted timestamp for use in filename
// eg. 2020-04-18T191300 -> users-report-2020-04-18T191300.csv
export function filenameTS(ts = new Date()) {
  const iso = ts.toISOString();
  const formatted = iso.split('.')[0].replace(/:/g, '');

  return formatted;
}

export function getFileNameFromPath(filePath) {
  const filename = filePath.replace(/^.*[\\/]/, '');
  return filename.split(/[?#]/)[0]; // remove query string/hash
}

/**
 * Returns file name and extension
 * @param {String} filePath - path of file, could be just filename too
 * @return {[String, String || null]} [name, extension]
 */
export function getFileNameAndExtensionFromPath(filePath) {
  const fileNameWithExtension = getFileNameFromPath(filePath);

  const name =
    fileNameWithExtension.substring(0, fileNameWithExtension.lastIndexOf('.')) ||
    fileNameWithExtension;

  let extension = null;
  // filename might not have extension
  if (name !== fileNameWithExtension) {
    extension = fileNameWithExtension.split('.').pop();
  }

  return [name, extension];
}

// returns Promise that resolves with ZipObject instances
// see https://stuk.github.io/jszip/documentation/api_zipobject.html
export function readZipFile(zipFile) {
  const jsZip = new JSZip();

  return jsZip
    .loadAsync(zipFile, {
      // https://stuk.github.io/jszip/documentation/api_jszip/load_async.html#checkcrc32-option
      checkCRC32: true,
    })
    .then(zipObject => zipObject.files);
}

export function getContentOfFileInZip(zipObject, resultType) {
  return zipObject.async(resultType);
}

export function calcPercentLoaded(loaded, total) {
  return Math.round((loaded / total) * 100);
}

export function fileSizeInMB(sizeBytes) {
  return sizeBytes / (1024 * 1024);
}

export function formatFileSize(bytes, decimalPoint) {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1000;
  let dm = decimalPoint;
  if (!dm && dm !== 0) {
    dm = 2;
  }

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const label = sizes[i];

  const size = parseFloat((bytes / k ** i).toFixed(dm));

  return `${size} ${label}`;
}

export function readFileAsArrayBuffer(file, onProgressCallback = () => {}) {
  return new Promise((resolve, reject) => {
    // eslint-disable-line promise/avoid-new
    const reader = new FileReader();

    reader.onprogress = e => {
      if (e.lengthComputable) {
        onProgressCallback(e.loaded, e.total);
      }
    };

    reader.onload = () => resolve(reader.result);

    reader.onerror = () => reject(reader.error);

    reader.readAsArrayBuffer(file);
  });
}
