import { getSegBB } from '../../selectors';

export default function setIsSegBBVisible(isVisible) {
  return (notify, getSceneState) => {
    const sceneState = getSceneState();

    const segBB = getSegBB(sceneState);
    if (segBB) {
      segBB.visible = isVisible;
      notify();
    }
  };
}
