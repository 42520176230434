import colors from './colors';
import fonts from './fonts';

export default {
  pageHeading: {
    'font-family': fonts.fontFamilyDefault,
    'font-size': '24px',
    color: colors.black,
  },

  bodyDefault: {
    'font-family': fonts.fontFamilyDefault,
    'font-size': '14px',
    color: colors.black,
  },
  bodyBold: {
    'font-size': '14px',
    'font-weight': '500',
  },

  bodySmall: {
    'font-family': fonts.fontFamilyDefault,
    'font-size': '12px',
    color: colors.black,
  },
  bodySmallBold: {
    'font-size': '12px',
    'font-weight': '500',
  },
};
