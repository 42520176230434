import { Vector3, BoxGeometry, MeshBasicMaterial, Mesh, Group, Box3 } from 'three';

import { AXIAL_PLANE_NAME, CORONAL_PLANE_NAME } from '../../../../settings/airway';
import {
  AXIAL_VIEWER_NAME,
  CORONAL_VIEWER_NAME,
  getAirwaySTL,
  getAxialSlicePlane,
  getCoronalSlicePlane,
} from '../../selectors';
import updateSlicePlane from './updateSlicePlane';

export default function initializeSlicePlanes() {
  return (notify, getSceneState, callSceneAction) => {
    const sceneState = getSceneState();

    let planesVisible = false;

    const prevAxialPlane = getAxialSlicePlane(sceneState);
    const prevCoronalPlane = getCoronalSlicePlane(sceneState);
    if (prevAxialPlane) {
      sceneState.scene.remove(prevAxialPlane);
      sceneState.scene.remove(prevCoronalPlane);

      planesVisible = prevAxialPlane.visible;
    }

    const axialSceneState = getSceneState(AXIAL_VIEWER_NAME);
    const coronalSceneState = getSceneState(CORONAL_VIEWER_NAME);
    if (!axialSceneState || !coronalSceneState) {
      return;
    }

    const { sliceView: axialSliceView, sliceIdx: axialSliceIdx } = axialSceneState;
    const { sliceView: coronalSliceView, sliceIdx: coronalSliceIdx } = coronalSceneState;

    const airway = getAirwaySTL(sceneState);
    if (!airway || !axialSliceView || !coronalSliceView) {
      return;
    }

    const { boundingBox: volumeBB } = axialSliceView.volume3D;

    const airwayBB = new Box3().setFromObject(airway);

    const center = new Vector3();
    const size = new Vector3();
    airwayBB.getSize(size);
    airwayBB.getCenter(center);

    const axialGeo = new BoxGeometry(size.x, size.y, 0.0001);
    const coronalGeo = new BoxGeometry(size.x, 0.0001, size.z);

    const axialWireframe = new Mesh(
      axialGeo,
      new MeshBasicMaterial({
        color: 0x00ff00,
        wireframe: true,
      }),
    );
    axialWireframe.position.set(center.x, center.y, volumeBB.min.z);
    const axialSolid = new Mesh(
      axialGeo,
      new MeshBasicMaterial({ color: 0x00ff00, transparent: true, opacity: 0.1 }),
    );
    axialSolid.position.set(center.x, center.y, volumeBB.min.z);

    const axialPlane = new Group();

    // axial slice view starts at max index
    axialPlane.position
      .copy(axialSliceView.orientation)
      .multiplyScalar(axialSliceView.calcSlicePosition(axialSliceView.numSlices - 1));
    axialPlane.add(axialWireframe, axialSolid);
    axialPlane.renderOrder = 1;
    axialPlane.name = AXIAL_PLANE_NAME;
    axialPlane.visible = planesVisible;

    const coronalWireframe = new Mesh(
      coronalGeo,
      new MeshBasicMaterial({
        color: 0x0000ff,
        wireframe: true,
      }),
    );
    coronalWireframe.position.set(center.x, volumeBB.min.y, center.z);
    const coronalSolid = new Mesh(
      coronalGeo,
      new MeshBasicMaterial({ color: 0x0000ff, transparent: true, opacity: 0.1 }),
    );
    coronalSolid.position.set(center.x, volumeBB.min.y, center.z);

    const coronalPlane = new Group();
    coronalPlane.add(coronalWireframe, coronalSolid);
    coronalPlane.renderOrder = 1;
    coronalPlane.name = CORONAL_PLANE_NAME;
    coronalPlane.visible = planesVisible;

    sceneState.scene.add(axialPlane, coronalPlane);

    if (axialSliceIdx != null) {
      callSceneAction(updateSlicePlane(axialSliceView, axialSliceIdx));
    }
    if (coronalSliceIdx != null) {
      callSceneAction(updateSlicePlane(coronalSliceView, coronalSliceIdx));
    }

    notify();
  };
}
