import React, { useEffect, useRef, useCallback } from 'react';
import * as THREE from 'three';
import styled from 'styled-components';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import {
  Provider as SceneProvider,
  useSceneDispatch,
  useSceneSetup,
  useSceneSelector,
  resizeCanvas,
  useCanvasMouseEvent,
  useSceneCallback,
} from '@visionair/scene-state-3js';

import SliceSlider from './SliceSlider';
import SeedPoint, { CIRCLE_RADIUS } from './SeedPoint';
import AddSeedCrosshair from './AddSeedCrosshair';

import useSelectorToJS from '../../../hooks/useSelectorToJS';
import { CANVAS_RESIZE_DEBOUNCE_MS } from '../../../settings/viewer';
import { debounced } from '../../../utils/general';
import MaskMaterial from '../../../three/MaskMaterial';

import loadMask from '../../../modules/scene/actions/sliceView/loadMask';
import {
  getSliceLoader,
  getIsHoveringOverCanvas,
  getIsSliceLoaderInitialized,
} from '../../../modules/scene/selectors';
import initializeSliceLoader from '../../../modules/scene/actions/initializeSliceLoader';
import setSliceImageState from '../../../modules/scene/actions/sliceView/setSliceImageState';
import handleMouseUp from '../../../modules/scene/actions/sliceView/handleMouseUp';
import handleMouseMove from '../../../modules/scene/actions/sliceView/handleMouseMove';
import displaySlice from '../../../modules/scene/actions/sliceView/displaySlice';
import setSeedPoints from '../../../modules/scene/actions/sliceView/setSeedPoints';
import snapToSeed from '../../../modules/scene/actions/sliceView/snapToSeed';
import { getSegSeedPoints } from '../../../modules/segmentations/selectors';
import { getIsPlacingSeed, getIsSelectingCenterlineStart } from '../../../modules/viewer/selectors';
import { showNotificationError } from '../../../modules/notifications/showNotification';
import setMaskSliceView from '../../../modules/scene/actions/sliceView/setMaskSliceView';
import setIsAxialViewLoaded from '../../../modules/viewer/setIsAxialViewLoaded';
import setIsCoronalViewLoaded from '../../../modules/viewer/setIsCoronalViewLoaded';

export function SliceViewerComponent({ canvasName, maskGeo, sliceView, seg }) {
  const sliceViewRef = useRef();
  const sliceCanvasRef = useRef();

  const { sliceLoader, isHoveringOverCanvas, isSliceLoaderInitialized } = useSceneSelector(
    sceneState => ({
      sliceLoader: getSliceLoader(sceneState),
      isHoveringOverCanvas: getIsHoveringOverCanvas(sceneState),
      isSliceLoaderInitialized: getIsSliceLoaderInitialized(sceneState),
    }),
  );

  const sceneDispatch = useSceneDispatch();
  const reduxDispatch = useDispatch();

  const seedPoints = useSelectorToJS(({ segmentations }) =>
    getSegSeedPoints(segmentations, seg.id),
  );

  const { isPlacingSeed, isSelectingCenterlineStart } = useSelector(({ viewer }) => ({
    isPlacingSeed: getIsPlacingSeed(viewer),
    isSelectingCenterlineStart: getIsSelectingCenterlineStart(viewer),
  }));

  useEffect(() => {
    if (seedPoints) {
      sceneDispatch(setSeedPoints(seedPoints, sliceView));
    }
  }, [sceneDispatch, seedPoints, sliceView]);

  useSceneSetup(sceneState => {
    sceneState.scene.add(new THREE.AmbientLight(0x505050));

    sceneState.canvas.addEventListener('mouseup', evt => sceneDispatch(handleMouseUp(evt)));
    sceneState.canvas.addEventListener('mousemove', evt => sceneDispatch(handleMouseMove(evt)));
    sceneState.canvas.addEventListener('wheel', evt => {
      evt.preventDefault();

      if (evt.ctrlKey) {
        sceneDispatch(setSliceImageState(-0.01 * evt.deltaY));
      } else {
        const scrollDir = sceneState.sliceView.isAxialView ? -1 : 1;
        sceneDispatch(displaySlice(undefined, scrollDir * evt.deltaY)).catch(() => {
          reduxDispatch(
            showNotificationError('An unexpected error occurred while loading scan images.'),
          );
        });
      }
    });

    const mat = new MaskMaterial();
    const geo = new THREE.BufferGeometry();
    geo.setFromPoints([new THREE.Vector3()]);
    const points = new THREE.Points(geo, mat);
    points.name = 'airway-mask';

    // eslint-disable-next-line no-param-reassign
    sceneState.mask = points;
    sceneState.scene.add(sceneState.mask);
  });

  useEffect(() => {
    sceneDispatch(initializeSliceLoader(sliceCanvasRef.current, sliceView))
      .then(() => {
        sceneDispatch(snapToSeed(0));

        if (sliceView.isAxialView) {
          reduxDispatch(setIsAxialViewLoaded(true));
        } else {
          reduxDispatch(setIsCoronalViewLoaded(true));
        }
      })
      .catch(console.error);

    sceneDispatch(setMaskSliceView(sliceView));
  }, [sceneDispatch, sliceView, reduxDispatch]);

  // call after initializing slice loader
  // otherwise the sliceView within the slice loader may be out of date
  useEffect(() => {
    if (maskGeo) {
      sceneDispatch(loadMask(maskGeo));
    }
  }, [maskGeo, sceneDispatch]);

  useEffect(() => {
    if (!sliceLoader) {
      return;
    }

    // eslint-disable-next-line no-new
    new ResizeSensor(
      sliceViewRef.current,
      debounced(CANVAS_RESIZE_DEBOUNCE_MS, () => {
        if (!sliceViewRef.current) {
          return;
        }

        const { clientWidth: width, clientHeight: height } = sliceViewRef.current;

        sceneDispatch(resizeCanvas(width, height));
        sliceLoader.loader.resizeCanvas(width, height);
      }),
    );

    // eslint-disable-next-line consistent-return
    return () => {
      sliceLoader.dispose();
    };
  }, [sceneDispatch, sliceLoader]);

  const setSeedIntersection = useSceneCallback(
    (seedID, isIntersecting) => (notify, getState) => {
      const state = getState();
      const newHoveringID = isIntersecting ? seedID : null;

      if (newHoveringID !== state.hoveringSeedID) {
        notify();
      }

      state.hoveringSeedID = newHoveringID;
    },
    [],
  );

  useCanvasMouseEvent(
    'pointermove',
    useCallback(
      (evt, state) => {
        if (!state.seedPoints || state.seedPoints.length === 0) {
          return;
        }

        const intersectingSeed = state.seedPoints.find(seed => {
          return (
            // eslint-disable-next-line eqeqeq
            state.sliceIdx == seed.sliceIdx &&
            Math.abs(seed.projPos.x - evt.offsetX) < CIRCLE_RADIUS &&
            Math.abs(seed.projPos.y - evt.offsetY) < CIRCLE_RADIUS
          );
        });

        if (intersectingSeed) {
          setSeedIntersection(intersectingSeed.id, true);
        } else {
          setSeedIntersection(state.hoveringSeedID, false);
        }
      },
      [setSeedIntersection],
    ),
  );

  if (!sliceView) {
    return null;
  }

  return (
    <Container data-test={sliceView.isAxialView ? 'slice-view-axial' : 'slice-view-coronal'}>
      <SliceViewContainer ref={sliceViewRef}>
        {!isSliceLoaderInitialized && (
          <LoadingContainer>
            <Spinner
              animation='border'
              variant='light'
              data-test={
                sliceView.isAxialView ? 'loading-slice-view-axial' : 'loading-slice-view-coronal'
              }
            />
          </LoadingContainer>
        )}
        <SceneCanvas
          className={canvasName}
          outline={
            (isPlacingSeed && isHoveringOverCanvas) ||
            (isSelectingCenterlineStart && sliceView.isAxialView)
          }
        />
        <SliceCanvas ref={sliceCanvasRef} className='slice-canvas' isPlacingSeed={isPlacingSeed} />
        {seedPoints && seedPoints.map(seed => <SeedPoint id={seed.id} key={seed.id} />)}
        <AddSeedCrosshair />
      </SliceViewContainer>
      <SliceSlider />
      <ZoomBtnContainer>
        <ZoomBtn type='button' onClick={() => sceneDispatch(setSliceImageState(-0.1))}>
          -
        </ZoomBtn>
        <ZoomBtn type='button' onClick={() => sceneDispatch(setSliceImageState(0.1))}>
          +
        </ZoomBtn>
      </ZoomBtnContainer>
    </Container>
  );
}

const scenePropsFactory = () => ({
  sliceIdx: 0,
  isPlacingSeed: false,
  sliceImageState: {
    zoom: 1,
    sCanvas: new THREE.Vector2(),
  },
  seedPoints: [],
  isSliceViewer: true,
  isSliceLoaderInitialized: false,
  hoveringSeedID: null,
  shouldRender: false,
});
export default styled(SceneProvider(SliceViewerComponent, scenePropsFactory))`
  flex: 1 1 auto;
`;

const Container = styled.div`
  height: 100%;
  min-height: 100px;
  position: relative;
  display: flex;
`;

const LoadingContainer = styled.div`
  background: black;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const SliceViewContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const SceneCanvas = styled.canvas`
  position: absolute;
  z-index: 2;

  ${props =>
    props.outline &&
    `
    box-shadow: inset 0 0 10px 5px ${props.theme.colors.colorSeedPending};
  `}
`;

const SliceCanvas = styled.canvas`
  position: absolute;
  z-index: 1;
  background: black;

  ${props => props.isPlacingSeed && 'cursor: none;'}
`;

const ZoomBtnContainer = styled.div`
  position: absolute;
  bottom: 20px;
  width: 80px;
  left: calc(50% - 40px);
  z-index: 4;
`;

const ZoomBtn = styled.button`
  border: solid 1px #404040;
  background-color: #2d2d2d;
  color: white;
  width: 50%;
  font-size: 21px;
  font-weight: bold;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:nth-child(2) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  && {
    outline: none;
  }
`;
