import styled from 'styled-components';

const ButtonRound = styled.button`
  outline: none;
  border: none;
  background: ${props => props.theme.colors.grey50};
  color: ${props => props.theme.colors.white};
  ${props => props.theme.buttons.buttonRound}
  ${props =>
    props.disabled &&
    `
    && {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `}

  :hover {
    ${props => !props.disabled && props.theme.buttons.buttonRoundHover}
  }

  :active {
    ${props => props.theme.buttons.buttonRoundActive}
  }

  :focus {
    ${props => props.theme.buttons.buttonRoundFocus}
  }
`;

export default ButtonRound;
