import saveCenterline from './saveCenterline';

import { getSelectedSegID, getSelectedVol, getSelectedScan } from '../../../viewer/selectors';
import verifySegmentationRedux from '../../../segmentations/verifySegmentation';
import store from '../../../../store';

export default function verifySegmentation() {
  return (notify, getSceneState, callSceneAction) => {
    const { centerline, centerlineChanges } = getSceneState();
    centerlineChanges.states[centerlineChanges.idx] = centerline.serialize({
      computeCarinaRegions: true,
      includeHiddenBranches: false,
    });

    const selectedScan = getSelectedScan(store.getState().viewer, store.getState().scans)?.toJS();
    const selectedVol = getSelectedVol(store.getState().viewer, store.getState().volume3Ds)?.toJS();

    // update scan acq date if different than confirmed date
    let scanDate;
    if (selectedVol.scanDate && selectedVol.scanDate !== selectedScan.ct_scan_date) {
      scanDate = selectedVol.scanDate;
    }

    const segID = getSelectedSegID(store.getState().viewer);

    return callSceneAction(saveCenterline(segID)).then(() =>
      store.dispatch(verifySegmentationRedux(segID, scanDate)),
    );
  };
}
