import placeSeed from './placeSeed';
import { getMouse } from '../../selectors';
import { getIsPlacingSeed } from '../../../viewer/selectors';
import store from '../../../../store';

export default function handleMouseUp(evt) {
  return (notify, getSceneState, callSceneAction) => {
    const sceneState = getSceneState();
    const mouse = getMouse(sceneState);

    const isPlacingSeed = getIsPlacingSeed(store.getState().viewer);
    if (!mouse.isDragging && isPlacingSeed) {
      callSceneAction(placeSeed(evt.offsetX, evt.offsetY));
    }

    notify();
  };
}
