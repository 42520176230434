import React from 'react';
import styled from 'styled-components';

export default function ScanParameters() {
  return (
    <ScanParametersContainer>
      <RowHeading>
        <ColHeading>Required CT Parameters</ColHeading>

        <ColHeading>Recommended CT Parameters</ColHeading>
      </RowHeading>

      <RowParams>
        <ColParams>
          <ParamGroup>
            <Label>Reconstruction Slice Thickness:</Label>
            <Value>≤ 1.5 mm, preferably less</Value>
          </ParamGroup>

          <ParamGroup>
            <Label>Breathing Cycle:</Label>
            <Value>Full inspiration breath-hold</Value>
          </ParamGroup>

          <ParamGroup>
            <Label>Slice Orientation:</Label>
            <Value>Axial</Value>
          </ParamGroup>

          <ParamGroup>
            <Label>Scan Type:</Label>
            <Value>Chest</Value>
          </ParamGroup>

          <ParamGroup>
            <Label>Field of View (FOV):</Label>
            <Value>Entire lung volume</Value>
          </ParamGroup>
        </ColParams>

        <ColParams>
          <ParamGroup>
            <Label>Timeframe of Use:</Label>
            <Value>3 months from date of acquisition</Value>
          </ParamGroup>

          <ParamGroup>
            <Label>Patient Position:</Label>
            <Value>Supine, immobile, arms raised</Value>
          </ParamGroup>

          <ParamGroup>
            <Label>Scan Area:</Label>
            <Value>Entire chest including entire lung field</Value>
          </ParamGroup>

          <ParamGroup>
            <Label>Scanner Type:</Label>
            <Value>Multi-slice (16 detector or greater preferred)</Value>
          </ParamGroup>

          <ParamGroup>
            <Label>Scan Duration:</Label>
            <Value>Within breath-hold of patient</Value>
          </ParamGroup>

          <ParamGroup>
            <Label>Acquisition:</Label>
            <Value>Spiral or helical mode</Value>
          </ParamGroup>

          <ParamGroup>
            <Label>Reconstruction Filter:</Label>
            <Value>Standard filter appropriate for Scanner type</Value>
          </ParamGroup>

          <ParamGroup>
            <Label>Pitch:</Label>
            <Value>≤ 1</Value>
          </ParamGroup>

          <ParamGroup>
            <Label>Gantry Angle:</Label>
            <Value>0° (no tilt)</Value>
          </ParamGroup>

          <ParamGroup>
            <Label>Scan Strength (kVP):</Label>
            <Value>120 kVP</Value>
          </ParamGroup>

          <ParamGroup>
            <Label>Effective mAs:</Label>
            <Value>Acceptable dosage range</Value>
          </ParamGroup>

          <ParamGroup>
            <Label>Image Resolution:</Label>
            <Value>512 × 512</Value>
          </ParamGroup>
        </ColParams>
      </RowParams>
      {/* hide button */}
    </ScanParametersContainer>
  );
}

const ScanParametersContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const RowHeading = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px; // account for params scrollbar
  color: #552794;
`;

const RowParams = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  overflow-y: auto;
`;

const ColHeading = styled.div`
  font-size: 17px;
  font-weight: 500;

  width: 90%;
  padding-bottom: 6px;
  margin-bottom: 12px;
  border-bottom: 1px solid #dbdbdb;
`;

const ColParams = styled.div``;

const ParamGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 13px;
`;

const Label = styled.div`
  font-size: 15px;
  font-weight: 700;
`;

const Value = styled.div`
  font-size: 15px;
  font-weight: 400;
  margin-left: 15px;
`;
