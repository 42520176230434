export default function snapshotCenterlineState() {
  return (notify, getSceneState) => {
    const { centerlineChanges, centerline } = getSceneState();

    const centerlineData = centerline.serialize({ computeCarinaRegions: false });

    centerlineChanges.states.splice(centerlineChanges.idx + 1, Infinity, centerlineData);
    centerlineChanges.idx += 1;
    centerlineChanges.needsUpdate = true;

    notify();
  };
}
