import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form';

import ScanStatusFilter from './ScanStatusFilter';
import { getIsSearchActive } from '../../modules/scansTable/selectors';
import { SCANSEG_STATUS } from '../../settings/segmentations';

const { PENDING, SEGMENTING, ERROR, NEEDS_REVIEW, REJECTED, VERIFIED } = SCANSEG_STATUS;

export default function ScanStatusFilters() {
  const isSearchActive = useSelector(({ scansTable }) => getIsSearchActive(scansTable));

  return (
    <Form.Group>
      <Label htmlFor='scan-status-filters' id='scan-status-filters-label'>
        Filters:
      </Label>
      <FiltersContainer
        isSearchActive={isSearchActive}
        id='scan-status-filters'
        aria-labelledby='scan-status-filters-label'
      >
        <ScanStatusFilter status={NEEDS_REVIEW} />
        <ScanStatusFilter status={SEGMENTING} />
        <ScanStatusFilter status={ERROR} />
        <ScanStatusFilter status={PENDING} />
        <Divider />
        <ScanStatusFilter status={VERIFIED} />
        <ScanStatusFilter status={REJECTED} />
      </FiltersContainer>
    </Form.Group>
  );
}

const Label = styled(Form.Label)`
  color: #777777;
`;

const FiltersContainer = styled.div`
  height: 40px;
  padding-left: 20px;

  background-color: ${props => props.theme.colors.lightGrey};
  border: solid 1px ${props => props.theme.colors.lightGrey};
  border-radius: 5px;

  display: flex;
  align-items: center;

  ${({ isSearchActive }) =>
    isSearchActive &&
    css`
      opacity: 0.2;
      pointer-events: none;
    `}
`;

const Divider = styled.div`
  height: 25px;
  width: 1px;
  margin-right: 20px;
  border-right: 1px solid #a7a7a7;
`;
