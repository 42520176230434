import displaySlice from './displaySlice';

export default function setSliceIdxByPosition(pos) {
  return (notify, getSceneState, callSceneAction) => {
    const sceneState = getSceneState();
    if (!sceneState.isSliceViewer) {
      return;
    }

    const sliceIdx = sceneState.sliceView.calcSliceIdx(pos);
    callSceneAction(displaySlice(sliceIdx));
  };
}
