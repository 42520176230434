// see https://dicom.innolitics.com/ciods/ct-image/patient/00100010
// for all possible DICOM tags

export default {
  STUDY_DATE: 'x00080020',
  SERIES_DATE: 'x00080021',
  ACQUISITION_DATE: 'x00080022',

  PATIENT_NAME: 'x00100010',
  PATIENT_MRN: 'x00100020', // denoted as PATIENT_ID in DICOM header
  PATIENT_BIRTHDATE: 'x00100030',
  PATIENT_SEX: 'x00100040',

  STUDY_INSTANCE_UID: 'x0020000d',
  STUDY_ID: 'x00200010',
  SERIES_INSTANCE_UID: 'x0020000e',
  SERIES_NUMBER: 'x00200011',

  IMAGE_POSITION: 'x00200032',
  IMAGE_ORIENTATION: 'x00200037',
};
