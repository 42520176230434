import { BufferGeometry } from 'three';

export const CAM_OFFSET = 10;

const CENTROID_MARKER_PX = 5;
const CENTER_MARKER_PX = 8;

export default function updateCrossSection(branch, u, opts = {}) {
  return (notify, getSceneState) => {
    const diamData = branch.getDiamDataAt(u);
    if (!diamData.min || !diamData.max) {
      return;
    }

    const sceneState = getSceneState();

    const {
      camera,
      crossSectionLines,
      minDiamLine,
      maxDiamLine,
      centroidMarker,
      centerPointMarker,
    } = sceneState;

    if (opts.showDiams) {
      const minDiamGeo = new BufferGeometry().setFromPoints(diamData.min.points);
      const maxDiamGeo = new BufferGeometry().setFromPoints(diamData.max.points);

      // TODO: get geometry buffer attribute updating to work
      minDiamLine.geometry.dispose();
      maxDiamLine.geometry.dispose();

      minDiamLine.geometry = minDiamGeo;
      maxDiamLine.geometry = maxDiamGeo;
    }

    const minDiam = diamData.min.length;
    const maxDiam = diamData.max.length;
    const avgDiam = (minDiam + maxDiam) / 2;
    const roundness = branch.getRoundnessAt(u);

    sceneState.crossSectionData.minDiam = minDiam;
    sceneState.crossSectionData.maxDiam = maxDiam;
    sceneState.crossSectionData.avgDiam = avgDiam;
    sceneState.crossSectionData.roundness = roundness;

    const points = branch.getCrossSectionPointsAt(u);
    // add first point to end to close circle
    points.push(points[0]);

    const lineGeo = new BufferGeometry().setFromPoints(points);

    // TODO: get geometry buffer attribute updating to work
    crossSectionLines.geometry.dispose();
    crossSectionLines.geometry = lineGeo;

    const normal = branch.getDirAt(u);
    const fittedCenter = branch.getFittedCenterAt(u);
    const origCenter = branch.curve.getPointAt(u);

    centroidMarker.visible = Boolean(opts.showCentroid);
    centerPointMarker.visible = Boolean(opts.showCenterline);

    if (opts.showCentroid) {
      centroidMarker.position.copy(fittedCenter);
    }
    if (opts.showCenterline) {
      centerPointMarker.position.copy(origCenter);
    }

    camera.position
      .copy(normal)
      .multiplyScalar(-CAM_OFFSET)
      .add(fittedCenter);

    // TODO: what should camera up dir be?
    camera.lookAt(fittedCenter);

    if (opts.zoomFactor) {
      camera.zoom = 2 * (Math.min(camera.right, camera.top) / maxDiam) * opts.zoomFactor;
      camera.updateProjectionMatrix();
    }

    // assume meshes have geo with radius of 1
    const centroidScale = CENTROID_MARKER_PX / camera.zoom;
    centroidMarker.scale.set(centroidScale, centroidScale, centroidScale);

    const centerPointScale = CENTER_MARKER_PX / camera.zoom;
    centerPointMarker.scale.set(centerPointScale, centerPointScale, centerPointScale);

    sceneState.render();

    notify();
  };
}
