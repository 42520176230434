import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';

import useScan from '../../hooks/useScan';
import useSelectorToJS from '../../hooks/useSelectorToJS';

import ViewerHeader from '../viewer-page/header/ViewerHeader';
import SeedPanel from '../viewer-page/sidebar/SeedPanel';
import SliceViews from '../viewer-page/slice-viewers/SliceViews';
import AirwayViewer from '../viewer-page/airway-viewer/AirwayViewer';
import ScanInfoTable from '../viewer-page/ScanInfoTable';

import { SEG_STATUS } from '../../settings/segmentations';
import { SEG_REFRESH_INTERVAL_MS } from '../../settings/viewer';

import fetchVolume3DsByScan from '../../modules/volume3Ds/fetchVolume3DsByScan';
import fetchSegmentationsByVol from '../../modules/segmentations/fetchSegmentationsByVol';

import setSegID from '../../modules/viewer/setSegID';
import setVolID from '../../modules/viewer/setVolID';
import setScanID from '../../modules/viewer/setScanID';

import viewerReset from '../../modules/viewer/reset';
import { getSelectedSeg, getSelectedVolID } from '../../modules/viewer/selectors';

import { showNotificationError } from '../../modules/notifications/showNotification';
import { AIRWAY_VIEWER_NAME } from '../../modules/scene/selectors';
import LeftToolbar from '../viewer-page/LeftToolbar';
import { CENTERLINE_STATUS_PROCESSING } from '../../settings/centerline';
import WindowControls from '../viewer-page/slice-viewers/WindowControls';
import ScanIssuesWarning from '../viewer-page/ScanIssuesWarning';

export default function ViewerPage() {
  const { scanID } = useParams();
  const scan = useScan(scanID, true);

  const { seg, volID } = useSelectorToJS(({ segmentations, viewer }) => ({
    seg: getSelectedSeg(viewer, segmentations),
    volID: getSelectedVolID(viewer),
  }));

  const viewsContainerRef = useRef();

  const reduxDispatch = useDispatch();
  const history = useHistory();

  useEffect(
    () => () => {
      reduxDispatch(viewerReset());
    },
    [reduxDispatch],
  );

  const segStatus = seg?.status;
  const centerlineStatus = seg?.centerlineStatus;

  useEffect(() => {
    // poll segmentation when seg is running
    // or seg status is success and centerline status is processing
    if (
      segStatus !== SEG_STATUS.RUNNING &&
      (segStatus !== SEG_STATUS.SUCCESS || centerlineStatus !== CENTERLINE_STATUS_PROCESSING)
    ) {
      return;
    }

    const intervalID = setInterval(() => {
      reduxDispatch(fetchVolume3DsByScan(scanID))
        .then(vols => Promise.all(vols.map(({ id }) => reduxDispatch(fetchSegmentationsByVol(id)))))
        .catch(console.error); // eslint-disable-line no-console
    }, SEG_REFRESH_INTERVAL_MS);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalID);
  }, [reduxDispatch, scanID, segStatus, centerlineStatus]);

  useEffect(() => {
    if (!scan) {
      return;
    }

    reduxDispatch(fetchVolume3DsByScan(scanID))
      .then(vols => Promise.all(vols.map(({ id }) => reduxDispatch(fetchSegmentationsByVol(id)))))
      .then(() => {
        reduxDispatch(setSegID(scan.segID.toString()));
        reduxDispatch(setVolID(scan.volume3DID.toString()));
        reduxDispatch(setScanID(scanID));
      })
      .catch(err => {
        history.push(`/scans`);
        reduxDispatch(
          showNotificationError('An unexpected error occurred while fetching segmentations.'),
        );
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, [scanID, scan, reduxDispatch, history]);

  let segIDToFetch = seg?.id;
  // if seg hasn't completed successfully (yet), fetch files from cloned seg, if one exists
  if (
    segStatus === SEG_STATUS.CREATED ||
    segStatus === SEG_STATUS.RUNNING ||
    segStatus === SEG_STATUS.ERROR
  ) {
    segIDToFetch = seg?.clonedSeg;
  }

  if (!volID) {
    return null;
  }

  return (
    <>
      <ViewerHeader seg={seg} scan={scan} />
      <PageContainer>
        <LeftToolbar />
        <WindowControls />
        <SeedList volID={volID} />
        <ViewsContainer ref={viewsContainerRef}>
          <SliceViews seg={seg} segIDToFetch={segIDToFetch} />
          <AirwayViewer
            scan={scan}
            segIDToFetch={segIDToFetch}
            seg={seg}
            canvasName={AIRWAY_VIEWER_NAME}
          />
          <ScanInfoTable scan={scan} volID={volID} containerRef={viewsContainerRef} />
          <ScanIssuesWarning />
        </ViewsContainer>
      </PageContainer>
    </>
  );
}

const PageContainer = styled.div`
  display: flex;
  height: calc(100% - 70px);
  min-height: 700px;
  min-width: 1280px;
`;

const SeedList = styled(SeedPanel)`
  flex: 0 0 320px;
`;

const ViewsContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;
