export const SCAN_STATUS = {
  UPLOADED: 'UPLOADED',
  REJECTED: 'REJECTED',
  VERIFIED: 'VERIFIED',
};

export const SEG_STATUS = {
  CREATED: 'CREATED',
  RUNNING: 'RUNNING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  VERIFIED: 'VERIFIED',
};

export const SCANSEG_STATUS = {
  PENDING: 'SCANSEG_PENDING',
  SEGMENTING: 'SCANSEG_SEGMENTING',
  ERROR: 'SCANSEG_ERROR',
  NEEDS_REVIEW: 'SCANSEG_NEEDS_REVIEW',
  REJECTED: 'SCANSEG_REJECTED',
  VERIFIED: 'SCANSEG_VERIFIED',
};

export const SEG_BB_NAME = 'seg-bb';

export const DEFAULT_SENSITIVITY = 5;
