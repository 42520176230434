import * as types from '../actions';
import { getVolume3DByScanURL } from '../../settings/api';
import { apiFetch } from '../../utils/requests';
import { getToken } from '../auth/selectors';

export function addScanVolume3Ds(volume3Ds) {
  return {
    type: types.ADD_VOLUME_3DS_BY_SCAN,
    volume3Ds,
  };
}

export default function fetchVolsByScan(scanID) {
  return (dispatch, getState) => {
    const token = getToken(getState().auth);

    return apiFetch(getVolume3DByScanURL(scanID), token, 'GET').then(vols => {
      dispatch(addScanVolume3Ds(vols));
      return vols;
    });
  };
}
