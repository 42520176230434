import colors from './colors';
import fonts from './fonts';

export default {
  buttonDefault: {
    height: '40px',
    'border-radius': '4px',
    'font-family': fonts.fontFamilyDefault,
    'font-size': '14px',
    'font-weight': '500',
    color: colors.white,
  },
  buttonDefaultHover: {
    'box-shadow': `0 3px 14px 2px ${colors.colorShadowHoverLight}, 0 8px 10px 1px ${colors.colorShadowHoverMedium}, 0 5px 5px -3px ${colors.colorShadowHoverDark}`,
  },
  buttonDefaultActive: {
    'box-shadow': `0 2px 4px -1px ${colors.colorShadowActiveDark}, 0 1px 10px 0 ${colors.colorShadowActiveLight}, 0 4px 5px 0 ${colors.colorShadowActiveMedium}`,
  },
  // FIXME: figure out how to generalize double (inner & outer) outlines for 'focused' state
  buttonDefaultFocus: {
    'box-shadow': `0 2px 4px -1px ${colors.colorShadowActiveDark}, 0 1px 10px 0 ${colors.colorShadowActiveLight}, 0 4px 5px 0 ${colors.colorShadowActiveMedium}`,
    border: `solid 1px ${colors.white}`,
  },
  buttonDefaultDisabled: {
    'background-color': `${colors.grey40}`,
  },

  buttonRound: {
    height: '35px',
    'border-radius': '17.5px',
    'font-family': fonts.fontFamilyDefault,
    'font-size': '14px',
    'font-weight': '500',
    color: colors.white,
  },
  buttonRoundHover: {
    'box-shadow': `0 3px 14px 2px ${colors.colorShadowHoverLight}, 0 8px 10px 1px ${colors.colorShadowHoverMedium}, 0 5px 5px -3px ${colors.colorShadowHoverDark}`,
  },
  buttonRoundActive: {
    'box-shadow': `0 2px 4px -1px ${colors.colorShadowActiveDark}, 0 1px 10px 0 ${colors.colorShadowActiveLight}, 0 4px 5px 0 ${colors.colorShadowActiveMedium}`,
  },
  // FIXME: figure out how to generalize double (inner & outer) outlines for 'focused' state
  buttonRoundFocus: {
    'box-shadow': `0 2px 4px -1px ${colors.colorShadowActiveDark}, 0 1px 10px 0 ${colors.colorShadowActiveLight}, 0 4px 5px 0 ${colors.colorShadowActiveMedium}`,
    border: `solid 1px ${colors.white}`,
    outline: 'none !important',
  },
  buttonRoundDisabled: {
    'background-color': `${colors.grey40}`,
  },
};
