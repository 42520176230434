import React from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField as MuiTextField } from 'formik-material-ui';
import { MdCheckCircleOutline, MdHelpOutline, MdHighlightOff } from 'react-icons/md';
import styled, { css } from 'styled-components';

import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import MuiTooltip from '@material-ui/core/Tooltip';

import {
  formatPatientName,
  dateToISO8601DateString,
  dicomDateStringToDate,
  spellOutPatientSex,
} from '../../../utils/dicom/dicom';

import ScanUploadDialog from './ScanUploadDialog';
import * as S from './ScanUploadDialog.styled';
import { DateMask } from '../../MaskedInput';

export default function ConfirmScanInfoForm({
  originalScanInfo,
  parsedScanInfo,
  onSubmit,
  onCancel,
}) {
  const patientNameMismatched =
    parsedScanInfo.patientName &&
    formatPatientName(parsedScanInfo.patientName) !== originalScanInfo.patientName;

  const patientMRNMismatched =
    parsedScanInfo.patientMRN && parsedScanInfo.patientMRN !== originalScanInfo.patientMRN;

  const patientDOBMismatched =
    parsedScanInfo.patientDOB &&
    parsedScanInfo.patientDOB?.getTime() !== new Date(originalScanInfo.patientDOB).getTime();

  const patientSexMismatched =
    parsedScanInfo.patientSex && parsedScanInfo.patientSex !== originalScanInfo.patientSex;

  const scanDateMismatched =
    parsedScanInfo.scanDate &&
    parsedScanInfo.scanDate?.getTime() !==
      dicomDateStringToDate(originalScanInfo.scanDate)?.getTime();

  const scanInfoMismatch =
    patientNameMismatched ||
    patientMRNMismatched ||
    patientDOBMismatched ||
    patientSexMismatched ||
    scanDateMismatched;

  const handleSubmit = values => {
    const confirmedScanInfo = {
      patientName: values.patientName,
      patientMRN: values.patientMRN,
      patientDOB: values.patientDOB,
      patientSex: values.patientSex,
      scanDate: values.scanDate,
      overwriteReason: values.overwriteReason,
      overwriteFix: values.overwriteFix,
    };

    onSubmit(confirmedScanInfo);
  };

  return (
    <Formik
      // - if value found in DICOM, display it
      // - if value NOT found in DICOM (anonymized), display info confirmed by Doctor originally
      validateOnMount
      initialValues={{
        patientName: parsedScanInfo.patientName || originalScanInfo.patientName,
        patientMRN: parsedScanInfo.patientMRN || originalScanInfo.patientMRN,
        patientDOB:
          dateToISO8601DateString(parsedScanInfo.patientDOB) ||
          dateToISO8601DateString(new Date(originalScanInfo.patientDOB)),
        patientSex: parsedScanInfo.patientSex || originalScanInfo.patientSex,
        scanDate:
          dateToISO8601DateString(parsedScanInfo.scanDate) ||
          dateToISO8601DateString(dicomDateStringToDate(originalScanInfo.scanDate)),
        overwriteReason: '',
        overwriteFix: '',
      }}
      initialTouched={{
        patientName: true,
        patientMRN: true,
        patientDOB: true,
        patientSex: true,
        scanDate: true,
        overwriteReason: false,
        overwriteFix: false,
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
          <DialogContent>
            <S.Instructions>
              <span style={{ fontWeight: '600' }}>
                To enable overwrite, the patient info parsed from this CT scan must match the info
                that was originally confirmed by the Physician.
              </span>
              <br />
              <br />
              Any information that mismatches what was originally confirmed by the Physician is
              highlighted below.
            </S.Instructions>
            <FormBody>
              <FormRow>
                <div style={{ display: 'flex', gap: '30px' }}>
                  <Field name='patientName'>
                    {props => (
                      <TextFieldWithTooltip
                        {...props}
                        label='Patient Name'
                        isAnonymized={!parsedScanInfo.patientName}
                        isMismatched={patientNameMismatched}
                        originalVal={originalScanInfo.patientName}
                        flexGrow
                        variant='outlined'
                        id='scanInfoConfirm-patientName'
                        data-test='confirm-scan-name'
                        style={{ flexGrow: '1' }}
                      />
                    )}
                  </Field>

                  <Field name='patientMRN'>
                    {props => (
                      <TextFieldWithTooltip
                        {...props}
                        label='MRN'
                        isAnonymized={!parsedScanInfo.patientMRN}
                        isMismatched={patientMRNMismatched}
                        originalVal={originalScanInfo.patientMRN}
                        variant='outlined'
                        id='scanInfoConfirm-patientMRN'
                        data-test='confirm-scan-mrn'
                        style={{ width: '325px' }}
                      />
                    )}
                  </Field>
                </div>
              </FormRow>

              <FormRow>
                <div style={{ display: 'flex', gap: '30px' }}>
                  <Field name='patientDOB'>
                    {props => (
                      <TextFieldWithTooltip
                        {...props}
                        label='Date of Birth'
                        isAnonymized={!parsedScanInfo.patientDOB}
                        isMismatched={patientDOBMismatched}
                        originalVal={originalScanInfo.patientDOB?.split('T')[0]}
                        InputProps={{
                          inputComponent: DateMask,
                        }}
                        variant='outlined'
                        id='scanInfoConfirm-patientDOB'
                        data-test='confirm-scan-dob'
                        style={{ width: '250px' }}
                      />
                    )}
                  </Field>

                  <Field name='patientSex'>
                    {props => (
                      <TextFieldWithTooltip
                        {...props}
                        select
                        label='Sex'
                        isAnonymized={!parsedScanInfo.patientSex}
                        isMismatched={patientSexMismatched}
                        originalVal={spellOutPatientSex(originalScanInfo.patientSex)}
                        SelectProps={{ IconComponent: () => null }}
                        variant='outlined'
                        id='scanInfoConfirm-patientSex'
                        data-test='confirm-scan-sex'
                        style={{ width: '175px' }}
                      >
                        <MenuItem hidden value={-1}>
                          Select one
                        </MenuItem>
                        <MenuItem value='M'>Male</MenuItem>
                        <MenuItem value='F'>Female</MenuItem>
                        <MenuItem value='O'>Other</MenuItem>
                      </TextFieldWithTooltip>
                    )}
                  </Field>

                  <Field name='scanDate'>
                    {props => (
                      <TextFieldWithTooltip
                        {...props}
                        label='Scan Date of Acquisition'
                        isAnonymized={!parsedScanInfo.scanDate}
                        isMismatched={scanDateMismatched}
                        originalVal={dateToISO8601DateString(
                          dicomDateStringToDate(originalScanInfo.scanDate),
                        )}
                        InputProps={{
                          inputComponent: DateMask,
                        }}
                        flexGrow
                        variant='outlined'
                        id='scanInfoConfirm-scanDate'
                        data-test='confirm-scan-date'
                        style={{ flexGrow: '1' }}
                      />
                    )}
                  </Field>
                </div>
              </FormRow>

              {!scanInfoMismatch && (
                <FormRow>
                  <div style={{ display: 'flex', paddingBottom: '35px' }}>
                    <Field name='overwriteReason'>
                      {props => (
                        <TextArea
                          {...props}
                          multiline
                          rows='2'
                          label='Reason for Overwriting'
                          variant='outlined'
                          id='scanInfoConfirm-overwriteReason'
                          data-test='scanInfoConfirm-overwriteReason'
                          style={{ flexGrow: '1' }}
                        />
                      )}
                    </Field>
                  </div>
                </FormRow>
              )}

              {!scanInfoMismatch && (
                <FormRow>
                  <div style={{ display: 'flex' }}>
                    <Field name='overwriteFix'>
                      {props => (
                        <TextArea
                          {...props}
                          multiline
                          rows='2'
                          label='Fix Applied'
                          variant='outlined'
                          id='scanInfoConfirm-overwriteFix'
                          data-test='scanInfoConfirm-overwriteFix'
                          style={{ flexGrow: '1' }}
                        />
                      )}
                    </Field>
                  </div>
                </FormRow>
              )}
            </FormBody>
          </DialogContent>

          <ScanUploadDialog.ActionButtons
            ScanParametersProps={{
              showBtn: false,
            }}
          >
            <div>
              <S.SecondaryButton
                onClick={onCancel}
                variant='outlined'
                style={{ marginRight: '40px', padding: '0 35px' }}
                data-test='confirm-scan-cancel-button'
              >
                No, don&apos;t upload
              </S.SecondaryButton>

              <Tooltip
                title={scanInfoMismatch ? 'Parsed info mismatches confirmed info' : ''}
                placement='top'
                arrow
                id='scanUpload-continueTooltip'
              >
                <span
                  style={{
                    display: 'inline-block',
                    cursor: isSubmitting || scanInfoMismatch ? 'not-allowed' : 'pointer',
                  }}
                >
                  <S.PrimaryButton
                    type='submit'
                    disabled={isSubmitting || scanInfoMismatch}
                    data-test='confirm-scan-save-button'
                  >
                    Yes, upload scan
                  </S.PrimaryButton>
                </span>
              </Tooltip>
            </div>
          </ScanUploadDialog.ActionButtons>
        </Form>
      )}
    </Formik>
  );
}

function TextFieldWithTooltip({
  isAnonymized,
  isMismatched,
  originalVal,
  flexGrow = false,
  ...props
}) {
  const fieldName = props.field.name; // eslint-disable-line react/destructuring-assignment

  let tooltipMsg = 'Matches value confirmed by Physician';
  let icon = (
    <MdCheckCircleOutline
      color='#47d93f'
      size={24}
      data-test={`patientInfoIcon-${fieldName}-matched`}
    />
  );

  if (isMismatched) {
    tooltipMsg = (
      <>
        Mismatches value confirmed by Physician
        <br />
        <strong style={{ color: '#ff0000' }}>{originalVal}</strong>
      </>
    );
    icon = (
      <MdHighlightOff
        color='#ff0000'
        size={24}
        data-test={`patientInfoIcon-${fieldName}-mismatched`}
      />
    );
  }
  if (isAnonymized) {
    tooltipMsg = (
      <>
        Value parsed from CT is anonymized.
        <br />
        Showing original value confirmed by Physician.
      </>
    );
    icon = (
      <MdHelpOutline
        color='#ff8c00'
        size={24}
        data-test={`patientInfoIcon-${fieldName}-anonymized`}
      />
    );
  }

  const inputProps = {
    // eslint-disable-next-line react/destructuring-assignment
    ...(props.InputProps || {}),
    endAdornment: <InputAdornment position='end'>{icon}</InputAdornment>,
  };

  const tooltipTitle = <div style={{ textAlign: 'center' }}>{tooltipMsg}</div>;

  return (
    <Tooltip title={tooltipTitle} placement='top' arrow>
      <FieldWrapper $disabled $flexGrow={flexGrow}>
        <TextField {...props} InputProps={inputProps} disabled />
      </FieldWrapper>
    </Tooltip>
  );
}

const FieldWrapper = styled.span`
  display: inline-block;
  ${props =>
    props.$disabled &&
    css`
      .MuiInputBase-root.Mui-disabled {
        cursor: not-allowed;
      }

      .MuiInputBase-input {
        cursor: not-allowed;
      }
    `}

  ${props =>
    props.$flexGrow &&
    css`
      flex-grow: 1;
    `}
`;

const DialogContent = styled(ScanUploadDialog.Content)`
  &.MuiDialogContent-root:first-child {
    padding-top: 0;
  }
`;

const FormBody = styled.div`
  padding-top: 10px;

  .MuiOutlinedInput-root {
    height: 50px;
    width: 100%;

    font-weight: 600;
    font-size: 16px;

    border-radius: 10px;

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.23);
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #552794 !important;
    }

    &.Mui-error:hover .MuiOutlinedInput-notchedOutline {
      border-color: #ff0000;
    }

    &.Mui-disabled {
      color: black;
    }

    .MuiInputBase-input.MuiInputBase-input {
      &::placeholder {
        font-size: 14px;
      }
    }
  }

  .MuiFormLabel-root {
    font-size: 14px;
    font-weight: 500;
    top: -1px;

    &.Mui-focused {
      font-size: 16px;
      color: #552794;
    }

    &.MuiFormLabel-root.Mui-disabled {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.54);
    }

    &.MuiInputLabel-shrink {
      font-size: 16px;
    }
  }

  .MuiSelect-select:focus {
    background-color: #ffffff;
  }
`;

const FormRow = styled.div`
  min-height: 85px; // add space for validation error text
`;

const TextField = styled(MuiTextField)`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

const Tooltip = styled(props => <MuiTooltip classes={{ popper: props.className }} {...props} />)`
  ${S.TooltipCSS}
  display: flex;
`;

const TextArea = styled(TextField)`
  .MuiInputBase-root {
    height: 70px;
  }
`;
