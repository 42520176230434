import { Map, fromJS } from 'immutable';

import * as types from '../actions';

const INITIAL_STATE = Map({ byId: Map() });

function loadSliceViews(state, sliceViews) {
  const idState = state.get('byId').withMutations(mutState => {
    sliceViews.forEach(view => {
      mutState.set(view.id, fromJS(view));
    });
  });

  return state.set('byId', idState);
}

export default function sliceViewsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.AUTH_LOGOUT:
      return INITIAL_STATE;
    case types.ADD_SLICE_VIEWS:
      return loadSliceViews(state, action.sliceViews);
    default:
      return state;
  }
}
