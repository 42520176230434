import { getAxialSlicePlane, getCoronalSlicePlane } from '../../selectors';

export default function updateSlicePlane(sliceView, sliceIdx) {
  return (notify, getSceneState) => {
    const sceneState = getSceneState();

    const slicePlane = sliceView.isAxialView
      ? getAxialSlicePlane(sceneState)
      : getCoronalSlicePlane(sceneState);
    if (!slicePlane) {
      return;
    }

    slicePlane.position
      .copy(sliceView.orientation)
      .multiplyScalar(sliceView.calcSlicePosition(sliceIdx));
  };
}
