import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DropdownButton as DropdownButtonBs, Dropdown, OverlayTrigger } from 'react-bootstrap';
import { format, parse } from 'date-fns';
import { FaUserCheck, FaUserTimes, FaRegQuestionCircle } from 'react-icons/fa';
import { FaRegCircleXmark } from 'react-icons/fa6';
import { MdCheckCircleOutline } from 'react-icons/md';

import Tooltip from '../../Tooltip.styled';

import { formatDate } from '../../../utils/general';
import {
  dicomDateStringToDate,
  isScanExpired,
  matchesConfirmedPatientInfo,
  isAnonymized,
} from '../../../utils/dicom/dicom';

import { getSelectedVolID, getSelectedSegID } from '../../../modules/viewer/selectors';
import { getVolsByScanID } from '../../../modules/volume3Ds/selectors';
import { getSegsByVolID } from '../../../modules/segmentations/selectors';
import setSegID from '../../../modules/viewer/setSegID';
import setVolID from '../../../modules/viewer/setVolID';

import { ReactComponent as FaUserQuestion } from '../../../images/faUserQuestion.svg';

export default function SeriesSegSelect({ scan, className }) {
  const { scanID } = useParams();

  const dispatch = useDispatch();

  const reduxProps = useSelector(({ volume3Ds, segmentations, viewer }) => ({
    volume3Ds,
    segmentations,
    selectedVolID: getSelectedVolID(viewer),
    selectedSegID: getSelectedSegID(viewer),
  }));

  if (!reduxProps.selectedVolID) {
    return null;
  }

  const allScanVols = getVolsByScanID(reduxProps.volume3Ds, scanID)?.toJS();

  // don't show nonviable series
  const viableScanVols = allScanVols.filter(v => v.isViable);
  if (!viableScanVols.length) {
    return <S.NoViableSeries>No Viable Series</S.NoViableSeries>;
  }

  const volSegMap = {};
  viableScanVols.forEach(({ id }) => {
    volSegMap[id] = getSegsByVolID(reduxProps.segmentations, id)
      ?.toJS()
      .sort((a, b) =>
        Number(a.segAlphaNum.split('-')[1]) > Number(b.segAlphaNum.split('-')[1]) ? -1 : 1,
      );
  });

  const selectedVol = viableScanVols.find(({ id }) => id === reduxProps.selectedVolID);

  const handleSegSelect = eventKey => {
    const [volID, segID] = eventKey.split('-');
    dispatch(setSegID(segID));
    dispatch(setVolID(volID));
  };

  return (
    <>
      <S.SelectSeriesBtn
        drop='right'
        title={`Series ${selectedVol.seriesAlpha}`}
        className={className}
        data-test='series-dropdown-toggle'
      >
        {viableScanVols.map(vol => {
          return (
            <SeriesItem scan={scan} vol={vol} key={vol.id}>
              {volSegMap[vol.id].map(seg => (
                <S.SegItem
                  key={seg.id}
                  eventKey={`${seg.volume3DID}-${seg.id}`}
                  onSelect={handleSegSelect}
                >
                  {seg.segAlphaNum}
                  <S.SegDate>{formatDate(seg.completedAt, 'yyyy-MM-dd HH:mm')}</S.SegDate>
                </S.SegItem>
              ))}
            </SeriesItem>
          );
        })}
      </S.SelectSeriesBtn>
    </>
  );
}

function SeriesItem({ scan, vol, ...rest }) {
  const scanDate = dicomDateStringToDate(vol.scanDate);
  const isExpired = isScanExpired(scanDate);

  const seriesDateText = vol.scanDate
    ? `Acq: ${format(parse(vol.scanDate, 'yyyyMMdd', new Date()), 'yyyy-MM-dd')}`
    : '';

  const title = (
    <S.SeriesItemTitle>
      <span className='seriesAlpha'>Series {vol.seriesAlpha}</span>

      {isExpired ? (
        <OverlayTrigger
          placement='top'
          overlay={<Tooltip id='tooltip-seriesExpired'>Expired</Tooltip>}
        >
          <S.SeriesDate isExpired={isExpired}>{seriesDateText}</S.SeriesDate>
        </OverlayTrigger>
      ) : (
        <S.SeriesDate isExpired={isExpired}>{seriesDateText}</S.SeriesDate>
      )}

      <PatientInfoIcon scan={scan} vol={vol} />
    </S.SeriesItemTitle>
  );

  return <S.SeriesItem key={vol.id} drop='right' title={title} {...rest} />;
}

function PatientInfoIcon({ scan, vol }) {
  if (matchesConfirmedPatientInfo(vol, scan)) {
    return (
      <OverlayTrigger
        placement='right'
        overlay={
          <Tooltip id='tooltip-patientInfoConfirmed'>
            <MdCheckCircleOutline color='#00ff00' size={18} />
            Confirmed Patient Info
          </Tooltip>
        }
      >
        <span>
          <FaUserCheck
            color='#00ff00'
            size={20}
            data-test={`patientInfoIcon-series${vol.seriesAlpha}-confirmed`}
          />
        </span>
      </OverlayTrigger>
    );
  }

  if (isAnonymized(vol)) {
    return (
      <OverlayTrigger
        placement='right'
        overlay={
          <Tooltip id='tooltip-patientInfoAnonymized'>
            <FaRegQuestionCircle color='#ffff00' size={18} />
            Anonymized Patient Info
          </Tooltip>
        }
      >
        <span>
          <S.FaUserQuestionIcon data-test={`patientInfoIcon-series${vol.seriesAlpha}-anonymized`} />
        </span>
      </OverlayTrigger>
    );
  }

  return (
    <OverlayTrigger
      placement='right'
      overlay={
        <Tooltip id='tooltip-patientInfoUnconfirmed'>
          <FaRegCircleXmark color='#ff0000' size={18} />
          Unconfirmed Patient Info
        </Tooltip>
      }
    >
      <span>
        <FaUserTimes
          color='#ff0000'
          size={20}
          data-test={`patientInfoIcon-series${vol.seriesAlpha}-unconfirmed`}
        />
      </span>
    </OverlayTrigger>
  );
}

const S = {};

S.NoViableSeries = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex: 1 1 50px;
  height: 40px;

  margin-left: 7.5px;

  font-size: 14px;
  font-weight: 600;
  color: #ffffff;

  background: #8a0505;
  border: 1px solid #494949;
  border-radius: 6px;

  cursor: default;
`;

S.SegDate = styled.span`
  font-size: 13px;
  font-weight: normal;
`;

S.SelectSeriesBtn = styled(DropdownButtonBs)`
  .dropdown-menu.show {
    background: #1d1d1d;
    border: solid 1px #4e4e4e;
    border-radius: 6px;
    padding: 10px;
    left: 6px !important;
    top: -11px !important;
    z-index: 2000;

    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  > button.dropdown-toggle {
    width: 100%;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background: #1d1d1d !important;
    border: 1px solid #494949;
    border-radius: 6px;
    font-size: 14px;
    font-weight: bold;
    padding: 0 20px;

    :hover {
      background: #393939 !important;
      border: 1px solid #494949;
    }

    :active {
      background: #393939 !important;
      border: 2px solid #0077ff;
    }
  }
`;

S.SeriesItem = styled(DropdownButtonBs)`
  .dropdown-menu.show {
    left: 18px !important;
    top: -11px !important;
    max-height: 570px;
    overflow: auto;
  }

  > button.dropdown-toggle {
    width: 300px;
    height: 40px;

    padding: 0 12px;

    background: #1d1d1d !important;
    border: none;
    transition: none;

    :hover,
    :active,
    :focus {
      background: #393939 !important;
      box-shadow: none !important;
      border: none !important;
    }

    &::after {
      content: none;
    }
  }
`;

S.SeriesItemTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-weight: 400;

  .seriesAlpha {
    font-weight: 600;
    font-size: 15px;
  }
`;

S.SeriesDate = styled.span`
  ${props => props.isExpired && 'color: #ff0000;'}
`;

S.FaUserQuestionIcon = styled(FaUserQuestion)`
  width: 20px;
  height: 20px;

  path {
    fill: #ffff00;
  }
`;

S.SegItem = styled(Dropdown.Item)`
  color: white;
  border-radius: 4px;
  padding: 0 12px;
  height: 40px;
  width: 240px;

  font-size: 15px;
  font-weight: 600;

  display: flex;
  justify-content: space-between;
  align-items: center;

  :hover {
    background: #393939;
    color: white;
  }
`;
