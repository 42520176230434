/* eslint max-classes-per-file: "off" */

export const errors = {
  NOT_A_DICOM: 'DICM prefix not found',
  INVALID_DICOM_INFO: 'DICOM does not contain valid info',
  NUMBER_SLICES: 'Invalid number of slices',
  FILE_TOO_LARGE: 'File too large',
  NO_VIABLE_SERIES: 'No viable series found',
};

export class CorruptZipError extends Error {
  constructor() {
    const message = 'Zip file is corrupt';
    super(message);

    this.name = 'CorruptZipError';
  }

  static get className() {
    return 'CorruptZipError';
  }
}

export class InvalidDICOMError extends Error {
  constructor(message) {
    super(message);
    this.message = message;
    this.name = 'InvalidDICOMError';
  }

  static get className() {
    return 'InvalidDICOMError';
  }
}

export class NoDICOMFoundError extends Error {
  constructor(jpegsFound = false) {
    const message = 'No DICOM was found';
    super(message);

    this.name = 'NoDICOMFoundError';
    this.jpegsFound = jpegsFound;
  }

  static get className() {
    return 'NoDICOMFoundError';
  }
}

export class SliceThicknessError extends Error {
  constructor(dicomInfo) {
    const message = `Invalid slice thickness: ${dicomInfo?.sliceThickness || 'unknown'} mm`;
    super(message);

    this.name = 'SliceThicknessError';
    this.dicomInfo = dicomInfo;
  }

  static get className() {
    return 'SliceThicknessError';
  }
}

export class NumberSlicesError extends Error {
  constructor(dicomInfo) {
    super(errors.NUMBER_SLICES);

    this.name = 'NumberSlicesError';
    this.dicomInfo = dicomInfo;
  }

  static get className() {
    return 'NumberSlicesError';
  }
}

export class FileTooLargeError extends Error {
  constructor() {
    super(errors.FILE_TOO_LARGE);

    this.name = 'FileTooLargeError';
  }

  static get className() {
    return 'FileTooLargeError';
  }
}

export class NoViableSeriesError extends Error {
  constructor(nonViableSeries) {
    super(errors.NO_VIABLE_SERIES);

    this.name = 'NoViableSeriesError';
    this.nonViableSeries = nonViableSeries;
  }

  static get className() {
    return 'NoViableSeriesError';
  }
}
