import { Map, List, fromJS } from 'immutable';

import * as types from '../actions';
import { getSegsByVolID } from './selectors';

const INITIAL_STATE = Map({ byId: Map() });

function loadSegmentations(state, segs) {
  const idState = state.get('byId').withMutations(mutState => {
    segs.forEach(seg => {
      mutState.set(seg.id, fromJS(seg));
    });
  });

  return state.set('byId', idState);
}

function addVolSegmentations(state, volSegs, seriesAlpha) {
  if (!seriesAlpha) {
    throw new Error('Error computing segAlphaNum - seriesAlpha must be defined');
  }
  const { volume3DID } = volSegs[0];
  if (volSegs.some(seg => seg.volume3DID !== volume3DID)) {
    throw new Error('Error computing segAlphaNum - Attempted to load segs from different volume3D');
  }

  const sortedSegs = volSegs
    .sort((a, b) => a.id - b.id)
    .map((seg, i) => ({ ...seg, segAlphaNum: `${seriesAlpha}-${i + 1}` }));

  return loadSegmentations(state, sortedSegs);
}

function addNewSegmentation(state, seg) {
  const volSegs = getSegsByVolID(state, seg.volume3DID);

  if (state.getIn(['byId', seg.id])) {
    const segAlphaNum = state.getIn(['byId', seg.id, 'segAlphaNum']);
    return state.setIn(['byId', seg.id], fromJS({ ...seg, segAlphaNum }));
  }

  const [seriesAlpha, segNum] = volSegs
    .reduce((latestAlphaNum, testSeg) => {
      const testAlphaNum = testSeg.get('segAlphaNum');

      return testAlphaNum.length >= latestAlphaNum.length && testAlphaNum > latestAlphaNum
        ? testAlphaNum
        : latestAlphaNum;
    }, '')
    .split('-');

  return state.setIn(
    ['byId', seg.id],
    fromJS({
      ...seg,
      segAlphaNum: `${seriesAlpha}-${Number(segNum) + 1}`,
    }),
  );
}

function addSeed(state, seed) {
  const seeds = state.getIn(['byId', seed.segmentationID, 'seedPoints'], List()).push(fromJS(seed));

  return state.setIn(['byId', seed.segmentationID, 'seedPoints'], seeds);
}

function removeSeed(state, seed) {
  const seeds = state
    .getIn(['byId', seed.segmentationID, 'seedPoints'])
    .filter(s => s.get('id') !== seed.id);
  return state.setIn(['byId', seed.segmentationID, 'seedPoints'], seeds);
}

function updateSensitivity(state, segID, sensitivity) {
  return state.setIn(['byId', segID, 'sensitivity'], sensitivity);
}

export default function segmentationsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.AUTH_LOGOUT:
      return INITIAL_STATE;
    case types.ADD_SEGMENTATIONS:
      return loadSegmentations(state, action.segmentations);
    case types.ADD_SEGMENTATIONS_BY_VOLUME_3D:
      return addVolSegmentations(state, action.segmentations, action.seriesAlpha);
    case types.ADD_NEW_SEGMENTATION:
      return addNewSegmentation(state, action.segmentation);
    case types.ADD_SEED:
      return addSeed(state, action.seed);
    case types.REMOVE_SEED:
      return removeSeed(state, action.seed);
    case types.UPDATE_SENSITIVITY:
      return updateSensitivity(state, action.segID, action.sensitivity);
    default:
      return state;
  }
}
