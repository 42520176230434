export function stdDev(values) {
  const avg = mean(values);

  const sqrDiffs = values.map(val => {
    const diff = val - avg;
    const sqrDiff = diff * diff;
    return sqrDiff;
  });

  const avgSqrDiff = mean(sqrDiffs);
  const stdDeviation = Math.sqrt(avgSqrDiff);

  return stdDeviation;
}

export function mean(values) {
  const sum = values.reduce((total, val) => total + val, 0);
  const avg = sum / values.length;

  return avg;
}

export function median(array, sortFn) {
  const sorted = [...array].sort(sortFn);
  const middleIndex = (sorted.length + 1) / 2;
  const isEven = sorted.length % 2 === 0;

  return isEven
    ? (sorted[middleIndex - 1.5] + sorted[middleIndex - 0.5]) / 2 // average of two middle els
    : sorted[middleIndex - 1]; // middle element
}
