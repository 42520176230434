import * as types from '../actions/auth';

export function authSetConnectedAction(isConnected) {
  return {
    type: types.AUTH_SET_CONNECTED,
    payload: {
      isConnected,
    },
  };
}

export default function authSetConnected(isConnected) {
  return dispatch => {
    dispatch(authSetConnectedAction(isConnected));
  };
}
