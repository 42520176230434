import React from 'react';
import styled from 'styled-components';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import checkmarkIcon from '../../../images/scan-overwrite-modal/checkmark.svg';
import { ReactComponent as UploadingIcon } from '../../../images/scan-overwrite-modal/ct-upload-uploading-icon.svg';

import ScanUploadDialog from './ScanUploadDialog';

export default function StepUploadingScan({ uploadPercent, uploadComplete, onClose }) {
  const renderProgress = () => {
    if (uploadComplete) {
      return (
        <LoadingContainer>
          &nbsp;
          <CirProgressWithLabel value={100} />
          CT Overwritten Successfully
        </LoadingContainer>
      );
    }

    if (uploadPercent === 100) {
      return (
        <LoadingContainer>
          Processing CT Scan
          <CirProgress data-test='scanUpload-progressCircleIndeterminate' />
          This may take a few moments...
        </LoadingContainer>
      );
    }

    return (
      <LoadingContainer>
        <CirProgressWithLabel value={uploadPercent} />
        Uploading CT Scan...
      </LoadingContainer>
    );
  };

  return (
    <>
      <ScanUploadDialog.Title
        heading='Uploading CT Scan'
        icon={<UploadingIcon />}
        showCloseBtn={uploadComplete}
        onClose={onClose}
      />

      <ScanUploadDialog.Content>
        <>{renderProgress()}</>
      </ScanUploadDialog.Content>
    </>
  );
}

const LoadingContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: #5d1c9a;
  margin-bottom: 80px;
`;

const CirProgressWithLabel = styled(CircularProgressWithLabel)`
  &.MuiCircularProgress-colorPrimary {
    color: #5d1c9a;
  }

  margin: 30px;
`;

const ProgressText = styled(Typography)`
  &.MuiTypography-colorTextSecondary {
    font-size: 20px;
    font-weight: 600;
    color: #5d1c9a;
  }
`;

function CirProgress(props) {
  return <CP size={100} thickness={2.5} {...props} />;
}

const CP = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: #5d1c9a;
  }

  margin: 30px;
`;

function CircularProgressWithLabel({ value }) {
  return (
    <Box position='relative' display='inline-flex'>
      <CirProgress
        variant='determinate'
        value={value}
        data-test='scanUpload-progressCircleDeterminate'
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <ProgressText variant='caption' component='div' color='textSecondary'>
          {value < 100 ? `${value}%` : <CheckMark src={checkmarkIcon} />}
        </ProgressText>
      </Box>
    </Box>
  );
}

const CheckMark = styled.img`
  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 100;
    }
  }

  animation-name: fade;
  animation-duration: 1s;
`;
