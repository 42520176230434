import updateSlicePlane from '../airwayView/updateSlicePlane';

import { AIRWAY_VIEWER_NAME } from '../../selectors';

export default function displaySlice(sliceIdx, scrollDir) {
  return (notify, getSceneState, callSceneAction) => {
    const sceneState = getSceneState();

    if (!sceneState.sliceView) {
      return Promise.resolve();
    }

    let newSliceIdx = sliceIdx;
    if (newSliceIdx === undefined || newSliceIdx === null) {
      newSliceIdx = sceneState.sliceIdx + (scrollDir > 0 ? 1 : -1);
    }

    newSliceIdx = Math.max(0, Math.min(sceneState.sliceView.numSlices - 1, newSliceIdx));

    if (newSliceIdx === sceneState.sliceIdx) {
      return Promise.resolve();
    }

    callSceneAction(updateSlicePlane(sceneState.sliceView, newSliceIdx), AIRWAY_VIEWER_NAME);

    return sceneState.sliceLoader.displaySlice(newSliceIdx, localStorage.getItem('token'));
  };
}
