export default `
uniform vec3 boundingBoxMin;
uniform vec3 boundingBoxMax;
uniform float sliceIdx;
uniform float numSlices;
uniform float sliceThickness;
uniform vec3 scanDirection;
uniform vec2 pixelSize;
uniform vec2 viewSize;
uniform vec2 canvasSize;
uniform vec2 sliceSize;

void main() {
  float p1 = dot(scanDirection, boundingBoxMin) + (0.5 * sliceThickness);
  float p2 = dot(scanDirection, boundingBoxMax) - (0.5 * sliceThickness);

  float minPt = min(p1, p2);
  float maxPt = max(p1, p2);

  float boundingBoxDiff = maxPt - minPt;

  float threshold = 0.5 * (boundingBoxDiff / numSlices);

  float sliceMin = ((boundingBoxDiff * sliceIdx) / (numSlices - 1.0)) + minPt - threshold;
  float sliceMax = ((boundingBoxDiff * sliceIdx) / (numSlices - 1.0)) + minPt + threshold;

  vec4 worldPosition = modelMatrix * vec4(position, 1.0);
  float vertexPoint = dot(scanDirection, vec3(worldPosition));

  if (vertexPoint >= sliceMin && vertexPoint <= sliceMax) {
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  } else {
    // move outside of camera view
    gl_Position = vec4(1000000, 1000000, 1000000, 1.0);
  }

  float pixelX = viewSize.x / canvasSize.x;
  float pixelY = viewSize.y / canvasSize.y;

  float volumeX = sliceSize.x / pixelSize.x;
  float volumeY = sliceSize.y / pixelSize.y;

  float maxPointSize = max(volumeX / pixelX, volumeY / pixelY);

  // make slightly larger to make sure there aren't any gaps
  gl_PointSize = round(maxPointSize + 0.5);
}
`;
