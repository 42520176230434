import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Mesh, MeshBasicMaterial, SphereBufferGeometry } from 'three';
import { useDispatch, useSelector } from 'react-redux';
import { useSceneDispatch, useSceneSelector, useSceneSetup } from '@visionair/scene-state-3js';

import visualizeCenterline from '../../../modules/scene/actions/airwayView/visualizeCenterline';
import { AXIAL_VIEWER_NAME } from '../../../modules/scene/selectors';
import { intersectPlaneWithLineSegments } from '../../../utils/threeUtils';
import Instructions from './Instructions';
import centerlineStartIconActive from '../../../images/centerline_tools/centerline_start_active.svg';
import { getSelectedSegID } from '../../../modules/viewer/selectors';
import {
  showNotificationError,
  showNotificationSuccess,
} from '../../../modules/notifications/showNotification';
import saveCenterline from '../../../modules/scene/actions/airwayView/saveCenterline';
import snapshotCenterlineState from '../../../modules/scene/actions/airwayView/snapshotCenterlineState';
import resetCenterline from '../../../modules/scene/actions/airwayView/resetCenterline';
import clearCenterlineStates from '../../../modules/scene/actions/airwayView/clearCenterlineStates';
import setIsSelectingCenterlineStart from '../../../modules/viewer/setIsSelectingCenterlineStart';
import setShowSliceViews from '../../../modules/viewer/setShowSliceViews';
import saveSeg from '../../../modules/segmentations/saveSeg';

const START_MARKER_COLOR = 0xffff00;

export default function CenterlineStartingPoint({ onFinish }) {
  const { centerline, canvasHeight } = useSceneSelector(state => ({
    centerline: state.centerline,
    canvasHeight: state.canvas.height,
  }));
  const { sliceView, sliceIdx } = useSceneSelector(
    state => ({
      sliceView: state.sliceView,
      sliceIdx: state.sliceIdx,
    }),
    AXIAL_VIEWER_NAME,
  );
  const startMarkerRef = useRef();
  const segID = useSelector(({ viewer }) => getSelectedSegID(viewer));

  const sceneDispatch = useSceneDispatch();
  const reduxDispatch = useDispatch();

  useSceneSetup(sceneState => {
    reduxDispatch(setIsSelectingCenterlineStart(true));
    reduxDispatch(setShowSliceViews(true));

    const startMarker = new Mesh(
      new SphereBufferGeometry(1, 32, 32),
      new MeshBasicMaterial({ color: START_MARKER_COLOR }),
    );
    sceneState.scene.add(startMarker);

    startMarkerRef.current = startMarker;

    return () => {
      sceneState.scene.remove(startMarker);
      reduxDispatch(setIsSelectingCenterlineStart(false));
    };
  });

  useEffect(() => {
    const slicePlane = sliceView.getSlicePlane(sliceIdx);

    const intersection = centerline.traverse(b => {
      const int = intersectPlaneWithLineSegments(slicePlane, b.points);
      if (int) {
        return { ...int, branch: b };
      }

      return undefined;
    });

    if (intersection) {
      startMarkerRef.current.position.copy(intersection.point);
    }
  }, [centerline, sliceIdx, sliceView]);

  const handleConfirm = () => {
    const slicePlane = sliceView.getSlicePlane(sliceIdx);

    // negate constant since normal is in positive z direction
    const startZ = -slicePlane.constant;

    const intersection = centerline.traverse(b => {
      const int = intersectPlaneWithLineSegments(slicePlane, b.points);
      if (int) {
        return { ...int, branch: b };
      }

      return undefined;
    });

    if (!intersection) {
      // all of centerline is above start point
      // delete entire centerline
      if (centerline.root.start.z > startZ) {
        centerline.root = null;
      }
    } else {
      intersection.branch.setPoints(intersection.branch.points.splice(intersection.pointStartIdx));
      intersection.branch.setParent(null);

      centerline.root = intersection.branch;
    }

    sceneDispatch(snapshotCenterlineState());

    reduxDispatch(saveSeg(segID, { centerlineStartZ: startZ }))
      .then(() => sceneDispatch(saveCenterline(segID)))
      .then(() => {
        reduxDispatch(showNotificationSuccess('Centerline saved successfully'));
        sceneDispatch(clearCenterlineStates());
        onFinish();
      })
      .catch(err => {
        reduxDispatch(showNotificationError('There was a problem saving centerline'));
        console.error(err);
      });

    sceneDispatch(visualizeCenterline());
  };

  const handleCancel = () => {
    sceneDispatch(resetCenterline());

    onFinish();
  };

  return (
    <>
      <Instructions>
        <img src={centerlineStartIconActive} alt='' />
        Select the slice where the centerline should begin. This action cannot be undone.
      </Instructions>
      <FooterBtns style={{ bottom: `-${canvasHeight - 20}px` }}>
        <CancelBtn onClick={handleCancel}>Cancel</CancelBtn>
        <ConfirmBtn onClick={handleConfirm}>Confirm</ConfirmBtn>
      </FooterBtns>
    </>
  );
}

const FooterBtns = styled.div`
  position: absolute;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CancelBtn = styled.button`
  background: #393939;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  width: 160px;
  height: 35px;
  margin-right: 10px;
`;

const ConfirmBtn = styled.button`
  background: #19822d;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  width: 160px;
  height: 35px;
  margin-left: 10px;
`;
