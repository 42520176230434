import {
  USERTYPE_TECHNICIAN,
  USERTYPE_ADMINISTRATOR,
  USERTYPE_DOCTOR,
  USERTYPE_ASSISTANT,
} from '../../settings/broncUsers';

export const getTokenExpirationMs = state => state.get('tokenExpiration') * 1000;
export const getTokenIssuedAtMs = state => state.get('tokenIssuedAt') * 1000;
export const getTokenOrigIssuedAtMs = state => state.get('tokenOrigIssuedAt') * 1000;
export const getTokenRefreshedAtMs = state => state.get('refreshedAtMs');
export const getUserID = state => state.get('userID');
export const getUserType = state => state.get('userType');
export const getUserEmail = state => state.get('email');
export const getIsLoggedIn = state => !!state.get('token');
export const getToken = state => state.get('token');
export const getRecaptcha = state => state.get('recaptcha');

export const isDoctor = state => getUserType(state) === USERTYPE_DOCTOR;
export const isAssistant = state => getUserType(state) === USERTYPE_ASSISTANT;
export const isTechnician = state => getUserType(state) === USERTYPE_TECHNICIAN;
export const isAdministrator = state => getUserType(state) === USERTYPE_ADMINISTRATOR;

export const isCOSUser = state => isTechnician(state) || isAdministrator(state);

export const getShowLogoutWarning = state => state.get('showLogoutWarning');
