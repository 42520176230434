import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import TextInput from './TextInput';
import { ButtonDefault } from './buttons';

import authLogin from '../modules/auth/authLogin';

import { SCANSEG_STATUS } from '../settings/segmentations';
import setScansStatusFilters from '../modules/scansTable/setScansStatusFilters';

import { HttpUnauthorizedError } from '../utils/requests';

const { PENDING, SEGMENTING, ERROR, NEEDS_REVIEW, REJECTED, VERIFIED } = SCANSEG_STATUS;

export default function LoginForm() {
  const dispatch = useDispatch();

  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = evt => {
    evt.preventDefault();
    setErrorMessage(null);

    // eslint-disable-next-line promise/catch-or-return
    dispatch(authLogin(evt.target.email.value, evt.target.password.value))
      .then(() => {
        dispatch(
          setScansStatusFilters({
            [PENDING]: false,
            [SEGMENTING]: true,
            [ERROR]: true,
            [NEEDS_REVIEW]: true,
            [REJECTED]: false,
            [VERIFIED]: false,
          }),
        );

        setRedirectToReferrer(true);
      })
      .catch(err => {
        if (err instanceof HttpUnauthorizedError) {
          setErrorMessage(<span>Email or Password is incorrect.</span>);
          return;
        }

        setErrorMessage(
          <span>
            An unexpected error occurred during login.
            <br />
            Please try again.
          </span>,
        );
      });
  };

  const location = useLocation();
  const { from } = location.state || {
    from: { pathname: 'scans' },
  };

  if (redirectToReferrer) {
    return <Redirect to={from} />;
  }

  return (
    <Login>
      <Header>
        <HeaderText>Login</HeaderText>
      </Header>
      <form data-test='login-form' onSubmit={handleSubmit}>
        <TextField type='email' name='email' label='Email' required data-test='login-email' />
        <TextField
          type='password'
          name='password'
          label='Password'
          required
          data-test='login-password'
        />
        <ErrorMessage data-test='login-error-message'>{errorMessage}</ErrorMessage>
        <LoginButton id='login-btn' name='login' type='submit' data-test='login-button'>
          Login
        </LoginButton>
      </form>
    </Login>
  );
}

const Login = styled.div`
  color: ${props => props.theme.colors.white};
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  height: 64px;
  margin-bottom: 15px;
  position: relative;
`;

const HeaderText = styled.div`
  font-size: 21px;
  border: none;
  background: none;
  outline: none;
  text-align: center;
  height: 100%;
  line-height: 64px;
  width: min-content;
  position: relative;
  top: 4px;
  padding: 0 30px 0 0;
`;

const TextField = styled(TextInput)`
  div {
    color: ${props => props.theme.colors.white};
    font-size: 16px;
  }

  input {
    height: 45px;
    background-color: rgba(55, 55, 55, 0.67);
    border: 1px solid #7d7d7d;
    color: ${props => props.theme.colors.white} !important;

    &:focus {
      border: 1px solid #23d3cd;
    }
  }
`;

const ErrorMessage = styled.div`
  color: #ff5551;
  height: 55px;
`;

const LoginButton = styled(ButtonDefault)`
  outline: none;
  background-image: linear-gradient(to bottom, #ffc044 -101%, #ffbd3f 0%, #feaa22 148%);

  font-size: 16px;

  width: 100%;
  height: 45px;
`;
