export const getScans = state => state.get('byId').toList();

/**
 *
 * @param {Map} state scans state
 * @param {string} scanID scan ID
 * @returns {Map} specified scan
 */
export const getScanByID = (state, scanID) => state.getIn(['byId', scanID], null);

/**
 *
 * @param {Map} state scans state
 * @param {string} scanID scan ID
 * @returns {string} patient ID for specified scan
 */
export const getScanPatientID = (state, scanID) => state.getIn(['byId', scanID, 'patient'], null);

/**
 *
 * @param {Map} state scans state
 * @param {string} scanID scan ID
 * @returns {string} notes for specified scan
 */
export const getScanNotes = (state, scanID) => state.getIn(['byId', scanID, 'notes'], null);

/**
 * @param {Map} state scans state
 * @param {string} patientID patient ID
 * @returns {List(Map)} List of scans (Maps) that are from specified patient
 */
export const getScansByPatient = (state, patientID) =>
  state
    .get('byId')
    .filter(scan => getScanPatientID(state, scan.get('id')) === patientID)
    .toList();

export const getScanAirwayFileName = (state, scanID) =>
  state.getIn(['byId', scanID, 'airway'], null);

export const getScanCenterlineFileName = (state, scanID) =>
  state.getIn(['byId', scanID, 'centerline'], null);

export const getScanNum = (state, scanID) => state.getIn(['byId', scanID, 'scanNum']);
