import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Redirect, useLocation } from 'react-router-dom';

import ContainerHome from './ContainerHome';
import LoginForm from './LoginForm';

import useSelectorToJS from '../hooks/useSelectorToJS';

import { getIsLoggedIn } from '../modules/auth/selectors';
import authLogout from '../modules/auth/authLogout';

export default function HomePage({ shouldLogout }) {
  const dispatch = useDispatch();

  const { isLoggedIn } = useSelectorToJS(({ auth }) => ({ isLoggedIn: getIsLoggedIn(auth) }));

  useEffect(() => {
    if (isLoggedIn && shouldLogout) {
      dispatch(authLogout());
    }
  }, [dispatch, isLoggedIn, shouldLogout]);

  const location = useLocation();

  if (shouldLogout) {
    return <Redirect to='/' />;
  }

  if (isLoggedIn && !shouldLogout) {
    const { from } = location.state || {
      from: {
        pathname: '/scans',
      },
    };

    return <Redirect to={from} />;
  }

  return (
    <ContainerHome>
      <LoginContainer>
        <LoginForm />
      </LoginContainer>
    </ContainerHome>
  );
}

HomePage.propTypes = {
  shouldLogout: PropTypes.bool,
};

HomePage.defaultProps = {
  shouldLogout: false,
};

const LoginContainer = styled.div`
  width: 425px;
  border-radius: 4px;
  height: min-content;
`;
