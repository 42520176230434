import { isValidDate } from '../general';

export const dicomDateStringRegex = /(\d{4}).*?(\d{2}).*?(\d{2})/; // YYYYMMDD

export function getYearFromDicomDateString(dicomDateString) {
  return dicomDateString && dicomDateString.substring(0, 4); // dicomDateString is YYYYMMDD
}

export function getMonthFromDicomDateString(dicomDateString) {
  return dicomDateString && dicomDateString.substring(4, 6); // dicomDateString is YYYYMMDD
}

export function getDayFromDicomDateString(dicomDateString) {
  return dicomDateString && dicomDateString.substring(6, 8); // dicomDateString is YYYYMMDD
}

// "20181201" -> new Date(2018, 11, 01)
// NOTE: JS date object contructor month index is 0-based!!!!!
export function dicomDateStringToDate(dicomDateString) {
  if (!dicomDateString) {
    return null;
  }

  // check if already date object
  if (isValidDate(dicomDateString)) {
    return dicomDateString;
  }

  const matches = dicomDateString.match(dicomDateStringRegex);
  if (!matches) {
    return null;
  }

  const [, year, month, day] = matches;

  return new Date(Date.UTC(year, parseInt(month, 10) - 1, day));
}

// new Date(2018, 11, 01) -> "2018-12-01"
export function dateToISO8601DateString(date) {
  if (!date) {
    return '';
  }

  return date.toISOString().split('T')[0];
}

export function isISO8601ValidDate(iso8601DateString) {
  if (!iso8601DateString) {
    return false;
  }

  if (isValidDate(iso8601DateString)) {
    return true;
  }

  let isValid = false;
  const matches = iso8601DateString.match(/^(\d{4})-(\d{2})-(\d{2})$/);
  if (matches !== null) {
    const year = +matches[1];
    const month = +matches[2] - 1; // NOTE: month index is 0-based
    const day = +matches[3];
    const date = new Date(year, month, day);

    // JS Date constructor creates valid dates if provided values outside expected range
    // e.g. 2018-14-01 -> 2019-03-01
    // so check that values are not overflowed
    if (date.getMonth() !== month || date.getDate() !== day) {
      return false;
    }

    isValid = isValidDate(date);
  }

  return isValid;
}

// This function is safe to use on dates that are already dicom date strings
export function iso8601StringToDicomDateString(isoDateString) {
  if (!isoDateString) {
    return '';
  }

  return isoDateString.slice(0, 10).replace(/-/g, '');
}

export const isScanExpired = scanDate => {
  if (!scanDate) {
    return false;
  }

  const threeMonthsAgo = new Date();
  const parsedScanDate = dicomDateStringToDate(scanDate);
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
  if (parsedScanDate < threeMonthsAgo) {
    return true;
  }
  return false;
};

export function spellOutPatientSex(sexAbbrev) {
  return (
    {
      M: 'Male',
      F: 'Female',
      O: 'Other',
    }[sexAbbrev] || ''
  );
}

export function formatPatientName(name) {
  if (!name) {
    return name;
  }

  let formattedName = name.replace(/[\^=]/gi, ' ');
  formattedName = formattedName.trim();
  formattedName = formattedName.replace(/^[,_]*|[,_]*$/g, '');
  formattedName = formattedName.trim();

  return formattedName;
}

export function isAnonymized(scanInfo = {}) {
  if (!scanInfo.patientName || !scanInfo.patientMRN || !scanInfo.patientDOB) {
    return true;
  }

  if (
    /patient|anonymous/i.test(scanInfo.patientName) ||
    /patient|anonymous/i.test(scanInfo.patientMRN)
  ) {
    return true;
  }

  return false;
}

export function matchesConfirmedPatientInfo(vol, scan) {
  if (!vol || !scan) {
    return false;
  }

  // Skip if DB patient info is anonymized
  if (isAnonymized(vol)) {
    return false;
  }

  const formatVolDOB = volDOB => {
    if (!volDOB?.length) {
      return '';
    }

    const dateParts = volDOB.split('-');
    if (dateParts.length !== 3) {
      return '';
    }

    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];

    return `${year}-${month}-${day}T00:00:00Z`;
  };

  return (
    vol.patientName === scan.patient_name &&
    vol.patientMRN === scan.patient_mrn &&
    formatVolDOB(vol.patientDOB) === scan.patientDOB
  );
}

export function isValidPatientName(patientName) {
  if (!patientName) {
    return false;
  }

  return !!patientName.trim().length;
}

export function isValidPatientMRN(patientMRN) {
  if (!patientMRN) {
    return false;
  }

  return !!patientMRN.trim().length;
}

export function isValidPatientDOB(patientDOB) {
  return isISO8601ValidDate(patientDOB);
}

export function isValidPatientSex(patientSex) {
  if (!patientSex) {
    return false;
  }

  const allowedValues = ['M', 'F', 'O'];
  return allowedValues.includes(patientSex.toUpperCase());
}

export function isValidScanDate(scanDate) {
  return isISO8601ValidDate(scanDate);
}
