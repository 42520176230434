import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import useScanSegs from '../../hooks/useScanSegs';

import { rejectScanURL, getRejectionCodesURL } from '../../settings/api';
import { SEG_STATUS } from '../../settings/segmentations';

import Modal from './ModalStyled';
import ButtonDefault from '../buttons/ButtonDefault';

import { apiFetch } from '../../utils/requests';
import { getToken } from '../../modules/auth/selectors';
import { updateScanAction } from '../../modules/scans/updateScan';
import { showNotificationError } from '../../modules/notifications/showNotification';

const REJECTION_CODE_MISC = '800';

const code2Reason = code =>
  ({
    '100': 'Slice thickness > 1.5mm',
    '200': 'Incomplete left bronchi',
    '201': 'Incomplete right bronchi',
    '202': 'Incomplete main bronchi',
    '203': 'Incorrect anatomy',
    '300': 'Poor scan quality',
    '400': 'Artifact present',
    '500': 'Multiple patients uploaded',
    '600': 'Patient Name missing',
    '601': 'Patient DOB missing',
    '602': 'Patient MRN missing',
    '603': 'CT Scan Date missing',
    '700': 'Not a CT scan',
    '800': 'Other - specify reason',
  }[code]);

export default function ScanRejectModal({ show, onClose }) {
  const { scanID } = useParams();
  const token = useSelector(({ auth }) => getToken(auth));
  const dispatch = useDispatch();
  const scanSegs = useScanSegs();

  const [rejectionCodes, setRejectionCodes] = useState([]);
  const [selectedCode, setSelectedCode] = useState(null);
  const [rejectionNotes, setRejectionNotes] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (show && !rejectionCodes.length) {
      apiFetch(getRejectionCodesURL(), token, 'GET')
        .then(setRejectionCodes)
        .catch(err => {
          onClose();
          dispatch(
            showNotificationError('An unexpected error occurred while loading rejection codes.'),
          );
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }
  }, [rejectionCodes.length, show, token, dispatch, onClose]);

  const handleReasonSelect = eventKey => {
    const rejectCode = eventKey;
    setSelectedCode(rejectCode);
  };

  const rejectScan = () => {
    const body = {
      rejection_code: selectedCode,
      miscRejectionReason: selectedCode === REJECTION_CODE_MISC ? rejectionNotes : undefined,
    };

    setIsSubmitting(true);

    apiFetch(rejectScanURL(scanID), token, 'POST', body)
      .then(scan => {
        setIsSubmitting(false);
        dispatch(updateScanAction(scan));
        onClose();
      })
      .catch(err => {
        setIsSubmitting(false);
        onClose();
        dispatch(showNotificationError('An unexpected error occurred while rejecting scan.'));
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const runningSegs = scanSegs.filter(s => s.status === SEG_STATUS.RUNNING);

  return (
    <RejectModal show={show} centered backdropClassName='modal-backdrop-blur'>
      <ModalHeader>Reject CT Scan?</ModalHeader>
      <ModalBody>
        <BodyText>I am rejecting this scan because...</BodyText>
        <DropdownContainer value={selectedCode} onSelect={handleReasonSelect}>
          <DropdownToggle>
            {selectedCode === null ? (
              'Select reason...'
            ) : (
              <SelectedReason>
                <RejCode>{selectedCode}</RejCode>
                <RejReason>{code2Reason(selectedCode)}</RejReason>
              </SelectedReason>
            )}
          </DropdownToggle>
          <DropdownMenu>
            {rejectionCodes.map(({ code }) => (
              <DropdownItem key={code} eventKey={code}>
                <RejCode>{code}</RejCode>
                <RejReason>{code2Reason(code)}</RejReason>
              </DropdownItem>
            ))}
            <DropdownItem eventKey={REJECTION_CODE_MISC}>
              <RejCode>{REJECTION_CODE_MISC}</RejCode>
              <RejReason>{code2Reason(REJECTION_CODE_MISC)}</RejReason>
            </DropdownItem>
          </DropdownMenu>
        </DropdownContainer>
        {selectedCode === REJECTION_CODE_MISC && (
          <MiscRejReason
            as='textarea'
            value={rejectionNotes}
            onChange={evt => setRejectionNotes(evt.target.value)}
            rows='3'
            placeholder='Enter custom rejection reason here...'
          />
        )}
        {runningSegs.length > 0 && (
          <WarningMessage>Warning! This scan has running segmentations.</WarningMessage>
        )}
        <ul>
          {runningSegs.map(s => (
            <li key={s.id}>{s.segAlphaNum}</li>
          ))}
        </ul>
        <Checkbox
          type='checkbox'
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
          id='confirm-all-series-checkbox'
          custom
          label='I have checked all series associated with this CT scan'
        />
      </ModalBody>
      <Modal.Footer>
        <CancelBtn disabled={isSubmitting} onClick={onClose}>
          Cancel
        </CancelBtn>
        <ConfirmBtn
          disabled={
            !isChecked ||
            selectedCode === null ||
            (selectedCode === REJECTION_CODE_MISC && !rejectionNotes) ||
            isSubmitting
          }
          onClick={rejectScan}
        >
          Reject Scan
        </ConfirmBtn>
      </Modal.Footer>
    </RejectModal>
  );
}

ScanRejectModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const RejectModal = styled(Modal)`
  .modal-dialog {
    max-width: 600px;
  }
`;

const ModalHeader = styled(Modal.Header)`
  justify-content: flex-start;
`;

const ModalBody = styled(Modal.Body)`
  padding-top: 5px;
  padding-bottom: 0;
  font-size: 15px;
`;

const BodyText = styled.div`
  margin-bottom: 20px;
`;

const DropdownContainer = styled(Dropdown)`
  width: 100%;
`;

const DropdownToggle = styled(Dropdown.Toggle)`
  width: 100%;
  padding: 12px 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 15px;

  &&&,
  &&&:hover,
  &&&:active {
    background-color: #565656;
    border: 1px solid #565656;
    color: #ffffff;
  }
`;

const DropdownMenu = styled(Dropdown.Menu)`
  background-color: #343434;
  border: 1px solid #333333;

  width: 100%;
  padding: 10px;

  /* max-height: 245px;
  overflow: auto; */
`;

const DropdownItem = styled(Dropdown.Item)`
  color: #ffffff;
  font-size: 14px;
  border-radius: 4px;
  padding: 12px 20px;
  white-space: normal;

  display: flex;

  :hover {
    background-color: #494949;
    color: #ffffff;
  }
`;

const RejCode = styled.div`
  width: 60px;
  text-align: left;
`;

const RejReason = styled.div`
  text-align: left;
  flex: 1 0;
`;

const SelectedReason = styled.div`
  display: flex;

  ${RejReason} {
    width: 380px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const WarningMessage = styled.h6`
  margin-top: 25px;
`;

const MiscRejReason = styled(Form.Control)`
  display: block;
  width: 100%;
  margin: 30px 0;
  padding: 10px;

  background-color: #343434;
  color: #ffffff;

  border: 1px solid #565656;
  border-radius: 6px;
`;

const Checkbox = styled(Form.Check)`
  margin-top: 30px;
  margin-left: 22px;

  label {
    cursor: pointer;
  }

  /* UNCHECKED */
  .custom-control-label {
    color: #ffffff;

    ::before {
      top: 2px;
      border-radius: 2px;
      border-color: #bdbdbd;
      background-color: #191919;
      width: 18px;
      height: 18px;
    }

    ::after {
      top: 2px;
      background-size: 75%;
      width: 18px;
      height: 18px;
    }
  }

  /* CHECKED */
  .custom-control-input:checked ~ .custom-control-label {
    color: #ffffff;

    ::before {
      border-color: #aa0014;
      background-color: #aa0014;
    }
  }

  /* remove blue border */
  .custom-control-input:focus ~ .custom-control-label::before {
    border-color: #fafafa !important;
    box-shadow: none !important;
  }
`;

const CancelBtn = styled(ButtonDefault)`
  width: 190px;
  background-color: #969696;
  margin-right: 20px !important;
`;

const ConfirmBtn = styled(ButtonDefault)`
  width: 190px;
  background-color: #aa0014;
`;
