import React from 'react';
import styled from 'styled-components';
import { MdUploadFile } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { OverlayTrigger } from 'react-bootstrap';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ScanOverwriteModal from '../../modals/scan-overwrite-modal/ScanOverwriteModal';
import Tooltip from '../../Tooltip.styled';

import { getShowOverwriteScanModal } from '../../../modules/viewer/selectors';
import setShowOverwriteScanModal from '../../../modules/viewer/setShowOverwriteScanModal';

export default function OverwriteScanBtn({ scan }) {
  const dispatch = useDispatch();

  const show = useSelector(({ viewer }) => getShowOverwriteScanModal(viewer));

  const showIconButton = useMediaQuery('(max-width:1400px)');

  return (
    <>
      {!showIconButton && (
        <S.OverwriteScanBtn
          onClick={() => dispatch(setShowOverwriteScanModal(true))}
          startIcon={<MdUploadFile size={24} />}
        >
          Overwrite Scan
        </S.OverwriteScanBtn>
      )}
      {showIconButton && (
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip id='tooltip-overwriteScan'>Overwrite Scan</Tooltip>}
        >
          <S.OverwriteScanIconBtn onClick={() => dispatch(setShowOverwriteScanModal(true))}>
            <MdUploadFile size={24} />
          </S.OverwriteScanIconBtn>
        </OverlayTrigger>
      )}

      {show && (
        <ScanOverwriteModal
          scan={scan}
          open={show}
          onClose={() => dispatch(setShowOverwriteScanModal(false))}
          onUploadSuccess={() => window.location.reload()}
        />
      )}
    </>
  );
}

const S = {};

S.OverwriteScanBtn = styled(Button)`
  text-transform: none;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;

  max-width: 175px;
  width: 100%;
  height: 100%;

  background-color: #1d1d1d;
  border: 1px solid #494949;
  border-radius: 6px;
  box-shadow: none;

  &:focus {
    outline: none;
  }

  .MuiButton-startIcon {
    margin-right: 14px;
  }
`;

S.OverwriteScanIconBtn = styled(IconButton)`
  max-width: 75px;
  width: 100%;
  height: 100%;

  color: #ffffff;
  background-color: #1d1d1d;
  border: 1px solid #494949;
  border-radius: 6px;
  box-shadow: none;

  &:focus {
    outline: none;
  }
`;
