import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useSceneSetup, useSceneDispatch } from '@visionair/scene-state-3js';

import visualizeCenterline from '../../../modules/scene/actions/airwayView/visualizeCenterline';
import Instructions from './Instructions';
import genIconActive from '../../../images/centerline_tools/gen_active.svg';

export default function GenerationTool({ onFinish }) {
  const dispatch = useSceneDispatch();

  const hasChangedRef = useRef(false);

  const leftBranchesRef = useRef([]);
  const rightBranchesRef = useRef([]);

  const [leftGen, setLeftGen] = useState(0);
  const [rightGen, setRightGen] = useState(0);
  const [maxLeftGen, setMaxLeftGen] = useState(1);
  const [maxRightGen, setMaxRightGen] = useState(1);

  useSceneSetup(sceneState => {
    sceneState.centerline.traverse(b => {
      if (b === sceneState.centerline.root) {
        return false;
      }

      if (b.generationNumber > 1) {
        return true;
      }

      if (b.end.x > sceneState.centerline.root.end.x) {
        leftBranchesRef.current.push(b, ...b.getDecendants());
      } else {
        rightBranchesRef.current.push(b, ...b.getDecendants());
      }

      return false;
    });

    const maxL = leftBranchesRef.current.reduce(
      (max, { generationNumber }) => (generationNumber > max ? generationNumber : max),
      0,
    );
    const maxR = rightBranchesRef.current.reduce(
      (max, { generationNumber }) => (generationNumber > max ? generationNumber : max),
      0,
    );

    const maxVisL = leftBranchesRef.current.reduce(
      (max, { generationNumber, visible }) =>
        generationNumber > max && visible ? generationNumber : max,
      0,
    );
    const maxVisR = rightBranchesRef.current.reduce(
      (max, { generationNumber, visible }) =>
        generationNumber > max && visible ? generationNumber : max,
      0,
    );

    setMaxLeftGen(maxL);
    setMaxRightGen(maxR);

    setLeftGen(maxVisL);
    setRightGen(maxVisR);
  });

  const handleLeftGenChange = ({ target: { value } }) => {
    const gen = parseFloat(value);

    leftBranchesRef.current.forEach(b => {
      b.setVisible(b.generationNumber <= gen);
    });
    dispatch(visualizeCenterline());

    setLeftGen(value);

    if (!hasChangedRef.current) {
      onFinish();
      hasChangedRef.current = true;
    }
  };

  const handleRightGenChange = ({ target: { value } }) => {
    const gen = parseFloat(value);

    rightBranchesRef.current.forEach(b => {
      b.setVisible(b.generationNumber <= gen);
    });
    dispatch(visualizeCenterline());

    setRightGen(value);

    if (!hasChangedRef.current) {
      onFinish();
      hasChangedRef.current = true;
    }
  };

  return (
    <>
      <Instructions>
        <img src={genIconActive} alt='' />
        Select branch generation
      </Instructions>
      <SliderContainer>
        <SliderRow>
          L
          <Slider
            type='range'
            value={leftGen}
            min={0}
            max={maxLeftGen}
            step={1}
            onChange={handleLeftGenChange}
            data-test='gen-select-slider-left'
          />
          {leftGen}
        </SliderRow>
        <SliderRow>
          R
          <Slider
            type='range'
            value={rightGen}
            min={0}
            max={maxRightGen}
            step={1}
            onChange={handleRightGenChange}
            data-test='gen-select-slider-right'
          />
          {rightGen}
        </SliderRow>
      </SliderContainer>
    </>
  );
}

const SliderContainer = styled.div`
  position: absolute;
  top: 84px;
  left: 20px;
  border-radius: 6px;
  background-color: #171616;
  padding: 10px;
  width: 280px;
  color: white;
`;

const SliderRow = styled.div`
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Slider = styled.input`
  flex-grow: 1;
  margin: 0px 12px;

  -webkit-appearance: none;
  outline: none !important;
  background: none;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    position: relative;
    z-index: 2;

    width: 15px;
    height: 15px;
    border-radius: 50px;

    margin-top: -5px;
    background: radial-gradient(#e2e0e0, #e2e1e1);
    border: none;
    box-shadow: none;
  }

  ::-webkit-slider-runnable-track {
    border: none;
    background: #6cff00;
    height: 4px;
    border-radius: 4px;
  }

  &:hover {
    cursor: pointer;
  }
`;
