import { useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { convertImmutable } from '../utils/general';

export default function useSelectorToJS(selectorFunc, equalityFunc = shallowEqual) {
  const prevSelectorStateJS = useRef();
  const prevSelectorStateImmutable = useRef();
  const currSelectorState = useSelector(selectorFunc, equalityFunc);

  if (!equalityFunc(prevSelectorStateImmutable.current, currSelectorState)) {
    prevSelectorStateImmutable.current = currSelectorState;
    prevSelectorStateJS.current = convertImmutable(currSelectorState);
  }

  return prevSelectorStateJS.current;
}
