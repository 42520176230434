import { vec3 } from 'gl-matrix';

import { isValidDate } from '../general';
import tags from './tags';

export const DICOM_DATE_STRING_REGEX = /(\d{4}).*?(\d{2}).*?(\d{2})/; // YYYYMMDD

/**
 * stores meta info from DICOM header of single image
 */
class DicomSlice {
  constructor(
    {
      [tags.STUDY_INSTANCE_UID]: studyInstanceUID,
      [tags.STUDY_ID]: studyID,
      [tags.SERIES_INSTANCE_UID]: seriesInstanceUID,
      [tags.SERIES_NUMBER]: seriesNumber,
      [tags.STUDY_DATE]: studyDate,
      [tags.SERIES_DATE]: seriesDate,
      [tags.ACQUISITION_DATE]: acquisitionDate,
      [tags.PATIENT_NAME]: patientName,
      [tags.IMAGE_POSITION]: imagePosition,
      [tags.IMAGE_ORIENTATION]: imageOrientation,
    } = {},
    filename,
  ) {
    this.studyInstanceUID = studyInstanceUID;
    this.studyID = studyID;
    this.seriesInstanceUID = seriesInstanceUID;
    this.seriesNumber = seriesNumber;

    this.scanDate = DicomSlice.dicomDateStringToDate(studyDate || seriesDate || acquisitionDate);

    this.patientName = patientName;

    this.imagePosition = imagePosition;
    this.imageOrientation = imageOrientation;
    this.sliceLocation = null;

    this.filename = filename;
  }

  isExpired() {
    const MONTHS_TIL_EXPIRED = 6;

    if (!this.scanDate) {
      return false;
    }

    const now = new Date();
    const dateExpired = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));
    dateExpired.setUTCMonth(dateExpired.getUTCMonth() - MONTHS_TIL_EXPIRED);

    return this.scanDate <= dateExpired;
  }

  /**
   * returns slice location value (single coordinate)
   * based upon image position and orientation
   * CANNOT trust Slice Location tag
   * NOTE: does not recompute if already computed
   * @returns {number} slice location
   */
  getSliceLocation() {
    // don't recompute if already computed
    if (this.sliceLocation != null) {
      return this.sliceLocation;
    }

    let sliceLocation;

    try {
      const imgPosition = DicomSlice.parseDecimalString(this.imagePosition);
      const { rowDir, colDir } = DicomSlice.parseImageOrientation(this.imageOrientation);

      const position = vec3.fromValues(...imgPosition);
      const xAxis = vec3.fromValues(...rowDir);
      const yAxis = vec3.fromValues(...colDir);

      const zAxis = vec3.cross(vec3.create(), xAxis, yAxis);

      sliceLocation = vec3.dot(position, vec3.normalize(zAxis, zAxis));
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      console.error(`Could not determine slice location: ${this.filename}`); // eslint-disable-line no-console
    }

    this.sliceLocation = sliceLocation;

    return sliceLocation;
  }

  /**
   * Parses decimal string (DS) into array with length dependent
   * on value multiplicity (VM) of decimalString
   * ex. "0\-1\2" -> [0, -1, 2]
   * DS - http://dicom.nema.org/medical/dicom/current/output/chtml/part05/sect_6.2.html
   * VM - http://dicom.nema.org/Dicom/2013/output/chtml/part05/sect_6.4.html
   * @param {string} decimalString string representing a float
   * @returns {number[]} array of parsed decimalString values
   */
  static parseDecimalString(decimalString) {
    const valuesStr = decimalString.split(/[\\/]/);
    return valuesStr.map(valueStr => parseFloat(valueStr));
  }

  /**
   * Parses image orientation decimal string into two arrays (row direction & column direction)
   * see https://dicom.innolitics.com/ciods/ct-image/image-plane/00200037
   * @param {string} imageOrientation decimal string from image orientation tag
   * @returns {Object}
   * @property {number[]} rowDir x, y, z axes for direction cosines of row
   * @property {number[]} colDir x, y, z axes for direction cosines of column
   */
  static parseImageOrientation(imageOrientation) {
    const values = DicomSlice.parseDecimalString(imageOrientation);
    const rowDir = values.slice(0, 3);
    const colDir = values.slice(3);

    return { rowDir, colDir };
  }

  // "20181201" -> new Date(2018, 11, 01)
  // NOTE: JS date object contructor month index is 0-based!!!!!
  static dicomDateStringToDate(dicomDateString) {
    if (!dicomDateString) {
      return null;
    }

    // check if already date object
    if (isValidDate(dicomDateString)) {
      return dicomDateString;
    }

    const matches = dicomDateString.match(DICOM_DATE_STRING_REGEX);
    if (!matches) {
      return null;
    }

    const [, year, month, day] = matches;

    return new Date(Date.UTC(year, parseInt(month, 10) - 1, day));
  }

  // new Date(2018, 11, 01) -> "20181201"
  // NOTE: JS date object contructor month index is 0-based!!!!!
  static dateToDicomDateString(dateObject) {
    if (!dateObject) {
      return '';
    }

    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject
      .getDate()
      .toString()
      .padStart(2, '0');

    return `${year}${month}${day}`;
  }
}

export default DicomSlice;
