import React from 'react';

import { ReactComponent as ConfirmInfoIcon } from '../../../images/scan-overwrite-modal/ct-upload-confirm-info-icon.svg';

import ConfirmScanInfoForm from './ConfirmScanInfoForm';
import ScanUploadDialog from './ScanUploadDialog';

export default function StepConfirmScanInfo({
  originalScanInfo,
  parsedScanInfo,
  onClose,
  onContinue,
}) {
  return (
    <>
      <ScanUploadDialog.Title
        heading='Review Patient Info'
        icon={<ConfirmInfoIcon />}
        showCloseBtn
        onClose={onClose}
      />

      <ConfirmScanInfoForm
        originalScanInfo={originalScanInfo}
        parsedScanInfo={parsedScanInfo}
        onSubmit={onContinue}
        onCancel={onClose}
      />
    </>
  );
}
