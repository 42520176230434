import visualizeCenterline from './visualizeCenterline';

export default function undoCenterline() {
  return (notify, getSceneState, callSceneAction) => {
    const { centerlineChanges, centerline } = getSceneState();
    if (centerlineChanges.idx === 0) {
      return;
    }

    const newCenterlineData = centerlineChanges.states[centerlineChanges.idx - 1];
    centerline.fromJSON(newCenterlineData);

    centerlineChanges.idx -= 1;
    centerlineChanges.needsUpdate = true;

    callSceneAction(visualizeCenterline());

    notify();
  };
}
