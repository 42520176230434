import { median } from '../math';

export const MIN_NUM_SLICES = 20;
export const MAX_SLICE_THICKNESS = 1.5;
export const MIN_SLICE_THICKNESS = 0.1;

/**
 * stores meta info about DICOM series (multiple images from same volume)
 */
class DicomSeries {
  constructor() {
    this.sliceThickness = null; // calculated from slice positions

    this.slices = []; // images in this series (instances of DicomSlice)
  }

  get studyID() {
    return this.slices.length ? this.slices[0].studyID : null; // assume slice has correct info
  }

  get seriesNumber() {
    return this.slices.length ? this.slices[0].seriesNumber : null; // assume slice has correct info
  }

  get scanDate() {
    return this.slices.length ? this.slices[0].scanDate : null; // assume slice has correct info
  }

  /**
   * adds slice to array
   * if specified, inserts at index in order according to spatial position
   * @param {DicomSlice} dicomSlice
   * @param {boolean} sortByLocation whether or not to sort slices by location after adding
   * @returns {DicomSlice[]} array of slices
   */
  addSlice(dicomSlice, sortByLocation = false) {
    this.slices.push(dicomSlice);

    if (sortByLocation) {
      this.sortSlicesByLocation();
    }

    return this.slices;
  }

  /**
   * @returns {boolean} whether or not series has enough images to be considered acceptable
   */
  hasEnoughSlices() {
    return this.slices.length >= MIN_NUM_SLICES;
  }

  /**
   * sorts slices in series by spatial position in ascending order
   * NOTE: sort is performed in place!
   */
  sortSlicesByLocation() {
    this.slices.sort((slice1, slice2) => {
      const slice1Loc = slice1.getSliceLocation();
      const slice2Loc = slice2.getSliceLocation();

      // put slice at end if location unknown
      if (slice1Loc == null) {
        return 1;
      }
      if (slice2Loc == null) {
        return -1;
      }

      return slice1Loc - slice2Loc;
    });
  }

  /**
   * determines slice thickness of slices in series by calculating difference between
   * slice positions
   * NOTE: in order to guarantee accurate calculation, you must ensure all slices
   * have been added to series before calling this
   * @returns {Number} - median slice thickness value
   */
  calcSliceThickness() {
    if (this.slices.length < 2) {
      return null;
    }

    this.sortSlicesByLocation();

    // subtract adjacent locations
    const sliceThicknesses = [];
    for (let i = 1; i < this.slices.length; i += 1) {
      const pos1 = this.slices[i - 1].getSliceLocation();
      const pos2 = this.slices[i].getSliceLocation();

      // ignore slices with unknown location
      if (pos1 != null && pos2 != null) {
        sliceThicknesses.push(Math.abs(pos1 - pos2));
      }
    }

    // get median value to reduce potential effect of outliers
    const sliceThickness = median(sliceThicknesses, (a, b) => a - b);
    this.sliceThickness = sliceThickness;

    return sliceThickness;
  }

  /**
   * Checks if slice thickness has value within acceptable range
   * by calculating value
   * @returns {Boolean} whether or not slice thickness is valid
   */
  hasValidSliceThickness() {
    this.calcSliceThickness();

    return MIN_SLICE_THICKNESS <= this.sliceThickness && this.sliceThickness <= MAX_SLICE_THICKNESS;
  }
}

export default DicomSeries;
