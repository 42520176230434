import { getAxialSlicePlane, getCoronalSlicePlane } from '../../selectors';

export default function setSlicePlanesVisibility(visible) {
  return (notify, getSceneState) => {
    const sceneState = getSceneState();

    const axialPlane = getAxialSlicePlane(sceneState);
    const coronalPlane = getCoronalSlicePlane(sceneState);

    axialPlane.visible = visible;
    coronalPlane.visible = visible;

    notify();
  };
}
