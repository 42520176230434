export default function updateSeedPositions() {
  return (notify, getSceneState) => {
    const sceneState = getSceneState();

    sceneState.camera.updateMatrixWorld();

    sceneState.seedPoints.forEach(seedPoint => {
      if (seedPoint.sliceIdx !== sceneState.sliceIdx) {
        return;
      }

      seedPoint.projPos.copy(seedPoint.position).project(sceneState.camera);

      /* eslint-disable no-param-reassign */
      seedPoint.projPos.x = (seedPoint.projPos.x + 1) * sceneState.camera.right;
      seedPoint.projPos.y = (seedPoint.projPos.y - 1) * sceneState.camera.bottom;

      seedPoint.needsUpdate = true;
    });
  };
}
