import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import useSelectorToJS from './useSelectorToJS';
import { getScanByID } from '../modules/scans/selectors';
import fetchScanByID from '../modules/scans/fetchScanByID';

export default function useScan(scanID, forceUpdate = true) {
  const dispatch = useDispatch();
  const { scan } = useSelectorToJS(({ scans }) => ({ scan: getScanByID(scans, scanID) }));
  const isScanLoaded = useRef(Boolean(scan) && !forceUpdate);

  useEffect(() => {
    if (scanID && !isScanLoaded.current) {
      dispatch(fetchScanByID(scanID));
      isScanLoaded.current = true;
    }
  }, [scanID, dispatch]);

  return scan;
}
