import { Map, fromJS } from 'immutable';

import * as types from '../actions';

const INITIAL_STATE = Map({ byId: Map() });

function loadVolume3Ds(state, volume3Ds) {
  const idState = state.get('byId').withMutations(mutState => {
    volume3Ds.forEach(vol => {
      mutState.set(vol.id, fromJS(vol));
    });
  });

  return state.set('byId', idState);
}

function loadScanVolume3Ds(state, scanVols) {
  const { scanID } = scanVols[0];
  if (scanVols.some(vol => vol.scanID !== scanID)) {
    throw new Error('Error computing seriesAlpha - Attempted to add vols from different scans');
  }

  const sortedVols = scanVols
    .sort((a, b) => a.id - b.id)
    .map((vol, i) => ({ ...vol, seriesAlpha: getSeriesAlphaByIdx(i) }));

  return loadVolume3Ds(state, sortedVols);
}

export default function volume3DsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.AUTH_LOGOUT:
      return INITIAL_STATE;
    case types.ADD_VOLUME_3DS:
      return loadVolume3Ds(state, action.volume3Ds);
    case types.ADD_VOLUME_3DS_BY_SCAN:
      return loadScanVolume3Ds(state, action.volume3Ds);
    default:
      return state;
  }
}

function getSeriesAlphaByIdx(seriesIdx) {
  const char = String.fromCharCode('A'.charCodeAt(0) + (seriesIdx % 26));
  let str = '';

  for (let i = 0; i <= Math.floor(seriesIdx / 26); i += 1) {
    str += char;
  }

  return str;
}
