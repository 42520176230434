export const LOGOUT_WARNING_INTERVAL_MS = 55000; // 55 seconds
export const REFRESH_THROTTLE_MS = LOGOUT_WARNING_INTERVAL_MS;
export const SCAN_EXPIRE_MONTHS = 3;
export const REENTER_PASSWORD_MODAL_TIMEOUT_MS = 60 * 1000; // 1 minute
export const MAX_TOKEN_REFRESH_MS = 3 * 60 * 60 * 1000; // 3 hours, set by api
export const MIN_PASSWORD_LENGTH = 8;

export const USERTYPE_DOCTOR = 'doctor';
export const USERTYPE_ASSISTANT = 'assistant';
export const USERTYPE_TECHNICIAN = 'technician';
export const USERTYPE_ADMINISTRATOR = 'administrator';

const userTypes = [
  USERTYPE_DOCTOR,
  USERTYPE_ASSISTANT,
  USERTYPE_TECHNICIAN,
  USERTYPE_ADMINISTRATOR,
];

const USERTYPES = {};

userTypes.forEach((value, index) => {
  USERTYPES[value] = index;
});

export default USERTYPES;
