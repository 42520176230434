import jwt from 'jwt-decode';
import * as types from '../actions/auth';
import { LOGOUT_WARNING_INTERVAL_MS } from '../../settings/broncUsers';

export function showLogoutWarning(showWarning) {
  return {
    type: types.SHOW_LOGOUT_WARNING,
    showWarning,
  };
}

let warningTimeout = null;

export default function setLogoutWarningTimeout(token) {
  return dispatch => {
    if (token !== undefined) {
      const expiresAtMs = jwt(token).exp * 1000;
      const expiresFromNowMs = expiresAtMs - Date.now();
      const warningFromNowMs = expiresFromNowMs - LOGOUT_WARNING_INTERVAL_MS;

      clearTimeout(warningTimeout);
      warningTimeout = window.setTimeout(() => {
        dispatch(showLogoutWarning(true));
        warningTimeout = null;
      }, warningFromNowMs);
    }

    dispatch(showLogoutWarning(false));
  };
}
