import ImageLoaderWorker from '../workers/sliceImageLoader';
import SliceLoader from './SliceLoader';

export default class SliceView {
  constructor(canvas, sliceView) {
    this.isWorkerLoader = Boolean(window.OffscreenCanvas);

    this.loadSlicesListeners = [];

    if (this.isWorkerLoader) {
      this.loader = ImageLoaderWorker();

      const offscreenCanvas = canvas.transferControlToOffscreen();

      // manually build postMessage matching internal workerize-loader api
      // required to be able to transfer control of canvas
      this.loader.postMessage(
        {
          type: 'RPC',
          id: 1,
          method: 'initialize',
          params: [offscreenCanvas, sliceView],
        },
        [offscreenCanvas],
      );

      this.loader.onmessage = e => {
        if (this.sliceCallback && e.data.message === 'displaySlice') {
          this.sliceCallback(e.data.sliceIdx, e.data.imgDimensions);
        } else if (e.data.message === 'loadSlices') {
          const listenerIdx = this.loadSlicesListeners.findIndex(f =>
            f.args.every((arg, i) => arg === e.data.args[i]),
          );
          if (listenerIdx > -1) {
            this.loadSlicesListeners.splice(listenerIdx, 1).forEach(f => f(e));
          }
        }
      };
    } else {
      this.loader = new SliceLoader(canvas, sliceView);
    }
  }

  loadSlices(start, numSlices, token) {
    const promise = this.loader.loadSlices(start, numSlices, token);
    if (!this.isWorkerLoader) {
      return promise;
    }

    // wait for promise within worker to resolve
    return new Promise((resolve, reject) => {
      const listener = e => {
        if (e.data.err) {
          reject(e.data.err);
        } else {
          resolve(e.data);
        }
      };
      listener.args = [start, numSlices, token];
      this.loadSlicesListeners.push(listener);
    });
  }

  displaySlice(sliceIdx, token) {
    return this.loader.displaySlice(sliceIdx, token);
  }

  initializeDisplayCallback(cb) {
    this.sliceCallback = cb;
  }

  initializeSliceView(sliceView) {
    this.loader.initializeSliceView(sliceView);
  }

  setWindow(min, max) {
    this.loader.setWindow(min, max);
  }

  dispose() {
    this.loader.dispose();
    if (this.isWorkerLoader) {
      this.loader.terminate();
    }
    this.loader = null;
  }
}
