import { getScanByID } from '../scans/selectors';
import { getSegByID } from '../segmentations/selectors';
import { getVolByID } from '../volume3Ds/selectors';

export const getSelectedSegID = state => state.get('segID');
export const getSelectedVolID = state => state.get('volID');
export const getSelectedScanID = state => state.get('scanID');

export const getSelectedSeg = (viewerState, segState) =>
  getSegByID(segState, getSelectedSegID(viewerState));
export const getSelectedVol = (viewerState, volState) =>
  getVolByID(volState, getSelectedVolID(viewerState));
export const getSelectedScan = (viewerState, scanState) =>
  getScanByID(scanState, getSelectedScanID(viewerState));

export const getIsPlacingSeed = state => state.get('isPlacingSeed');

export const getEditSeedID = state => state.get('editSeedID');

export const getIsLoadingAirway = state => state.get('isLoadingAirway');
export const getIsLoadingCenterline = state => state.get('isLoadingCenterline');
export const getIsLoadingMask = state => state.get('isLoadingMask');

export const getIsCrossSectionVisible = state => state.get('isCrossSectionVisible');

export const getIsSelectingCenterlineStart = state => state.get('isSelectingCenterlineStart');

export const getShowSliceViews = state => state.get('showSliceViews');
export const getShowSeedList = state => state.get('showSeedList');
export const getShowScanInfoTable = state => state.get('showScanInfoTable');
export const getShowCTWindowing = state => state.get('showCTWindowing');
export const getShowRejectModal = state => state.get('showRejectModal');
export const getShowOverwriteScanModal = state => state.get('showOverwriteScanModal');

export const getShowCenterlineBoundsWarning = state => state.get('showCenterlineBoundsWarning');

export const getIsAxialViewLoaded = state => state.get('isAxialViewLoaded');
export const getIsCoronalViewLoaded = state => state.get('isCoronalViewLoaded');
