import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  useCanvasMouseEvent,
  useSceneSelector,
  useSceneDispatch,
} from '@visionair/scene-state-3js';

import visualizeCenterline from '../../../modules/scene/actions/airwayView/visualizeCenterline';
import { CENTERLINE_DEFAULT_COLOR } from '../../../settings/centerline';
import Instructions from './Instructions';
import smoothingIconActive from '../../../images/centerline_tools/smooth_active.svg';
import { isClick } from '../../../modules/scene/selectors';

const BRANCH_HOVER_COLOR = 0xff0000;
const BRANCH_SELECTED_COLOR = 0xff0000;

const MIN_SMOOTH = 1;
const MAX_SMOOTH = 15;
const SMOOTH_STEP = (MAX_SMOOTH - MIN_SMOOTH) / 20; // 5% increments

export default function SmoothingTool({ onFinish }) {
  const [selectedBranch, setSelectedBranch] = useState();
  const [smoothness, setSmoothness] = useState(1);
  const hasChangedRef = useRef(false);
  const prevBranchRef = useRef();
  const unsmoothedPointsRef = useRef();

  const scene = useSceneSelector(state => state.scene);
  const dispatch = useSceneDispatch();

  useCanvasMouseEvent(
    'pointermove',
    useCallback(
      (evt, sceneState, raycaster) => {
        if (selectedBranch) {
          return;
        }

        const { branch } = sceneState.centerline.getClosestPointToRay(raycaster.ray);
        unsmoothedPointsRef.current = branch.points.slice();

        const threeBranch = sceneState.scene.getObjectByName(branch.branchName);
        threeBranch.material.color.set(BRANCH_HOVER_COLOR);

        if (prevBranchRef.current && threeBranch !== prevBranchRef.current) {
          prevBranchRef.current.material.color.set(CENTERLINE_DEFAULT_COLOR);
        }

        prevBranchRef.current = threeBranch;
      },
      [selectedBranch],
    ),
  );

  useCanvasMouseEvent(
    'pointerup',
    useCallback(
      (evt, sceneState, raycaster) => {
        if (selectedBranch || !isClick(sceneState, evt)) {
          return;
        }

        const { branch } = sceneState.centerline.getClosestPointToRay(raycaster.ray);

        const threeBranch = sceneState.scene.getObjectByName(branch.branchName);
        threeBranch.material.color.set(BRANCH_SELECTED_COLOR);

        setSelectedBranch(branch);
        setSmoothness(branch.smoothDistance);
      },
      [selectedBranch],
    ),
  );

  useEffect(() => () => dispatch(visualizeCenterline()), [dispatch]);

  const handleSliderChange = ({ target: { value } }) => {
    setSmoothness(value);

    selectedBranch.smoothBranch(parseFloat(value), unsmoothedPointsRef.current);
    selectedBranch.visualize(scene, BRANCH_SELECTED_COLOR);

    if (!hasChangedRef.current) {
      onFinish();
      hasChangedRef.current = true;
    }
  };

  return (
    <>
      <Instructions>
        <img src={smoothingIconActive} alt='' />
        {selectedBranch ? 'Smoothness:' : 'Left-Click the line segment to smooth.'}
      </Instructions>
      {Boolean(selectedBranch) && (
        <SliderContainer>
          <Slider
            type='range'
            value={smoothness}
            min={MIN_SMOOTH}
            max={MAX_SMOOTH}
            step={SMOOTH_STEP}
            disabled={!selectedBranch}
            onChange={handleSliderChange}
            data-test='smooth-centerline-slider'
          />
          {`${Math.round(100 * ((smoothness - MIN_SMOOTH) / (MAX_SMOOTH - MIN_SMOOTH)))}%`}
        </SliderContainer>
      )}
    </>
  );
}

const Slider = styled.input`
  flex-grow: 1;
  margin-right: 12px;

  -webkit-appearance: none;
  outline: none !important;
  background: none;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    position: relative;
    z-index: 2;

    width: 15px;
    height: 15px;
    border-radius: 50px;

    margin-top: -5px;
    background: radial-gradient(#e2e0e0, #e2e1e1);
    border: none;
    box-shadow: none;
  }

  ::-webkit-slider-runnable-track {
    border: none;
    background: red;
    height: 4px;
    border-radius: 4px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const SliderContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 210px;
  border-radius: 6px;
  background-color: #171616;
  width: 310px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  color: white;
  font-weight: 500;
`;
