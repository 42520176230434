import colors from './colors';
import fonts from './fonts';

export default {
  'background-color': colors.black,
  color: colors.white,
  'font-family': fonts.fontFamilyDefault,
  'font-size': '14px',
  'font-weight': '500',
  padding: '5px 20px',
};
