import colors from './colors';
import fonts from './fonts';

export default {
  default: {
    'font-family': fonts.fontFamilyDefault,
    'font-size': '11px',
    color: colors.grey50,
  },
  active: {
    'font-weight': '500',
    color: colors.black,
  },
};
