/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  MeshBasicMaterial,
  SphereBufferGeometry,
  Mesh,
  BufferGeometry,
  LineBasicMaterial,
  Line,
  Float32BufferAttribute,
} from 'three';
import {
  Provider as SceneProvider,
  useSceneSelector,
  useSceneSetup,
  useSceneDispatch,
  resizeCanvas,
} from '@visionair/scene-state-3js';

import AdjustToCentroidTool from './AdjustToCentroidTool';
import { getIsCrossSectionVisible } from '../../../modules/viewer/selectors';

const CANVAS_WIDTH = 235;
const CANVAS_HEIGHT = 235;

const CROSS_SECTION_COLOR = 0xffffff;
const CENTROID_COLOR = 0xffffff;
const CENTERLINE_COLOR = 0xff0000;

function CrossSectionViewer({ canvasName }) {
  const showCanvas = useSelector(({ viewer }) => getIsCrossSectionVisible(viewer));
  const isAdjustingCenterline = useSceneSelector(state => state.isAdjustingCenterline);

  useSceneSetup(state => {
    const sceneState = state;

    const lineGeo = new BufferGeometry();
    const bufAttr = new Float32BufferAttribute([0, 0, 0], 3, false);
    lineGeo.setAttribute('position', bufAttr);
    const lineMat = new LineBasicMaterial({ color: CROSS_SECTION_COLOR });
    sceneState.crossSectionLines = new Line(lineGeo, lineMat);

    const minDiamGeo = new BufferGeometry();
    minDiamGeo.setAttribute('position', bufAttr);
    const minDiamMat = new LineBasicMaterial({ color: 0xff0000 });
    sceneState.minDiamLine = new Line(minDiamGeo, minDiamMat);

    const maxDiamGeo = new BufferGeometry();
    maxDiamGeo.setAttribute('position', bufAttr);
    const maxDiamMat = new LineBasicMaterial({ color: 0x0000ff });
    sceneState.maxDiamLine = new Line(maxDiamGeo, maxDiamMat);

    sceneState.camera.left = -CANVAS_WIDTH / 2;
    sceneState.camera.right = CANVAS_WIDTH / 2;
    sceneState.camera.top = CANVAS_HEIGHT / 2;
    sceneState.camera.bottom = -CANVAS_HEIGHT / 2;

    const viewSizeMM = 40;
    const viewSizeCam = Math.min(CANVAS_WIDTH, CANVAS_HEIGHT);
    sceneState.camera.zoom = viewSizeCam / viewSizeMM;
    sceneState.camera.updateProjectionMatrix();

    sceneState.scene.add(
      sceneState.crossSectionLines,
      sceneState.minDiamLine,
      sceneState.maxDiamLine,
      sceneState.centroidMarker,
      sceneState.centerPointMarker,
    );
  });

  const sceneDispatch = useSceneDispatch();

  useEffect(() => {
    sceneDispatch(resizeCanvas(CANVAS_WIDTH, CANVAS_HEIGHT));
  }, [sceneDispatch]);

  return (
    <>
      <CrossSectionContainer style={{ pointerEvents: showCanvas ? 'all' : 'none' }}>
        {isAdjustingCenterline && <AdjustToCentroidTool />}
        <Canvas
          style={{ display: showCanvas ? 'block' : 'none' }}
          height={CANVAS_HEIGHT}
          width={CANVAS_WIDTH}
          className={canvasName}
        />
      </CrossSectionContainer>
    </>
  );
}

const scenePropsFactory = () => {
  const centroidMarker = new Mesh(
    new SphereBufferGeometry(1),
    new MeshBasicMaterial({ color: CENTROID_COLOR }),
  );

  const centerPointMarker = new Mesh(
    new SphereBufferGeometry(1),
    new MeshBasicMaterial({ color: CENTERLINE_COLOR }),
  );

  // render centroid above centerPointMarker
  centroidMarker.renderOrder = 1;
  centroidMarker.onBeforeRender = renderer => {
    renderer.clearDepth();
  };

  return {
    centroidMarker,
    centerPointMarker,
    crossSectionData: {
      minDiam: null,
      maxDiam: null,
      avgDiam: null,
      roundness: null,
    },
    shouldRender: false,
  };
};

export default styled(SceneProvider(CrossSectionViewer, scenePropsFactory))`
  position: relative;
  z-index: 1001;
`;

const Canvas = styled.canvas``;

const CrossSectionContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 94px;
  border-radius: 10px;
  border: solid 1px #707070;
  background-color: #141414;
`;
