import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import HomePage from './HomePage';
import PrivateRoutes from './PrivateRoutes';
import Notification from './Notification';

export default function Routes() {
  return (
    <>
      <Notification />
      <Router>
        <Switch>
          <Route exact path='/' component={HomePage} />
          <Route exact path='/logout' render={props => <HomePage {...props} shouldLogout />} />
          <Route component={PrivateRoutes} />
        </Switch>
      </Router>
    </>
  );
}
