export default `
  uniform vec2 window;
  uniform sampler2D imgTexture;

  varying vec2 vUv;

  float huMin = -1024.0;
  float huMax = 3071.0;
  float huWidth = 3071.0 - (-1024.0);

  void main() {
    vec3 pixelColor = texture(imgTexture, vUv).rgb;

    // shift the raw pixel value based on the window settings
    // NOTE: pixelColor is normalized to [0, 1] range
    vec3 color = (pixelColor - (window.x - huMin) / huWidth) * (huWidth / (window.y - window.x));
    
    gl_FragColor = vec4(color, 1.0);
  }
`;
