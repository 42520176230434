export default function setMaskVisible(visible) {
  return (notify, getSceneState) => {
    const { isSliceViewer, mask } = getSceneState();
    if (!isSliceViewer) {
      return;
    }

    mask.visible = visible;

    notify();
  };
}
