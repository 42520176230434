import updateSeedPositions from './updateSeedPositions';
import { parseAPISeed } from '../../../../utils/threeUtils';

// create seed proxies to store projected position state without updating redux state
export default function setSeedPoints(seedPoints, sliceView) {
  return (notify, getSceneState, callSceneAction) => {
    const sceneState = getSceneState();
    sceneState.seedPoints = seedPoints.map(seed => parseAPISeed(seed, sliceView));

    callSceneAction(updateSeedPositions());

    sceneState.render();

    notify();
  };
}
