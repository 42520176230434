import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MdClear } from 'react-icons/md';

import BaseComponent from './BaseComponent';

import {
  getNotificationMessage,
  getNotificationType,
  getNotificationShowForMS,
  getNotificationCloseImmediately,
} from '../modules/notifications/selectors';
import clearNotification from '../modules/notifications/clearNotification';

export const SHOW_DEFAULT_NOTIFICATION_TIME_MS = 5000;
export const SHOW_ERROR_NOTIFICATION_TIME_MS = Infinity; // persist until manually closed

export const SUCCESS_NOTIFICATION = 'SUCCESS';
export const ERROR_NOTIFICATION = 'ERROR';

export class NotificationComponent extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = { isShown: false };
  }

  componentDidUpdate(prevProps) {
    // immediately hide existing notification WITHOUT close animation
    if (this.props.closeImmediately && !prevProps.closeImmediately) {
      clearTimeout(this.autoCloseTimeout);
    }

    // immediately hide existing notification WITH close animation
    if (!this.props.message && prevProps.message) {
      this.handleClose();
    }

    // new message or message changed (non-empty)
    if (this.props.message && this.props.message !== prevProps.message) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isShown: true });
      clearTimeout(this.autoCloseTimeout);

      // persist message through animations
      this.currentMessage = this.props.message;

      this.setTimeout();
    }
  }

  setTimeout() {
    let showForMS = SHOW_DEFAULT_NOTIFICATION_TIME_MS;

    if (this.props.notificationType === ERROR_NOTIFICATION) {
      showForMS = SHOW_ERROR_NOTIFICATION_TIME_MS;
    }

    // specified value overrides everything
    if (this.props.showForMS) {
      // eslint-disable-next-line prefer-destructuring
      showForMS = this.props.showForMS;
    }

    // Infinity -> notification will persist until manually closed
    if (showForMS !== Infinity) {
      this.autoCloseTimeout = setTimeout(() => this.handleClose(), showForMS);
    }
  }

  handleClose() {
    this.setState({ isShown: false });
    clearTimeout(this.autoCloseTimeout);

    if (this.props.message) {
      this.props.clearNotification();
    }
  }

  render() {
    if (this.props.closeImmediately) {
      return null;
    }

    return (
      <SlideoutContainer>
        <NotificationContainer
          show={this.state.isShown}
          notificationType={this.props.notificationType}
          data-test='toast-notification'
        >
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: this.props.message || this.currentMessage }} />
          <CloseButton onClick={this.handleClose}>
            <MdClear color='#ffffff' size='18px' />
          </CloseButton>
        </NotificationContainer>
      </SlideoutContainer>
    );
  }
}

NotificationComponent.propTypes = {
  message: PropTypes.string,
  notificationType: PropTypes.string.isRequired,
  showForMS: PropTypes.number,
  closeImmediately: PropTypes.bool,
};

const mapStateToProps = ({ notifications }) => ({
  message: getNotificationMessage(notifications),
  notificationType: getNotificationType(notifications),
  showForMS: getNotificationShowForMS(notifications),
  closeImmediately: getNotificationCloseImmediately(notifications),
});

export default connect(mapStateToProps, { clearNotification })(NotificationComponent);

const CONTAINER_WIDTH = '400px';

const SlideoutContainer = styled.div`
  position: fixed;
  top: 25px;
  left: 0;
  width: 100%;
  height: 0;
  overflow: visible;
  pointer-events: none;
  z-index: 1001;
`;

const NotificationContainer = styled.div`
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: ${props => (props.show ? '25px' : `-${CONTAINER_WIDTH}`)};
  transition: right 0.3s ease;
  z-index: 999999;

  border-radius: 4px;
  background: ${props =>
    ({
      [SUCCESS_NOTIFICATION]: props.theme.colors.green70,
      [ERROR_NOTIFICATION]: props.theme.colors.red70,
    }[props.notificationType] || props.theme.colors.purple50)};
  color: ${props => props.theme.colors.white};

  width: ${CONTAINER_WIDTH};
  padding: 12px 15px;

  font-size: 14px;
  font-family: ${props => props.theme.fonts.fontFamilyDefault};
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  outline: none;
  padding: 5px;

  align-self: flex-start;
`;
