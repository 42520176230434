import { Box3, Vector3 } from 'three';

export default function snapCameraToObjectCoronal(object3D, zoomFactor = 1) {
  return (notify, getSceneState) => {
    const { camera, controls } = getSceneState();

    const bb = new Box3().setFromObject(object3D);
    const size = new Vector3();
    const center = new Vector3();

    bb.getSize(size);
    bb.getCenter(center);

    const zoom = Math.min((camera.right * 2) / size.x, (camera.top * 2) / size.z) * zoomFactor;
    camera.zoom = zoom;
    camera.updateProjectionMatrix();

    const planeNormal = new Vector3(0, 1, 0);
    camera.position.copy(center).sub(planeNormal.clone().multiplyScalar(400));
    camera.up.set(0, 0, 1);
    camera.lookAt(center);

    if (controls) {
      controls.target.copy(center);
    }

    notify();
  };
}
