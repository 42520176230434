import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { useSceneDispatch, useSceneSelector } from '@visionair/scene-state-3js';

import {
  showNotificationError,
  showNotificationSuccess,
} from '../../../modules/notifications/showNotification';
import saveCenterline from '../../../modules/scene/actions/airwayView/saveCenterline';
import undoCenterline from '../../../modules/scene/actions/airwayView/undoCenterline';
import { getCenterlineNeedsUpdate, getUndoEnabled } from '../../../modules/scene/selectors';

import viewerIcon from '../../../images/3d-viewer.svg';
import undoIconActive from '../../../images/centerline_tools/undo_active.svg';
import undoIcon from '../../../images/centerline_tools/undo.svg';
import saveIconActive from '../../../images/centerline_tools/save_active.svg';
import saveIcon from '../../../images/centerline_tools/save.svg';
import { SEG_STATUS } from '../../../settings/segmentations';

export default function AiwayViewerHeader({ seg }) {
  const { centerlineNeedsUpdate, disableUndo } = useSceneSelector(state => ({
    centerlineNeedsUpdate: getCenterlineNeedsUpdate(state),
    disableUndo: !getUndoEnabled(state),
  }));

  const sceneDispatch = useSceneDispatch();
  const reduxDispatch = useDispatch();

  const isUndoDisabled = disableUndo || seg.status !== SEG_STATUS.SUCCESS;
  const isSaveDisabled = !centerlineNeedsUpdate || seg.status !== SEG_STATUS.SUCCESS;

  useEffect(() => {
    if (!centerlineNeedsUpdate) {
      return;
    }

    const listener = evt => {
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
      evt.preventDefault();
      // eslint-disable-next-line no-param-reassign
      evt.returnValue = '';
    };

    window.addEventListener('beforeunload', listener);

    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('beforeunload', listener);
  }, [centerlineNeedsUpdate]);

  const handleSave = useCallback(() => {
    if (isSaveDisabled) {
      return;
    }

    sceneDispatch(saveCenterline(seg.id))
      .then(() => {
        reduxDispatch(showNotificationSuccess('Centerline saved successfully'));
      })
      .catch(err => {
        console.error(err); // eslint-disable-line no-console
        reduxDispatch(
          showNotificationError('There was a problem saving centerline. Please try again.'),
        );
      });
  }, [reduxDispatch, sceneDispatch, isSaveDisabled, seg]);

  const handleUndo = useCallback(() => {
    if (!isUndoDisabled) {
      sceneDispatch(undoCenterline());
    }
  }, [sceneDispatch, isUndoDisabled]);

  const handleKeyPress = useCallback(
    event => {
      switch (event.key) {
        case 'z':
          handleUndo();
          break;
        case 's':
          handleSave();
          break;
        default:
      }
    },
    [handleUndo, handleSave],
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Container>
      <div>
        <img src={viewerIcon} alt='' />
        3D Viewer (Centerline Edit)
      </div>
      <div style={{ display: 'flex' }}>
        <UndoBtn disabled={isUndoDisabled} onClick={handleUndo}>
          <img src={isUndoDisabled ? undoIcon : undoIconActive} alt='' />
          Undo
        </UndoBtn>
        <SaveBtn disabled={isSaveDisabled} onClick={handleSave}>
          <img src={isSaveDisabled ? saveIcon : saveIconActive} alt='' />
          Save Centerline
        </SaveBtn>
      </div>
    </Container>
  );
}

const Container = styled.div`
  height: 48px;
  background-image: linear-gradient(to bottom, #414141, #0d0d0d);
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 12px;
  font-weight: bold;

  img {
    margin-right: 10px;
  }
`;

const btnMixin = css`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;

  border: none;
  background: none;
  outline: none !important;

  &:disabled {
    opacity: 0.4;
    color: white;
    cursor: not-allowed;
  }
`;

const SaveBtn = styled.button`
  ${btnMixin}

  color: #00ff14;
`;

const UndoBtn = styled.button`
  ${btnMixin}

  color: #ffd800;
  margin-right: 20px;
`;
