// TODO: use env variable
export const API_BASE_URL = process.env.API_URL || 'http://localhost:8000/';

export const API_TOKEN_URL = 'auth/get-token/';
export const API_TOKEN_REFRESH_URL = 'auth/refresh-token/';

export const API_ORDERS_URL = 'api/orders/';
export const API_SCANS_URL = 'api/scans/';
export const API_STENTS_URL = 'api/stents/';
export const API_SPHERES_URL = 'api/spheres/';
export const API_USERS_URL = 'api/users/';
export const API_PATIENTS_URL = 'api/patients/';
export const API_UPLOAD_URL = 'api/upload/';
export const API_SHIPPING_ADDRESS_URL = 'api/shipping-addresses';
export const API_AFFILIATIONS_URL = 'api/affiliations';
export const API_HOSPITALS_URL = 'api/hospitals';
export const API_VOLUME_3D_URL = 'api/volume3ds';
export const API_SLICE_VIEW_URL = 'api/slice-views';
export const API_SEG_URL = 'api/segmentations';
export const API_SEED_URL = 'api/seed-points';

export const MULTIPART_FORM_BOUNDARY = 'formBoundary';

export const getVolume3DByIDURL = volume3DID => `${API_VOLUME_3D_URL}/${volume3DID}`;
export const getVolume3DByScanURL = scanID => `${API_VOLUME_3D_URL}?scanID=${scanID}`;

export const getSliceViewsByVolume = volume3DID => `${API_SLICE_VIEW_URL}?volume3DID=${volume3DID}`;
export const getSliceImages = (sliceViewID, start, numSlices) =>
  `${API_SLICE_VIEW_URL}/${sliceViewID}/slice?start=${start}&numSlices=${numSlices}`;

export const deleteSeedURL = seedID => `${API_SEED_URL}/${seedID}`;
export const updateSeedURL = seedID => `${API_SEED_URL}/${seedID}`;

export const getSegmentationsByVolURL = volID => `${API_SEG_URL}?volume3DID=${volID}`;
export const clearSegmentationURL = segID => `${API_SEG_URL}/${segID}/clear`;
export const startSegmentationURL = segID => `${API_SEG_URL}/${segID}/start`;
export const verifySegmentationURL = segID => `${API_SEG_URL}/${segID}/verify`;
export const uploadCenterlineURL = segID => `${API_SEG_URL}/${segID}/centerline`;
export const patchSegURL = segID => `${API_SEG_URL}/${segID}`;

export const RECAPTCHA_SITEKEY = process.env.RECAPTCHA_KEY;

export const getUserURL = userID => `${API_USERS_URL}${userID}/`;
export const updateUserURL = userID => `${API_USERS_URL}${userID}/`;

export const getPatientsURL = () => `${API_PATIENTS_URL}`;
export const getPatientURL = patientID => `${API_PATIENTS_URL}${patientID}/`;

export const getRejectionCodesURL = () => `${API_SCANS_URL}/rejection_codes`;
export const rejectScanURL = scanID => `${API_SCANS_URL}${scanID}/reject`;
export const getScansURL = () => `${API_SCANS_URL}?withSegs=true`;
export const getScansByStatusURL = statuses =>
  `${API_SCANS_URL}?status=${statuses.map(status => status.replace('SCANSEG_', '')).join(',')}`;
export const getScansByPatientURL = patientID => `${API_SCANS_URL}?patient=${patientID}`;
export const getScanURL = scanID => `${API_SCANS_URL}${scanID}?withSeg=true`;
export const getScanByPatientInfoURL = (patientID, patientScanNum) =>
  `${API_SCANS_URL}?patientID=${patientID}&patientScanNum=${patientScanNum}&withSeg=true`;
export const getScanByScanNum = scanNum => `${API_SCANS_URL}?scanNum=${scanNum}&withSeg=true`;
export const updateScanURL = scanID => `${API_SCANS_URL}${scanID}/`;
export const overwriteScanURL = scanID => `${API_SCANS_URL}${scanID}/overwrite`;

export const getScanFileURL = (scanID, fileName) => `static/scans/${scanID}/${fileName}/`;
export const downloadScanZipURL = scanID => `${API_SCANS_URL}${scanID}/download`;
export const uploadAirwayFileURL = scanID => `${API_UPLOAD_URL}${scanID}/airway/`;
export const uploadCenterlineFileURL = scanID => `${API_UPLOAD_URL}${scanID}/centerline/`;
export const uploadVolumeFileURL = scanID => `${API_UPLOAD_URL}${scanID}/volume/`;

export const getSegFileURL = (segID, fileName) => `static/segmentations/${segID}/${fileName}`;
export const getAirwayURL = segID => getSegFileURL(segID, `seg_${segID}.airway.stl`);
export const getMaskVFFURL = segID => getSegFileURL(segID, `seg_${segID}.airway.vff`);
export const getMaskMHDURL = segID => [
  getSegFileURL(segID, `seg_${segID}.mhd`),
  getSegFileURL(segID, `seg_${segID}.raw`),
];
export const getCenterlineURL = (segID, fileExt) =>
  getSegFileURL(segID, `seg_${segID}.centerline.${fileExt}`);

export const getStentsByScanURL = scanID => `${API_STENTS_URL}?scan=${scanID}`;
export const getStentURL = stentID => `${API_STENTS_URL}${stentID}/`;
export const updateStentURL = stentID => `${API_STENTS_URL}${stentID}/`;
export const createStentURL = () => `${API_STENTS_URL}`;
export const duplicateStentURL = stentID => `${API_STENTS_URL}${stentID}/duplicate/`;
export const uploadStentFileURL = stentID => `${API_STENTS_URL}${stentID}/upload/`;
export const getStentFileURL = (stentID, fileName) => `static/stents/${stentID}/${fileName}`;
export const getStentOrderURL = stentID => `${API_ORDERS_URL}?stentID=${stentID}`;

export const searchScansURL = ({
  scanNum,
  doctorName,
  patientName,
  scanStatus = [],
  scanSegStatus = [],
  acqDate = { start: null, end: null }, // ISO 8601 format
  uploadDate = { start: null, end: null }, // ISO 8601 format
  verifRejDate = { start: null, end: null }, // ISO 8601 format
  withSegs,
  limit,
  offset,
} = {}) => {
  const queryStrings = [];
  if (scanNum) {
    queryStrings.push(`scanNum=${scanNum}`);
  }
  if (doctorName) {
    queryStrings.push(`doctorName=${doctorName}`);
  }
  if (patientName) {
    queryStrings.push(`patientName=${patientName}`);
  }
  if (scanStatus?.length) {
    const scanStatusList = [].concat(scanStatus);
    queryStrings.push(`scanStatus=${scanStatusList.join(',')}`);
  }
  if (scanSegStatus?.length) {
    const scanSegStatusList = [].concat(scanSegStatus);
    queryStrings.push(`scanSegStatus=${scanSegStatusList.join(',')}`);
  }
  if (acqDate?.start) {
    queryStrings.push(`acqDateStart=${acqDate.start}`);
  }
  if (acqDate?.end) {
    queryStrings.push(`acqDateEnd=${acqDate.end}`);
  }
  if (uploadDate?.start) {
    queryStrings.push(`uploadDateStart=${uploadDate.start}`);
  }
  if (uploadDate?.end) {
    queryStrings.push(`uploadDateEnd=${uploadDate.end}`);
  }
  if (verifRejDate?.start) {
    queryStrings.push(`verifiedRejectedDateStart=${verifRejDate.start}`);
  }
  if (verifRejDate?.end) {
    queryStrings.push(`verifiedRejectedDateEnd=${verifRejDate.end}`);
  }
  if (withSegs) {
    queryStrings.push(`withSegs=${withSegs}`);
  }
  if (limit) {
    queryStrings.push(`limit=${limit}`);
  }
  if (offset) {
    queryStrings.push(`offset=${offset}`);
  }

  queryStrings.push('training=false');

  return `${API_SCANS_URL}search?${queryStrings.join('&')}`;
};
