import { BackSide, MeshBasicMaterial, Mesh } from 'three';
import { Centerline } from '@visionair/centerline-3js';

import { getCenterlineURL } from '../../../../settings/api';
import { initialize, fullUrl, apiFetch } from '../../../../utils/requests';
import { AIRWAY_LABEL } from '../../../../settings/airway';
import CenterlineWorker from '../../../../workers/centerline';
import * as centerlineSettings from '../../../../settings/centerline';
import setIsLoadingCenterline from '../../../viewer/setIsLoadingCenterline';
import removeCenterline from './removeCenterline';
import visualizeCenterline from './visualizeCenterline';
import { getSegCenterlineStatus } from '../../../segmentations/selectors';
import { showNotificationError } from '../../../notifications/showNotification';
import store from '../../../../store';

export default function loadCenterline(segID) {
  return (notify, getSceneState, callSceneAction) => {
    const sceneState = getSceneState();
    const { scene } = sceneState;

    const token = localStorage.getItem('token');
    const centerlineStatus = getSegCenterlineStatus(store.getState().segmentations, segID);

    callSceneAction(removeCenterline());

    store.dispatch(setIsLoadingCenterline(true));

    const { geometry: airwayGeo } = scene.getObjectByName(AIRWAY_LABEL);
    airwayGeo.computeBoundsTree();

    return apiFetch(getCenterlineURL(segID, 'json'), token, 'GET')
      .catch(async err => {
        if (centerlineStatus === centerlineSettings.CENTERLINE_STATUS_ERROR) {
          const res = await fetch(
            fullUrl(getCenterlineURL(segID, 'vtk')),
            initialize(token, 'GET'),
          );
          if (!res.ok) {
            throw new Error('centerline .vtk not found');
          }

          const arrayBuffer = await res.arrayBuffer();

          const centerlineLoader = loadWorker();
          const centerlineBlob = await centerlineLoader.loadCenterline(
            arrayBuffer,
            airwayGeo.getAttribute('position').array,
          );

          return JSON.parse(await centerlineBlob.text());
        }

        throw err;
      })
      .then(async centerlineJSON => {
        const backsideAirway = new Mesh(airwayGeo, new MeshBasicMaterial({ side: BackSide }));

        const centerline = new Centerline(backsideAirway, undefined, {
          minBranchLength: centerlineSettings.MIN_BRANCH_LENGTH,
          minLeafLength: centerlineSettings.MIN_LEAF_LENGTH,
          maxBranchAngle: centerlineSettings.MAX_BRANCH_ANGLE,
          branchPtMaxDist: centerlineSettings.BRANCH_POINT_MAX_DIST,
          pointsFromEnd: centerlineSettings.POINTS_FROM_END,
        }).fromJSON(centerlineJSON);

        centerline.smoothDistance = centerlineSettings.CENTERLINE_DEFAULT_SMOOTH;

        sceneState.centerline = centerline;

        sceneState.centerlineChanges.states = [centerlineJSON];
        sceneState.centerlineChanges.idx = 0;
        sceneState.centerlineChanges.needsUpdate = false;

        callSceneAction(visualizeCenterline());

        store.dispatch(setIsLoadingCenterline(false));

        notify();

        return centerline;
      })
      .catch(() => {
        if (centerlineStatus !== centerlineSettings.CENTERLINE_STATUS_PROCESSING) {
          store.dispatch(setIsLoadingCenterline(false));
          store.dispatch(showNotificationError('There was a problem fetching the centerline.'));
        }
      });
  };
}

let centerlineWorker;

function loadWorker() {
  if (centerlineWorker) {
    return centerlineWorker;
  }

  centerlineWorker = CenterlineWorker();

  return centerlineWorker;
}
