import { DoubleSide, ShaderMaterial, Texture, Vector2 } from 'three';

import fragmentShader from './shaders/CTFrag.glsl';
import vertexShader from './shaders/CTVert.glsl';

export const HU_MIN = -1024;
export const HU_MAX = 3071;
export const LUNG_HU_MIN = -1000;
export const LUNG_HU_MAX = 200;
export const MEDIASTINUM_HU_MIN = -125;
export const MEDIASTINUM_HU_MAX = 225;

export default class CTMaterial extends ShaderMaterial {
  constructor(props = {}) {
    const uniforms = {
      imgTexture: { value: new Texture() },
      window: { value: new Vector2(LUNG_HU_MIN, LUNG_HU_MAX) },
    };

    super({
      uniforms,
      vertexShader,
      fragmentShader,
      side: DoubleSide,
      ...props,
    });
  }

  setWindow(min, max) {
    this.uniforms.window.value.set(min, max);
  }

  setTexture(texture) {
    this.uniforms.imgTexture.value = texture;
    this.needsUpdate = true;
  }
}
