import { useCallback, useRef } from 'react';
import { Plane, Vector3 } from 'three';
import { useCanvasMouseEvent, useSceneDispatch } from '@visionair/scene-state-3js';

import adjustBranchCenterPoint from '../../../modules/scene/actions/airwayView/adjustBranchCenterPoint';
import { AIRWAY_VIEWER_NAME } from '../../../modules/scene/selectors';

const plane = new Plane();
const newPosition = new Vector3();

export default function AdjustToCentroidTool() {
  const isDraggingRef = useRef(false);

  const dispatch = useSceneDispatch();

  useCanvasMouseEvent(
    'pointermove',
    useCallback(
      (evt, sceneState, raycaster) => {
        if (!isDraggingRef.current) {
          return;
        }

        plane.setFromNormalAndCoplanarPoint(
          raycaster.ray.direction,
          sceneState.centerPointMarker.position.clone(),
        );
        plane.projectPoint(raycaster.ray.origin, newPosition);

        sceneState.centerPointMarker.position.copy(newPosition);

        dispatch(adjustBranchCenterPoint(newPosition), AIRWAY_VIEWER_NAME);

        sceneState.render();
      },
      [dispatch],
    ),
  );

  useCanvasMouseEvent(
    'pointerdown',
    useCallback((evt, sceneState, raycaster) => {
      const hits = raycaster.intersectObject(sceneState.centerPointMarker);

      isDraggingRef.current = hits.length > 0;
    }, []),
  );

  useCanvasMouseEvent(
    'pointerup',
    useCallback(() => {
      isDraggingRef.current = false;
    }, []),
  );

  useCanvasMouseEvent(
    'pointerleave',
    useCallback(() => {
      isDraggingRef.current = false;
    }, []),
  );

  return null;
}
