import React from 'react';
import { MdArrowDropDown, MdArrowDropUp, MdClose } from 'react-icons/md';
import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';

import { ReactComponent as ScanParametersIcon } from '../../../images/scan-overwrite-modal/ct-upload-scan-parameters-icon.svg';

import ScanParameters from './ScanParameters';
import * as S from './ScanUploadDialog.styled';

function ScanUploadDialog({ open, children, ...rest }) {
  return (
    <S.Dialog
      open={open}
      disableEscapeKeyDown
      maxWidth={false}
      scroll='body'
      data-test='scan-upload-modal'
      {...rest}
    >
      {children}
    </S.Dialog>
  );
}

function Title({ heading, icon, showCloseBtn, onClose, ...rest }) {
  return (
    <S.DialogTitle $showCloseBtn={showCloseBtn} {...rest} data-test='scanUploadDialog-title'>
      {icon && (
        <div className='ScanUploadDialog-DialogTitleIcon' style={{ display: 'flex' }}>
          {icon}
        </div>
      )}
      <Heading>{heading}</Heading>

      {showCloseBtn && (
        <IconButton onClick={onClose} data-test='close-scan-upload-btn'>
          <MdClose size={28} color='000000' />
        </IconButton>
      )}
    </S.DialogTitle>
  );
}

const Heading = styled.div`
  font-size: 22px;
  font-weight: 600;
  margin-left: 20px;
  flex-grow: 1;
  text-align: left;
`;

function Content({ children, openScanParameters = false, ...rest }) {
  return (
    <S.DialogContent {...rest}>
      {openScanParameters ? <ScanParameters /> : children}
    </S.DialogContent>
  );
}

function ActionButtons({ children, ScanParametersProps, ...rest }) {
  return (
    <S.DialogActions $showScanParametersBtn={ScanParametersProps.showBtn} {...rest}>
      {ScanParametersProps.showBtn && (
        <S.ScanParametersBtn
          onClick={ScanParametersProps.onClick}
          startIcon={<ScanParametersIcon width={30} />}
          endIcon={
            ScanParametersProps.open ? <MdArrowDropUp size={30} /> : <MdArrowDropDown size={30} />
          }
          $open={ScanParametersProps.open || false}
        >
          CT Scan Parameters
        </S.ScanParametersBtn>
      )}

      {children}
    </S.DialogActions>
  );
}

ScanUploadDialog.Title = Title;
ScanUploadDialog.Content = Content;
ScanUploadDialog.ActionButtons = ActionButtons;

export default ScanUploadDialog;
